import "./boiler-detail.css"
import { createRef, useRef, useEffect, useState, useCallback } from "react";

import emailIcon from "../../../assets/images/resultspage/Email-icon.png"
import chatIcon from "../../../assets/images/resultspage/Chat-icon.png"
import greenRightTick from "../../../assets/images/Completed-tick.png"
import radiatorImg from "../../../assets/images/TRV.png"

import GetYourQuoteBtn from "../get-your-quote-btn/get-your-quote-btn";
import { Accordion } from "react-bootstrap";
import Safeinstalltion from "../safe-installation/Safeinstallation";
import dateIcon from "../../../assets/images/resultspage/date-icon.png"
import Calendar from 'react-calendar';
import moment from 'moment';
import validator from 'validator';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner'
import Swal from 'sweetalert2'
import { Button, Modal } from 'react-bootstrap'
import StepperComponent from "../../components/stepper/stepper";
import CustomSpinnerComponent from "../../components/custom-spinner/custom-spinner";
import SuccessHandImage from '../../../assets/images/resultspage/glare.png'
import smiley_face from '../../../assets/images/resultspage/smiley_face.png'
import aos from 'aos';
import LoaderImage from "../../../assets/images/favloader.gif"
import CryptoJS from "crypto-js";
import Footer from '../footer/Footer';

var PayPalToken = null
var myOrderID = null
var term = 120;
var apr = 9.9;
var depositPercent = 50;

const BoilerDetails = (params) => {
    let selectedGrant = params.boiler;

    const [grant, setGrant] = useState(selectedGrant)
    const [questions, setQuestions] = useState([])
    const [installation, setInstallation] = useState(false)
    const [paymentSuccess, setpaymentSuccess] = useState(false)
    // const [RangeFinance, setRangeFinance] = useState(50)
    const [FormErrors, setFormErrors] = useState({})
    const [PymentMethod, setPymentMethod] = useState('card')
    const [postcode, setPostcode] = useState(localStorage.getItem('postcode'))
    const [clientToken, setClientToken] = useState('')
    const [AllReq, setAllReq] = useState({})
    const [acceptTC, setAcceptTC] = useState(false)
    const [isAdded , setIsAdded] = useState(false)
    const [addressList, setAddressList] = useState([])
    const [isManuallyAddress, setisManuallyAddress] = useState(false);

    const [TRVvalue, setTRVvalue] = useState(null)
    const [AccordionActive, setAccordionActive] = useState('')
    const [installationDate, setInstallationDate] = useState(new Date())
    const [PayaplScriptLoaded, setPaypalScriptLoaded] = useState(false)
    const [isSubmitted, setisSubmitted] = useState(false)

    const [isActive, setisActiveFinance] = useState(null)
    const [paymentBtn, setPaymentBtn] = useState('');

    const [orderID, setOrderID] = useState("");
    const [orderRefID, setOrderRefID] = useState(null);
    //added to remove on option change
    const [trvTempValue, setTrvTempValue] = useState(0);
    const [trvBoolean, setTrvBoolean] = useState(false);
    const [prevTotalPrice, setPrevTotalPrice] = useState(null);
    //to display arp and term
    // const [aprValue, setAprValue] = useState(9.9);
    // const [loanTerm, setLoanTerm] = useState(120);
    const [chat, setChat] = useState(true);
    
    const DetailTitle = useRef();
    const DetailFname = useRef();
    const DetailLname = useRef();
    const DetailEmail = useRef();
    const DetailCEmail = useRef();
    const DetailPhoneNumber = useRef();
    const DetailAddress = useRef();
    const DetailAddress1 = useRef();
    const DetailDiscountCode = useRef();
    const AccordionSection = useRef();
    const CardNumberInput = useRef();
    const CVVInput = useRef();
    const ExpiryDateInput = useRef();
    const DetailPostcode = useRef();
    //To scroll to install date
    const ChooseDate = useRef(null)

    // Email quote
    const [showEmailQuote, setshowEmailQuote] = useState(false)
    const [EmailQuoteBoiler, setEmailQuoteBoiler] = useState('')
    const [EmailQuoteSubscribe, setEmailQuoteSubscribe] = useState(false)

    const EmailQuoteEmail = useRef();
    const EmailQuotePhone = useRef();

    //added for finance loan calculation
    const [monthly, setMonthly] = useState(87.65);
    const [loan, setLoan] = useState();
    const [orderTotal, setOrderTotal] = useState(selectedGrant.total_price);
    const [interest, setInterest] = useState();

    // // To fetch all booked date 
    // useEffect(async () => {
    //     const bookedDate = await fetch(`${process.env.REACT_APP_API_URL}order/bookeddate`);
    //     const bookedDateData = await bookedDate.json();



    // },[])
    // const client = require('braintree-web/client');
    // const hostedFields = require('braintree-web/hosted-fields');
    useEffect(() => {

        document.getElementById(params.scroll).scrollIntoView();

        if (JSON.parse(localStorage.getItem('questions'))) {
            setQuestions(JSON.parse(localStorage.getItem('questions')))
        }
        let gg = [...grant.package_details];
        let qq = []
        let q = gg.filter((g) => {
            let z = { ...g }
            let icon = z.icon.split(".");
            if (icon[0].endsWith("-")) {
                let quests = []
                if (JSON.parse(localStorage.getItem('questions'))) {
                    quests = JSON.parse(localStorage.getItem('questions'))
                }
                let qq = quests.filter((question) => {
                    if (question.question_value == icon[0].substring(0, icon[0].length - 1)) return question;
                })
                if (qq.length > 0) {
                    let selectedOption = qq[0].options.filter((option) => {
                        return (option.isSelected)
                    })
                    z.icon = icon[0] + selectedOption[0].value + "." + icon[1]
                }
            }
            qq.push(z);
            return g
        })
        

        // Payment fields render
        if (clientToken == '') {
            getPaypalTokens()
        }

        if (clientToken != '') {
            console.log(process.env.REACT_APP_PAYPAL_CLIENT_ID)
            var url = `https://www.paypal.com/sdk/js?components=buttons,hosted-fields&client-id=${process.env.REACT_APP_PAYPAL_CLIENT_ID}`; 
            const script = document.createElement('script');

            script.src = url;
            script.async = true;
            script.setAttribute('data-client-token', clientToken);

            script.onreadystatechange = setPaypalScriptLoaded(true)
            // document.body.removeChild(script);
            document.body.appendChild(script);
            // console.log(paypal.HostedFields.isEligible())
        }

        if (addressList.length == 0) {
            getAddressList(postcode)
        }

        aos.init({duration:2000});

        // Set logic of questions wise extra cost START

        if(isAdded == false){
            var ExtraCostQuestions = JSON.parse(localStorage.getItem('questions'));

            var isPlumeKit = false;
            var isVerticalFlue = false;
            var isConvertBoiler = false;
            var isDifferentPlace = false;

            ExtraCostQuestions.forEach((q) => {
                if(q.question_value == 'flue_from_ground'){
                    var selectedOption = q.options.filter((option) => {
                        return (option.isSelected)
                    })
                    if(selectedOption.length > 0){
                        if(selectedOption[0].value == 'less_than_2'){
                            isPlumeKit = true;
                        }
                    }
                }

                if(q.question_value == 'flue_from_another_property'){
                    var selectedOption = q.options.filter((option) => {
                        return (option.isSelected)
                    })
                    if(selectedOption.length > 0){
                        if(selectedOption[0].value == 'less_than_1'){
                            isPlumeKit = true;
                        }
                    }
                }

                if(q.question_value == 'flue_from_window_door'){
                    var selectedOption = q.options.filter((option) => {
                        return (option.isSelected)
                    })
                    if(selectedOption.length > 0){
                        if(selectedOption[0].value == 'no'){
                            isPlumeKit = true;
                        }
                    }
                }

                if(q.question_value == 'flue_under_structure'){
                    var selectedOption = q.options.filter((option) => {
                        return (option.isSelected)
                    })
                    if(selectedOption.length > 0){
                        if(selectedOption[0].value == 'yes'){
                            isPlumeKit = true;
                        }
                    }
                }

                if(q.question_value == 'flue_out'){
                    var selectedOption = q.options.filter((option) => {
                        return (option.isSelected)
                    })
                    if(selectedOption.length > 0){
                        if(selectedOption[0].value == 'roof'){
                            isVerticalFlue = true;
                        }
                    }
                }

                if(q.question_value == 'convert_to_combi'){
                    var selectedOption = q.options.filter((option) => {
                        return (option.isSelected)
                    })
                    if(selectedOption.length > 0){
                        if(selectedOption[0].value == 'yes'){
                            isConvertBoiler = true;
                        }
                    }
                }

                if(q.question_value == 'new_boiler_different_place'){
                    var selectedOption = q.options.filter((option) => {
                        return (option.isSelected)
                    })
                    if(selectedOption.length > 0){
                        if(selectedOption[0].value == 'yes'){
                            isDifferentPlace = true;
                        }
                    }
                }
            })

            // console.log('isPlumeKit', isPlumeKit);
            // console.log('isVerticalFlue', isVerticalFlue);
            // console.log('isConvertBoiler', isConvertBoiler);
            // console.log('isDifferentPlace', isDifferentPlace);

            if(isPlumeKit == true){
                qq.push({
                    "icon": "flue_out-wall.svg",
                    "text": `An added Plume Kit`,
                    "value": '£180',
                })

                // grant.total_price += 180;

            }

            if(isVerticalFlue == true){
                qq.push({
                    "icon": "flue_out-wall.svg",
                    "text": `Vertical Flue`,
                    "value": '£300',
                })
                // grant.total_price += 300;
            }

            if(isConvertBoiler == true){
                qq.push({
                    "icon": "boiler-package-icon.svg",
                    "text": `Convert a ‘System’ boiler to a ‘Combi’ boiler`,
                    "value": '£1,020',
                })

                // grant.total_price += 1020;
            }

            if(isDifferentPlace == true){
                qq.push({
                    "icon": "system-cleanse-package-icon.svg",
                    "text": `Boiler in a different place`,
                    "value": '£1,020',
                })

                // grant.total_price += 1020;
            }
            // console.log("totalgrant", grant.total_price);
            // params.totalPrice(grant.total_price);
            setIsAdded(true)
        }
        // Set logic of questions wise extra cost END

        grant.package_details = qq
        setGrant(grant);

    }, [clientToken, postcode])

    useEffect(() => {
        const w = document.querySelectorAll(".disabled_date")
        w.forEach((ww) => {
            ww.setAttribute('disabled', true)
        })

        const cu = document.querySelectorAll('.react-calendar__month-view__days__day.my_available_date');
        cu.forEach((scu) => {

            scu.addEventListener('focus', (event) => {
                setTimeout(function () {
                    event.target.classList.add("white");
                }, 200)

            });

            scu.addEventListener('blur', (event) => {
                event.target.classList.remove("white");
            });
        })

        if (AccordionActive == 'your_installation') {
            let btn = document.querySelectorAll('.your_installation > .accordion-header > button')[0]
            btn.click();
            window.scrollTo(0, AccordionSection.current.offsetTop+500)
            setAccordionActive('')
        }

        if (AccordionActive == 'your_details') {
            let btn = document.querySelectorAll('.your_details > .accordion-header > button')[0]
            btn.click();
            setAccordionActive('')
        }

        if (AccordionActive == 'installation_address') {
            let btn = document.querySelectorAll('.installation_address > .accordion-header > button')[0]
            btn.click();
            setAccordionActive('')
        }

        if (AccordionActive == 'payment_details') {
            let btn = document.querySelectorAll('.payment_details > .accordion-header > button')[0]
            // if(btn.getAttribute('aria-expanded') === false){
            btn.click();
            // }

            setAccordionActive('')
        }
    })

    //Added by david@JCasp 28-10-2021 to display loan amount to client start
    const calLoan = (result) => {
        setMonthly(result.monthlyPayment);
        setLoan(result.loanAmount);
        setOrderTotal(result.totalRepayment);
        setInterest(result.costOfFinance);
        // grant.total_price = result.totalRepayment;
        // setGrant({...grant})
    }
    //Added by david@JCasp 28-10-2021 to display loan amount to client end

    //added by David@Jcasp 22-11-2021 value change on select option of apr start
    const calculationForAll = (e, totalGrant, option) => {
        // let percentage;
        // let apr;
        // let term;
        // if(percentage === undefined){
        //   percentage = parseInt(RangeFinance);
        // }
        // if(apr === undefined){
        //   apr = parseFloat(aprValue);
        // }
        // if(term === undefined){
        //   term = parseInt(loanTerm);
        // }
        if(option === "range"){
        //   setRangeFinance(parseInt(e.target.value));
          depositPercent = parseInt(e.target.value);
        }
        if(option === "apr"){
          apr = parseFloat(e.target.value);
        //   setAprValue(parseFloat(e.target.value));
          if(apr === 0 || apr === 0.0){
            term = 24;
          }else{
              term = 120;
          }
        //   console.log("loanTermmmmm",loanTerm);
        }
        if(option === "term"){
          term = parseInt(e.target.value);
        //   setLoanTerm(parseInt(term));
        //   console.log("loanTermmmmmmmmm",loanTerm);
        }
        console.log("depositPercent", depositPercent);
        console.log("apr", apr);
        // console.log("aprrrrrr", aprValue)
        console.log("term", term);
        // let depositPercent = parseFloat(e.target.value);
        let depositAmount= Math.floor((depositPercent/100)*totalGrant);
        // console.log("depsit", RangeFinance);
        let result = new window.VendigoCalculatorWidget().calculate(totalGrant, depositAmount, apr, term, 0);
        calLoan(result);
      }
      ////added by David@Jcasp 22-11-2021 value change on select option of apr end

    const scriptLoaded = function () {
        
        setTimeout(function () {
            // console.log('okokoko', window.paypal.HostedFields.isEligible())
            let PaypalorderId;
            if (window.paypal.HostedFields.isEligible() === true) {
                var fontsize = '1rem'
                if (window.innerWidth < 540) {
                    fontsize = '0.9rem'
                } else if (window.innerWidth < 500) {
                    fontsize = '0.7rem'
                }

                window.paypal.HostedFields.render({
                    createOrder: function (data, actions) {
                        return fetch('https://api-m.sandbox.paypal.com/v2/checkout/orders', {
                            method: 'post',
                            body: `{
                                "intent": "CAPTURE",
                                "purchase_units": [{
                                    "amount": {
                                        "currency_code": "USD",
                                        "value": 0.01
                                    }
                                }]
                            }`,
                            headers: {
                                'Accept': 'application/json',
                                'Content-Type': 'application/json',
                                'Authorization': 'Bearer ' + PayPalToken
                            }

                        }).then(function (res) {
                            return res.json();
                        }).then(function (orderData) {
                            PaypalorderId = orderData.id;
                            return PaypalorderId
                        });
                    },
                    styles: {
                        'input': {
                            'font-size': fontsize,
                            'font-family': 'Museo 300',
                            'color': '#212529',
                            'padding': '0px 1%'
                        },
                    },
                    fields: {
                        number: {
                            selector: '#card-number',
                            placeholder: 'Credit Card Number',
                        },
                        cvv: {
                            selector: '#cvv',
                            placeholder: 'CVV',
                        },
                        expirationDate: {
                            selector: '#expiration-date',
                            placeholder: 'MM/YYYY',
                        }
                    }
                }).then(hostedFields => {
                    document.querySelector('#my-sample-form').addEventListener('submit', event => {
                        event.preventDefault();
                        hostedFields.submit().then(payload => {

                            fetch('https://api-m.sandbox.paypal.com/v2/checkout/orders/' + payload.orderId + '/capture/', {
                                method: 'post',
                                headers: {
                                    'Accept': 'application/json',
                                    'Content-Type': 'application/json',
                                    'Authorization': 'Bearer ' + PayPalToken,
                                },
                            }).then(function (res) {
                                console.log(res)
                                if (!res.ok) {
                                    FormErrors.payment = 'Payment is not captured. Use valid card details.'
                                    setFormErrors({ ...FormErrors })
                                    setAccordionActive('payment_details')
                                    setisSubmitted(false)
                                } else {
                                    // Call API to store boiler and payment information.
                                    StoreDetails(payload.orderId, 'success')
                                }
                            })

                        }).catch(error => {
                            setisSubmitted(false)
                            console.log(error)
                            var StringError = 'Please enter correct card details.'
                            // for (let index = 0; index < error.details.length; index++) {
                            //     const errorText = error.details[index];
                            //     StringError += errorText.issue + ': ' + errorText.description + ' \n';
                            // }
                            FormErrors.payment = StringError
                            setFormErrors({ ...FormErrors })
                            setAccordionActive('payment_details')
                        });
                    });
                }).catch(error => {
                    console.log(error)
                });
            } else {

                // Render the PayPal button into #paypal-button-container
                window.paypal.Buttons({
                    style: {
                        layout: 'horizontal'
                    },
                    // Call your server to set up the transaction
                    // createOrder: function(data, actions) {
                    //     return fetch('/demo/checkout/api/paypal/order/create/', {
                    //         method: 'post'
                    //     }).then(function(res) {
                    //         return res.json();
                    //     }).then(function(orderData) {
                    //         return orderData.id;
                    //     });
                    // },

                    // Call your server to finalize the transaction
                    onApprove: function (data, actions) {
                        return fetch('/demo/checkout/api/paypal/order/' + data.orderID + '/capture/', { //remove extra ss from the orderss
                            method: 'post'
                        }).then(function (res) {
                            return res.json();
                        }).then(function (orderData) {
                            // Three cases to handle:
                            //   (1) Recoverable INSTRUMENT_DECLINED -> call actions.restart()
                            //   (2) Other non-recoverable errors -> Show a failure message
                            //   (3) Successful transaction -> Show confirmation or thank you

                            // This example reads a v2/checkout/orders capture response, propagated from the server
                            // You could use a different API or structure for your 'orderData'
                            var errorDetail = Array.isArray(orderData.details) && orderData.details[0];

                            if (errorDetail && errorDetail.issue === 'INSTRUMENT_DECLINED') {
                                return actions.restart(); // Recoverable state, per:
                                // https://developer.paypal.com/docs/checkout/integration-features/funding-failure/
                            }

                            if (errorDetail) {
                                var msg = 'Sorry, your transaction could not be processed.';
                                if (errorDetail.description) msg += '\n\n' + errorDetail.description;
                                if (orderData.debug_id) msg += ' (' + orderData.debug_id + ')';
                                return alert(msg); // Show a failure message (try to avoid alerts in production environments)
                            }

                            // Successful capture! For demo purposes:
                            console.log('Capture result', orderData, JSON.stringify(orderData, null, 2));
                            var transaction = orderData.purchase_units[0].payments.captures[0];
                            alert('Transaction ' + transaction.status + ': ' + transaction.id + '\n\nSee console for all available details');

                            // Replace the above to show a success message within this page, e.g.
                            // const element = document.getElementById('paypal-button-container');
                            // element.innerHTML = '';
                            // element.innerHTML = '<h3>Thank you for your payment!</h3>';
                            // Or go to another URL:  actions.redirect('thank_you.html');
                        });
                    }

                }).render('#paypal-button-container');
            }
        }, 1000)
    }


    // Get tokens and client secret
    const getPaypalTokens = function () {
        var qs = require('qs');
        var data = qs.stringify({
            'grant_type': 'client_credentials'
        });
        var config = {
            method: 'post',
            url: `${process.env.REACT_APP_PAYPAL_URL}/oauth2/token`,
            headers: {
                'Authorization': 'Basic QVo1SHAtSzlRcncxV294bkNVN3FZVE8wQTJVWmE1NnZnYVplZWl3S0lMYVpleWZwOUdkck9rVDFIanBVVjhTS3RWVFRzaGtwS3F6Zm8zS246RUxRV2VXb2RwMWpvX2ZDWmlrOEZmSi1jQVlNbEM2LTNVUE40TFd6cTA1QUpIaklzS0dxbjN2bllmNW8yUVp3QWEtWEFPR3VHT2J4MGZIUXc=',
                'Content-Type': 'application/x-www-form-urlencoded'
            },
            data: data
        };

        axios(config)
            .then(res => {
                PayPalToken = res.data.access_token;
                var configInner = {
                    method: 'post',
                    url: `${process.env.REACT_APP_PAYPAL_URL}/identity/generate-token`,
                    headers: {
                        'Authorization': 'Bearer ' + res.data.access_token
                    }
                };

                axios(configInner)
                    .then(function (response) {
                        // console.log('aa', response.data.client_token)
                        setClientToken(response.data.client_token)

                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            })

    }

    // Get Address list
    const getAddressList = function (code) {
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_ADDRESS_API_URL}${code}?api-key=${process.env.REACT_APP_ADDRESS_API_KEY}`,
        };

        axios(config)
            .then(res => {
                console.log(res)
                var newAddress = []
                var Addresses = res.data.Addresses;
                for (let index = 0; index < Addresses.length; index++) {
                    const a = Addresses[index];
                    var address_string = a.split(',')[0]
                    newAddress.push(address_string)
                }

                setAddressList(newAddress)

            }).catch(error => {
                setAddressList([])
            })
    }

    const handleInstallation = function () {
        if(PymentMethod === 'card'){
            if (PayaplScriptLoaded) {
                setInstallation(true)
                scriptLoaded()
            } else {
                var myCheck = setInterval(function () {
                    if (window.paypal) {
                        setInstallation(true)
                        scriptLoaded()
                        clearInterval(myCheck)
                    }
                }, 1000)
            }
        } else {
            setInstallation(true)
        }
        // document.getElementById("chooseDate").scrollIntoView();
        // ChooseDate.current.scrollIntoView({ behavior: "smooth" });
        // window.scrollTo({
        //     top: ChooseDate.current.offsetTop,
        //     behavior: "smooth",
        //     // You can also assign value "auto"
        //     // to the behavior parameter.
        // });
        window.scrollTo(0,0)
    }

    // added by David@JCasp 28-10-2021 start
    const handleRangeChange = function(val, totalGrant) {
        console.log("the result.....")
        // setRangeFinance(val.target.value);
        depositPercent = val.target.value;
        let depositAmount =parseFloat(val.target.value);
        let result = new window.VendigoCalculatorWidget().calculate(totalGrant, depositAmount, 9.9, 36, 0);
        calLoan(result);
        console.log("The result of loan amount", result);
      };
    // added by David@JCasp 28-10-2021 start

    //for live chat pop up
    const liveChat = () => {
        if(chat){
            window.HubSpotConversations.widget.open();
            setChat(false)
        }else{
            window.HubSpotConversations.widget.close();
            setChat(true)
        }
    }

    const StoreDetails = async function (paypalID, paymentStatus) {

        let paymentData = {
            "status": paymentStatus,
            "applicationId": paypalID,
            "orderId": myOrderID,
            "payment_method": PymentMethod
        }

        const paymentResponse  =  await fetch(`${process.env.REACT_APP_API_URL}order/statusorder`,{
            method:'POST',
            headers: {
                "Content-type": 'application/json',
            },
            body: JSON.stringify(paymentData)
        })

        const resPay = await paymentResponse.json();
        await setisSubmitted(false)
        setpaymentSuccess(true)
        setOrderRefID(paypalID)
        window.scrollTo(0,0)

        // AllReq.payment_details = {
        //     method: PymentMethod
        // }

        // if (PymentMethod == 'card') {
        //     AllReq.payment_details.paypalOrderID = orderID
        // } else {
        //     AllReq.payment_details.windigo = 'Vendigo'
        // }

        // let questions = localStorage.getItem('questions') ? localStorage.getItem('questions') : [];
        // questions = JSON.parse(questions)

        // let selectedQueation = [];

        // for (let index = 0; index < questions.length; index++) {
        //     const q = questions[index];
        //     var selectedOption = q.options.filter(option => option.isSelected == true)

        //     let so = null;
        //     if (selectedOption.length > 0) {
        //         so = selectedOption[0].value
        //     }
        //     selectedQueation.push({
        //         question_value: q.question_value,
        //         selectedOption: so
        //     })
        // }

        // AllReq.questions = selectedQueation;

        // var config = {
        //     method: 'post',
        //     url: `${process.env.REACT_APP_API_URL}/order/place`,
        //     headers: {
        //         'Content-Type': 'application/json'
        //     },
        //     data: AllReq
        // };

        // if (isSubmitted == false) {
        //     axios(config)
        //         .then(res => {
        //             setisSubmitted(false)
        //             // Swal.fire(
        //             //     'Success!',
        //             //     'Order placed successfully.',
        //             //     'success'
        //             // )
        //             setpaymentSuccess(true)
        //             window.scrollTo(0,0)

        //         }).catch(err => {
        //             setisSubmitted(false)
        //             Swal.fire(
        //                 'Error!',
        //                 'Internal server error.',
        //                 'error'
        //             )
        //         })
        // }

    }

    const handlePaymentMethod = function (method, totalGrant) {
        apr = 9.9;
        term = 120;
        depositPercent = 50;

        let result = new window.VendigoCalculatorWidget().calculate(totalGrant, Math.floor(totalGrant/2), apr, term, 0);
        calLoan(result);

        setPymentMethod(method);
    }

    const handleManuallyAddress = function () {
        var newValue = !isManuallyAddress
        setisManuallyAddress(newValue)
    }

    const handleAddressChange = function () {
        var code = DetailPostcode.current.value;
        getAddressList(code)
    }

    const handleTRV = function (event) {
        const val = event.target.value
        const TRVfound = grant.TRV_prices.find(price => price.number == val);
        if (TRVfound) {
            var currency = (grant.currency == "GBP") ? "£" : grant.currency;
            var alreadyPackage = grant.package_details.find(pack => pack.TRV_key != undefined)

            if (alreadyPackage) {
                var index = grant.package_details.findIndex(pack => pack.TRV_key != undefined)
                grant.package_details[index] = {
                    "icon": "controls-package-icon.svg",
                    "text": `${TRVfound.number} - TRV`,
                    "value": `${currency} ${20 * TRVfound.number}`,
                    "TRV_key": true
                }
            } else {
                grant.package_details.push({
                    "icon": "controls-package-icon.svg",
                    "text": `${TRVfound.number} TRV`,
                    "value": `${currency} ${20 * TRVfound.number}`,
                    "TRV_key": true
                })
            }
            
            if(trvBoolean === false){
                console.log("trvBoolean to false");
                grant.total_price = grant.total_price + 20*TRVfound.number;
                setTrvTempValue(20*TRVfound.number);
                setTrvBoolean(true);
            }else{
                console.log("trvBoolean to true", trvTempValue);
                grant.total_price = (grant.total_price + 20*TRVfound.number) - trvTempValue;
                setTrvTempValue(20*TRVfound.number);
            }
            

        } else if (val == 0) {
            var alreadyPackage = grant.package_details.find(pack => pack.TRV_key != undefined)

            if (alreadyPackage) {
                var index = grant.package_details.findIndex(pack => pack.TRV_key != undefined)
                grant.package_details.splice(index, 1)
                // grant.total_price = grant.total_price - 20;
                grant.total_price = grant.total_price - trvTempValue;
                setTrvTempValue(0);
            }

        }
        //original file
        // if (TRVfound) {
        //     var currency = (grant.currency == "GBP") ? "£" : grant.currency;
        //     var alreadyPackage = grant.package_details.find(pack => pack.TRV_key != undefined)

        //     if (alreadyPackage) {
        //         var index = grant.package_details.findIndex(pack => pack.TRV_key != undefined)
        //         grant.package_details[index] = {
        //             "icon": "controls-package-icon.svg",
        //             "text": `${TRVfound.number} - TRV`,
        //             "value": `${currency} ${TRVfound.price}`,
        //             "TRV_key": true
        //         }
        //     } else {
        //         grant.package_details.push({
        //             "icon": "controls-package-icon.svg",
        //             "text": `${TRVfound.number} TRV`,
        //             "value": `${currency} ${TRVfound.price}`,
        //             "TRV_key": true
        //         })
        //     }

        //     grant.total_price = grant.total_price + TRVfound.price
        // } else if (val == 0) {
        //     var alreadyPackage = grant.package_details.find(pack => pack.TRV_key != undefined)

        //     if (alreadyPackage) {
        //         var index = grant.package_details.findIndex(pack => pack.TRV_key != undefined)
        //         grant.package_details.splice(index, 1)
        //         grant.total_price = grant.total_price - 20
        //     }

        // }

        setGrant({ ...grant })
    }

    // Full mark on datepicker
    const mark = [
        // '22-11-2021',
        // '24-11-2021',
        // '20-11-2021'
    ]

    // Available date mark on datepicker
    const availabel = [
        // '23-11-2021'
    ]

    // Handle Forms
    const handleDetailfrm = function () {
        if (DetailFname.current.value == '') {
            FormErrors.fname = 'Please enter first name.'
        } else {
            if (/[^a-zA-Z\-\/]/.test(DetailFname.current.value)) {
                FormErrors.fname = "Valid name includes alphabets only. No special characters or numbers are allowed" 
            }else {
                delete FormErrors.fname;
            }
        }

        if (DetailLname.current.value == '') {
            FormErrors.lname = 'Please enter last name.'
        } else {
            if (/[^a-zA-Z\-\/]/.test(DetailLname.current.value)) {
                FormErrors.lname = "Valid name includes alphabets only. No special characters or numbers are allowed"
            }else {
                delete FormErrors.lname;
            }
        }

        if (DetailEmail.current.value == '') {
            FormErrors.email = 'Please enter email.'
        } else if (!validator.isEmail(DetailEmail.current.value)) {
            FormErrors.email = 'Please enter valid email.'
        } else {
            delete FormErrors.email;
        }

        if (DetailCEmail.current.value == '') {
            FormErrors.cemail = 'Please enter confirm email.'
        } else if (!validator.isEmail(DetailCEmail.current.value)) {
            FormErrors.cemail = 'Please enter valid email.'
        } else if (!validator.equals(DetailEmail.current.value, DetailCEmail.current.value)) {
            FormErrors.cemail = 'Confirm email did not match.'
        } else {
            delete FormErrors.cemail;
        }

        if (DetailPhoneNumber.current.value == '') {
            FormErrors.phone = 'Please enter phone number.'
        } else if (!validator.isMobilePhone(DetailPhoneNumber.current.value)) {
            FormErrors.phone = 'Please enter valid phone number.'
        } else {
            delete FormErrors.phone;
        }

        if (DetailTitle.current.value == '') {
            FormErrors.title = 'Please select title.'
        } else {
            delete FormErrors.title;
        }

        setFormErrors({ ...FormErrors })
        if (Object.keys(FormErrors).length === 0) {
            setAccordionActive('installation_address')
        }

    }

    const handleAddressfrm = function () {

        if (DetailPostcode.current.value == '') {
            FormErrors.postcode = 'Please enter postcode.'
        } else {
            delete FormErrors.postcode;
        }

        if (isManuallyAddress == true) {
            if (DetailAddress1.current.value == '') {
                FormErrors.address1 = 'Please enter address.'
            } else {
                delete FormErrors.address1;
            }
        } else {
            if (DetailAddress.current.value == '') {
                FormErrors.address = 'Please enter address.'
            } else {
                delete FormErrors.address;
            }
        }


        setFormErrors({ ...FormErrors })
        if (Object.keys(FormErrors).length === 0) {
            setAccordionActive('payment_details')
        }
    }

    const handleInputChange = function (e) {
        console.log(e)
    }

    const handleDiscount = function () {
        if (DetailDiscountCode.current.value == '') {
            FormErrors.discount_code = 'Please enter discount code.'
        } else {
            delete FormErrors.discount_code;
        }

        setFormErrors({ ...FormErrors })
    }

    const handleConfrimDate = function () {
        setAccordionActive('your_installation')
    }

    const handleInstallationDate = function (date) {
        setInstallationDate(new Date(date))
    }

    const handleYourInstallation = function () {
        setAccordionActive('your_details')
    }

    const handleTC = function () {
        var newTC = (acceptTC == false) ? true : false;
        setAcceptTC(newTC)

    }

    //E-commerce widget
    var vendigoWidget = new window.VendigoWidget({
        onApplicationCreated: function(application) {
        console.log('application created', application);
        },
        onApplicationUpdated: function(application) {
        console.log('application updated', application);
        },
        onExit: async function(application) {
        console.log('widget closed', application);

            let paymentStatus = application.status;
            let appID = application.applicationId;
            let odrID = application.orderId;

            let paymentData = {
                "status": paymentStatus,
                "applicationId": appID,
                "orderId": odrID,
                "payment_method": PymentMethod,
            }

            const paymentResponse  =  await fetch(`${process.env.REACT_APP_API_URL}order/statusorder`,{
                method:'POST',
                headers: {
                    "Content-type": 'application/json',
                },
                body: JSON.stringify(paymentData)
            })

            const resPay = await paymentResponse.json();
            console.log("Response from the vendigo", resPay);
            await setisSubmitted(false)
            if(resPay.success.code === "JCT026"){
                if(resPay.status === 'REFERRED'){
                    await setOrderRefID(appID)
                    await setpaymentSuccess(true);
                    Swal.fire(
                        'Referred!',
                        'The lender needs to conduct further checks on the customer, before an underwriter can make a decision regarding the application.',
                        'info'
                    )
                }
                if(resPay.status === "ERROR"){
                    await setOrderRefID(appID)
                    await setpaymentSuccess(false)
                    Swal.fire(
                        'Error!',
                        'Internal error in the lenders system.',
                        'error'
                    )
                }
                if(resPay.status === "CANCELLED"){
                    await setOrderRefID(appID)
                    await setpaymentSuccess(false);
                    Swal.fire(
                        'Cancelled!',
                        'The loan application has been cancelled.',
                        'error'
                    )
                }
                if(resPay.status === "APPROVED"){
                    await setOrderRefID(appID)
                    await setpaymentSuccess(true)
                    Swal.fire(
                        'Approved!',
                        'The loan application has been approved. Sign the contracts if you wish to accept the loan.',
                        'success'
                    )
                }
                if(resPay.status === "STARTED"){
                    await setOrderRefID(appID)
                    await setpaymentSuccess(true);
                    Swal.fire(
                        'Started!',
                        'The application has been started but it has not been submitted to our lenders yet.',
                        'info'
                    )
                }
                if(resPay.status === "QUOTE"){
                    await setOrderRefID(appID)
                    await setpaymentSuccess(false)
                    Swal.fire({
                        title:'Quote!',
                        text:'You have not completed your loan application - would you like some help?',
                        icon:'info',
                        confirmButtonText: `Chat with us`,
                        showCancelButton: true,
                      }).then((result) => {
                        /* Read more about isConfirmed, isDenied below */
                        if (result.isConfirmed) {
                          window.HubSpotConversations.widget.open();
                        } 
                      })                  
                }
                if(resPay.status === "PENDING"){
                    await setOrderRefID(appID)
                    await setpaymentSuccess(true);
                    Swal.fire(
                        'Pending!',
                        'The application is currently working its way through our panel of lenders.',
                        'info'
                    )
                }
                if(resPay.status === "CONDITIONALLY APPROVED"){
                    await setOrderRefID(appID)
                    await setpaymentSuccess(true)
                    Swal.fire(
                        'Conditionally approved!',
                        'Your application has been approved by a lender, pending the submission of some additional information.',
                        'info'
                    )
                }
                if(resPay.status === "DECLINED"){
                    await setOrderRefID(appID)
                    await setpaymentSuccess(false);
                    Swal.fire(
                        'Declined!',
                        'Unfortunately, your loan application has been declined by our lenders.',
                        'info'
                    )
                }
                if(resPay.status === "ACTIVE"){
                    await setOrderRefID(appID)
                    await setpaymentSuccess(true)
                    Swal.fire(
                        'Active!',
                        'The contracts have been signed by the customer, so the loan is now active.',
                        'info'
                    )
                }
                if(resPay.status === "PAID"){
                    await setOrderRefID(appID)
                    await setpaymentSuccess(true);
                    Swal.fire(
                        'Paid!',
                        'The lender has disbursed funds for this loan.',
                        'success'
                    )
                }
                if(resPay.status === "ISSUE RAISED"){
                    await setOrderRefID(appID)
                    await setpaymentSuccess(false)
                    Swal.fire(
                        'Issue raised!',
                        'The customer has raised an issue.',
                        'info'
                    )
                }
                if(resPay.status === "NO MATCHING LENDER"){
                    await setOrderRefID(appID)
                    await setpaymentSuccess(false);
                    Swal.fire(
                        'No matching lender!',
                        'None of our lenders will accept this application due to the reason shown.',
                        'info'
                    )
                }
                if(resPay.status === "EXPIRED"){
                    await setOrderRefID(appID)
                    await setpaymentSuccess(false)
                    Swal.fire(
                        'Expired!',
                        'The loan application has expired.',
                        'info'
                    )
                }
                if(resPay.status === "ADDRESS MISMATCH"){
                    await setOrderRefID(appID)
                    await setpaymentSuccess(false);
                    Swal.fire(
                        'Address mismatch!',
                        'Our lenders cannot match the address against their database of addresses.',
                        'info'
                    )
                }
                if(resPay.status === "CONTRACTS PENDING"){
                    await setOrderRefID(appID)
                    await setpaymentSuccess(true)
                    Swal.fire(
                        'Contracts pending!',
                        "The customer has been approved but our system hasn't received the contracts yet",
                        'success'
                    )
                }
                if(resPay.status === "TO BE PAID"){
                    await setOrderRefID(appID)
                    await setpaymentSuccess(true);
                    Swal.fire(
                        'To be paid!',
                        'The lender has been notified to disburse the funds.',
                        'info'
                    )
                }
                if(resPay.status === "SATISFACTION NOTE"){
                    await setOrderRefID(appID)
                    await setpaymentSuccess(true)
                    Swal.fire(
                        'Satisfaction note!',
                        "Sign the satisfaction note for the funds to be disbursed or raise an issue if they are unhappy with the merchant's work.",
                        'info'
                    )
                }
            }

        },
    });



    // Boiler Submission
    const handleSubmission = async function () {

        delete FormErrors.payment
        delete FormErrors.acceptTC
        setFormErrors({ ...FormErrors })
        if (acceptTC === false) {
            FormErrors.acceptTC = 'Please accept terms and condition.'
            setFormErrors({ ...FormErrors })
            return;
        }

        var selectedDate = moment(installationDate).valueOf();
        handleDetailfrm();

        AllReq.installation_date = selectedDate;


        console.log("installationDate: ", installationDate)

        const months = ["January", "February", "March", 
        "April", "May", "June", "July", "August", "September", 
        "October", "November", "December"];



        //Convert install date to desired format


        if (Object.keys(FormErrors).length === 0) {
            var details = {
                'title': DetailTitle.current.value,
                'first_name': DetailFname.current.value,
                'last_name': DetailLname.current.value,
                'email': DetailEmail.current.value,
                'phone_number': DetailPhoneNumber.current.value,
            }
            AllReq.customer_details = details;
            handleAddressfrm()
            if (Object.keys(FormErrors).length === 0) {
                var address = {
                    'post_code': DetailPostcode.current.value,
                    'address_line_1': DetailAddress.current.value,
                }

                if (isManuallyAddress == true) {
                    address.address_line_2 = DetailAddress1.current.value;
                }

                AllReq.installation_details = address
                AllReq.boiler_details = {
                    key: grant.key,
                    package_details: grant.package_details,
                    total_price: grant.total_price
                }

                setAllReq({ ...AllReq.boiler_details.key })
                
                const loan_details = {
                    "apr": apr,
                    "term": term,
                    "deposit": Math.floor((depositPercent/100)*grant.total_price),
                    "monthlyPayment": monthly,
                    "interest": interest,
                    "loan": loan,
                    "order": orderTotal
                }
                console.log("loan_details", loan_details);

                AllReq.loan_detail = loan_details;
                AllReq.payment_details = {
                    method: PymentMethod,
                    paypalOrderID: null
                };

                setisSubmitted(true)

                //Attempted questions:
                let questions = localStorage.getItem('questions') ? localStorage.getItem('questions') : [];
                questions = JSON.parse(questions)

                let selectedQueation = [];

                for (let index = 0; index < questions.length; index++) {
                    const q = questions[index];
                    var selectedOption = q.options.filter(option => option.isSelected == true)

                    let so = null;
                    if (selectedOption.length > 0) {
                        so = selectedOption[0].value
                    }
                    selectedQueation.push({
                        question_value: q.question_value,
                        selectedOption: so
                    })
                }

                AllReq.questions = selectedQueation;

                setAllReq({...AllReq})
                console.log("ALLREG", AllReq);
                //Create a send data to database
                const quoteResponse =  await fetch(`${process.env.REACT_APP_API_URL}order/createorder`,{
                    method:'POST',
                    headers: {
                        "Content-type": 'application/json',
                    },
                    body: JSON.stringify(AllReq)
                })
                // console.log("Userinfo...", data);

                const quoteResponseData = await quoteResponse.json()

                setOrderID(quoteResponseData.orderId);
                myOrderID = quoteResponseData.orderId
                if(quoteResponseData.success.code === "JCT026"){
                    if(PymentMethod === 'card'){
                        console.log('my req', AllReq)
                        document.querySelector('#submit').click();
                    } else {
                        vendigoWidget.open(quoteResponseData.hash);
                    }
                }
                

            } else {
                setAccordionActive('installation_address')
            }
        } else {
            setAccordionActive('your_details')
        }

    }

    // Email quote submit
    const handleEmailQuote = function () {
        setEmailQuoteBoiler(grant.key)
        setshowEmailQuote(true);
    }

    const closeEmailQuote = function () {
        setshowEmailQuote(false);
        setEmailQuoteBoiler('')
        setEmailQuoteSubscribe(false)
        delete FormErrors.QuoteEmail;
        delete FormErrors.QuotePhone;

        setFormErrors({ ...FormErrors })
        EmailQuoteEmail.current.value = ''
        EmailQuotePhone.current.value = ''
    }

    const submitEmailQuote = function () {
        if (EmailQuoteEmail.current.value == '') {
            FormErrors.QuoteEmail = 'Please enter email.'
        } else if (!validator.isEmail(EmailQuoteEmail.current.value)) {
            FormErrors.QuoteEmail = 'Please enter valid email.'
        } else {
            delete FormErrors.QuoteEmail;
        }

        if (EmailQuotePhone.current.value == '') {
            FormErrors.QuotePhone = 'Please enter phone number.'
        } else if (!validator.isMobilePhone(EmailQuotePhone.current.value)) {
            FormErrors.QuotePhone = 'Please enter valid phone number.'
        } else {
            delete FormErrors.QuotePhone;
        }

        if (EmailQuotePhone.current.value == '') {
            FormErrors.QuotePhone = 'Please enter phone number.'
        } else if (!validator.isMobilePhone(EmailQuotePhone.current.value)) {
            FormErrors.QuotePhone = 'Please enter valid phone number.'
        } else {
            delete FormErrors.QuotePhone;
        }

        setFormErrors({ ...FormErrors })

        if (Object.keys(FormErrors).length === 0) {

            let questions = localStorage.getItem('questions') ? localStorage.getItem('questions') : [];
            questions = JSON.parse(questions)

            let selectedQueation = [];

            for (let index = 0; index < questions.length; index++) {
                const q = questions[index];
                var selectedOption = q.options.filter(option => option.isSelected == true)

                let so = null;
                if (selectedOption.length > 0) {
                    so = selectedOption[0].value
                }
                selectedQueation.push({
                    question_value: q.question_value,
                    selectedOption: so
                })
            }

            var QuoteRequests = {
                email: EmailQuoteEmail.current.value,
                boiler_key: EmailQuoteBoiler,
                telephone: EmailQuotePhone.current.value,
                subscribe: EmailQuoteSubscribe,
                questions: selectedQueation
            };
            var config = {
                method: 'post',
                url: `${process.env.REACT_APP_API_URL}/quote/sent-email`,
                headers: {
                    'Content-Type': 'application/json'
                },
                data: QuoteRequests
            };
            setisSubmitted(true)
            axios(config)
                .then(res => {
                    setisSubmitted(false)
                    Swal.fire(
                        'Success!',
                        'Quote submitted successfully.',
                        'success'
                    ).then((result) => {
                        closeEmailQuote();
                    })
                }).catch(err => {
                    setisSubmitted(false)
                    Swal.fire(
                        'Error!',
                        'Internal server error.',
                        'error'
                    ).then((result) => {
                        closeEmailQuote();
                    })
                })

            return ''
        } else {
            return ''
        }
    }

    const handleSunscribe = function () {
        var newSubscribe = (EmailQuoteSubscribe == false) ? true : false;
        setEmailQuoteSubscribe(newSubscribe)
    }

    const handleFinance = function (id, totalGrant) {

        let result = new window.VendigoCalculatorWidget().calculate(totalGrant, Math.floor(totalGrant/2), apr, term, 0);
        calLoan(result)

        let financeOptions = document.querySelector("#finance-" + id)
        let boilerCard = financeOptions.parentNode.parentNode.parentNode;
        if (isActive === id) {
            setisActiveFinance(null)
            boilerCard.querySelector(".row").style.paddingBottom = `4vw`
        } else {
            setisActiveFinance(id)
            setTimeout(function () {

                let bh = (boilerCard.offsetHeight * 75) / 100;
                let style = window.getComputedStyle(boilerCard.querySelector(".row"))
                let v = style.getPropertyValue('padding-bottom');
                console.log(financeOptions.offsetHeight, boilerCard.offsetHeight, bh)
                if (financeOptions.offsetHeight > bh) {
                    boilerCard.querySelector(".row").style.paddingBottom = `calc(${v} + ${(financeOptions.offsetHeight - (bh * 0.85))}px)`
                } else {
                    boilerCard.querySelector(".row").style.paddingBottom = `calc(${v})`
                }
            }, 10)
        }
    }

    const closeFinance = function (i) {
        setisActiveFinance(null)
        let financeOptions = document.querySelector("#finance-" + i)
        let boilerCard = financeOptions.parentNode.parentNode.parentNode;
        boilerCard.querySelector(".row").style.paddingBottom = `4vw`
    }

    if (!selectedGrant || Object.keys(selectedGrant).length === 0) {
        return (<><h1>Invalid Grant</h1></>)
    }
    return (
        <>
            {
                (installation === false) ? (<>
                    <h1>Great choice!</h1>
                    <div className="boiler-card first-boiler boiler-detail">
                        <div className="row">
                            <div className="col-md-8">
                                <h2>{grant.name}</h2>
                                <div className="row">
                                    <div className="col-md-6">
                                        <img src={"./grants/" + grant.boiler_image} alt="" />
                                        {
                                            (grant.best_buy_image) ?
                                                <img className="best_buy_logo" src={"./grants/" + grant.best_buy_image} alt="" />
                                                : ""
                                        }
                                    </div>
                                    <div className="col-md-6">
                                        <img src={"./grants/" + grant.detailpage_warranty_image} alt="" />
                                        <img src={"./grants/" + grant.detailpage_other_image} alt="" />
                                    </div>
                                </div>
                                <div className="pos-relative">
                                    <div className="grey-card">
                                        <div className="grey-title">
                                            Fixed price including installation:
                                        </div>
                                        <div className="grey-subtitle">
                                            <span>{(grant.currency == "GBP") ? "£" : grant.currency}{(grant.total_price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> inc VAT or <span>{(grant.currency == "GBP") ? "£" : grant.currency}{grant.base_price_per_month}</span> per month
                                        </div>
                                        <div className="grey-card-lower" id={params.scroll}>
                                            <div className="text-part">
                                                <div className="row">
                                                    <GetYourQuoteBtn action={() => { handleFinance(1, grant.total_price) }} text="Finance options" bgColor="green" color="white" />
                                                </div>
                                                <div className="email-quote">
                                                    <div onClick={handleEmailQuote}>
                                                        <img src={emailIcon} alt="" />
                                                        Email your quote
                                                    </div>
                                                    <div>
                                                        <img src={chatIcon} alt="" />
                                                        Chat with us 24/7
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div id={"finance-1"} className={(isActive === 1) ? "pos-absolute show z-index-999" : "pos-absolute"} >
                                        <div className="finance-section">
                                            <div className="finance-close-icon" onClick={() => closeFinance(1)}>X</div>
                                            <div className="finance-box">
                                                <h1>Pay Monthly</h1>
                                                <p>Please check that you have selected the correct Finance Plan with Payment Term before proceeding.</p>
                                                <form>

                                                    <div className="form-group">
                                                        <label className="control-label">Finance Plan</label>
                                                        <select className="dropdown" onChange ={e => calculationForAll(e, grant.total_price, "apr")}>
                                                            {
                                                                (grant.interest_free)?(
                                                                    <>
                                                                        <option value="9.9" selected>9.9% APR</option>
                                                                        <option value="0.0">0.0% APR</option>
                                                                    </>
                                                                    ):(
                                                                    <><option value="9.9" selected>9.9% APR</option></>)
                                                            }
                                                        </select>
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="control-label">Choose Payment Term</label>
                                                            <select className="dropdown" onChange ={e => calculationForAll(e, grant.total_price, "term")}>
                                                            {
                                                                grant.interest_free?(
                                                                    (apr === 0.0)?(
                                                                        <>
                                                                            <option value="12">1 Years (12 Months)</option>
                                                                            <option value="24" selected>2 Years (24 Months)</option>
                                                                        </>
                                                                    ):(
                                                                        <>
                                                                            {/* <option>3 Years (36 Months)</option>
                                                                            <option>4 Years (48 Months)</option>
                                                                            <option>5 Years (60 Months)</option> */}
                                                                            <option value="36">3 Years (36 Months)</option>
                                                                            <option value="48">4 Years (48 Months)</option>
                                                                            <option value="60">5 Years (60 Months)</option>
                                                                            <option value="84">7 Years (84 Months)</option>
                                                                            <option value="120" selected>10 Years (120 Months)</option>
                                                                        </>
                                                                    )  
                                                                ):(
                                                                    <>
                                                                        <option value="36">3 Years (36 Months)</option>
                                                                        <option value="48">4 Years (48 Months)</option>
                                                                        <option value="60">5 Years (60 Months)</option>
                                                                        <option value="84">7 Years (84 Months)</option>
                                                                        <option value="120" selected>10 Years (120 Months)</option>
                                                                    </>
                                                                )
                                                                
                                                            }
                                                        </select>
                                                    </div>

                                                    <div className="form-group">
                                                        <label className="control-label">Choose Deposit {`£${(Math.floor((depositPercent/100)*grant.total_price)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}`} ({depositPercent}%)</label>
                                                        {/* <input className="range-slider" type="range" min="0" max="100" step="1" value={RangeFinance} onChange={(e) => handleRangeChange(e, grant.total_price)}/> */}
                                                        <input className="range-slider" type="range" min="0" max="50" step="1" value={depositPercent} onChange={(e) => calculationForAll(e, grant.total_price, "range")}/>
                                                    </div>

                                                    <div className="form-group info">
                                                        <p><b>Monthly Payment: </b> <span className="text-right">{String(monthly).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></p>
                                                        <p><b>Interest: </b> <span className="text-right">{String(interest).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></p>
                                                        <p><b>Loan Amount:</b> <span className="text-right">{String(loan).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></p>
                                                        <p><b>Order Total:</b> <span className="text-right">{String(orderTotal).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></p>
                                                    </div>
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <Accordion >
                                    <Accordion.Item eventKey="0" className="white-card">
                                        <Accordion.Header>Product description</Accordion.Header>
                                        <Accordion.Body>
                                            <div className="desc-list-wrapper">
                                                {
                                                    grant.product_description.list.map(desc =>
                                                        <div className="desc-list flexbox flexbox-space-between">
                                                            <span className="color-skyblue museo-700" dangerouslySetInnerHTML={{ __html: desc.text }}></span>
                                                            <span dangerouslySetInnerHTML={{ __html: desc.value }}></span>
                                                        </div>
                                                    )

                                                }
                                            </div>
                                            <GetYourQuoteBtn text="Dimensions" bgColor="skyblue" color="white" />
                                            <div className="row dimensions">
                                                <div className="col-md-4">
                                                    <span className="museo-700">H</span> - {grant.dimensions.height}
                                                </div>
                                                <div className="col-md-4">
                                                    <span className="museo-700">H</span> - {grant.dimensions.width}
                                                </div>
                                                <div className="col-md-4">
                                                    <span className="museo-700">H</span> - {grant.dimensions.depth}
                                                </div>
                                            </div>
                                            {
                                                grant.product_description.points.map(point =>
                                                    <div className="points-list flexbox">
                                                        <img src={greenRightTick} alt="" />
                                                        <span dangerouslySetInnerHTML={{ __html: point }}></span>
                                                    </div>
                                                )

                                            }
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item className="included-accord" eventKey="1">
                                        <Accordion.Header>Whats included?</Accordion.Header>
                                        <Accordion.Body>
                                            <p>Your boiler comes with everything you need</p>
                                            {
                                                grant.included.map(point =>
                                                    <div className="points-list flexbox">
                                                        <img src={greenRightTick} alt="" />
                                                        <span dangerouslySetInnerHTML={{ __html: point }}></span>
                                                    </div>
                                                )
                                            }
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item className="heating-accord" eventKey="2">
                                        <Accordion.Header>Heating controls?</Accordion.Header>
                                        <Accordion.Body>
                                            <p dangerouslySetInnerHTML={{ __html: grant.heating_controls.desc }}></p>
                                            <div className="row">
                                                <div className="col-md-4">
                                                    <img src={"./grants/" + grant.heating_controls.image} alt="" />
                                                </div>
                                                <div className="col-md-8">
                                                    {
                                                        grant.heating_controls.points.map(point =>
                                                            <div className="points-list flexbox">
                                                                <img src={greenRightTick} alt="" />
                                                                <span dangerouslySetInnerHTML={{ __html: point }}></span>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                    <Accordion.Item className="radiator-accord" eventKey="3">
                                        <Accordion.Header>Thermo radiator controls</Accordion.Header>
                                        <Accordion.Body>
                                            <p> TRV's replace <span className="color-skyblue">standard valves</span>. They are <span className="color-skyblue">recommended because they:</span></p>
                                            <div className="row">
                                                <div className="points-list flexbox">
                                                    <img src={greenRightTick} alt="" />
                                                    <span>Use less energy than standard radiator valves</span>
                                                </div>
                                                <div className="points-list flexbox">
                                                    <img src={greenRightTick} alt="" />
                                                    <span>Save you money on your heating bill</span>
                                                </div>
                                                <div className="points-list flexbox">
                                                    <img src={greenRightTick} alt="" />
                                                    <span>Are better for the environment</span>
                                                </div>
                                            </div>
                                            <div className="radiator-card">
                                                <div className="row">
                                                    <div className="col-md-3">
                                                        <img src={radiatorImg} alt="" />
                                                    </div>
                                                    <div className="col-md-9">
                                                        <h3>Add TRV's to your order</h3>
                                                        <p>Choose how many TRV's you would like below</p>
                                                        <p className="color-skyblue">(only one needed per radiator)</p>
                                                        <select onChange={handleTRV} value={TRVvalue}>
                                                            <option value="0">0 - {(grant.currency == "GBP") ? "£" : grant.currency} 0</option>
                                                            {
                                                                grant.TRV_prices.map(trv =>
                                                                    <option value={trv.number}>{trv.number} - {(grant.currency == "GBP") ? "£" : grant.currency} {trv.number*trv.price}</option>
                                                                )
                                                            }
                                                        </select>
                                                    </div>
                                                </div>
                                            </div>
                                        </Accordion.Body>
                                    </Accordion.Item>
                                </Accordion>
                                <div className="choose-installation-date">
                                    <GetYourQuoteBtn action={() => { handleInstallation() }} text="Choose an installation date" bgColor="skyblue" color="white" icon="calendar" />
                                </div>
                            </div>
                            <div className="col-md-4 boiler-detail-right">
                                <h3>Your package...</h3>
                                <div className="white-card row">
                                    {
                                        grant.package_details.map(detail =>
                                            <div className="row">
                                                <div className="col-md-3">
                                                    {
                                                        (detail.icon) ? <img src={"./grants/" + detail.icon} alt="" /> : ""
                                                    }
                                                </div>
                                                <div className="col-md-7">{detail.text}</div>
                                                <div className="col-md-2">
                                                    {(detail.value == "base_price") ?
                                                        <span>{(grant.currency == "GBP") ? "£" : grant.currency}{(grant.base_price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> :
                                                        detail.value
                                                    }
                                                </div>
                                            </div>
                                        )
                                    }
                                </div>
                                <div className="row black-card">
                                    <div className="col-md-6">Total</div>
                                    <div className="col-md-6">{(grant.currency == "GBP") ? "£" : grant.currency}{(grant.total_price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} <small>(inc VAT)</small></div>
                                </div>
                            </div>
                            <div className="safe-installtion flexbox best-fit-bottom">
                                <Safeinstalltion />
                            </div>

                            <Modal show={showEmailQuote}>
                                <Modal.Header closeButton onClick={closeEmailQuote}>
                                    <Modal.Title>Email your quote</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <p>Enter your email address and mobile phone number in the boxes below so we can send this quote to you.</p>
                                    <form>
                                        <div className="form-group">
                                            <label className="control-label black-label"> Email <span className="color-skyblue">*</span></label>
                                            <input type="text" placeholder="youremail@mail.com" ref={EmailQuoteEmail} />
                                            {
                                                FormErrors['QuoteEmail'] ? (
                                                    <span className="error">{FormErrors.QuoteEmail}</span>
                                                ) : ''
                                            }
                                        </div>

                                        <div className="form-group mt-4">
                                            <label className="control-label black-label"> Phone number <span className="color-skyblue">*</span></label>
                                            <input type="text" placeholder="e.g. 07790225642" ref={EmailQuotePhone} />
                                            {
                                                FormErrors['QuotePhone'] ? (
                                                    <span className="error">{FormErrors.QuotePhone}</span>
                                                ) : ''
                                            }
                                        </div>

                                        <div className="form-group mt-4">
                                            <label className="box my_subscribe">
                                            I am happy to receive a fixed price quote from CES Boiler Shop
                                                <input type="checkbox" onChange={handleSunscribe} />
                                                <span className="mark"></span>
                                            </label>
                                        </div>
                                    </form>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={closeEmailQuote}>
                                        Close
                                    </Button>
                                    <Button className="bgColor-skyblue" variant="primary" onClick={submitEmailQuote}>
                                        Email Quote
                                    </Button>
                                </Modal.Footer>
                            </Modal>
                            {
                                (isSubmitted) ? (
                                    <div className="progressOverley">
                                        {/* <Spinner animation="grow" variant="primary" /> */}
                                        <CustomSpinnerComponent />
                                    </div>
                                ) : ''
                            }
                        </div>
                    </div>
                </>) : (
                    <>
                        {
                            (paymentSuccess == true) ? (<>
                                <div className="boiler-card first-boiler boiler-detail">
                                    <div className="row justify-content-center">
                                        <div className="col-11">
                                            <h1 className="success_heading"><span className="color-skyblue">Cool!</span> Your install is booked in for { moment(AllReq.installation_date).format('Do MMMM') }</h1>
                                        </div>
                                    </div>
                                    <div className="row checkout-page">
                                        <div className="col-md-8">
                                            <div className="order_heading">Order ref: <strong>#CES-{orderRefID}</strong></div>
                                            <Accordion ref={AccordionSection} defaultActiveKey="0">
                                                <Accordion.Item eventKey="0" className="your_order_details" >
                                                    <Accordion.Header>Your details</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="row your_order_details_text">
                                                            <div className="col-md-12">
                                                                <p>See below confirmation of your order details. If any information is incorrect, don’t hesitate to contact us at: <a href="mailto:info@ces-limited.com">info@ces-limited.com</a> to let us know.</p>
                                                            </div>
                                                            <div className="col-md-12">
                                                                {/* <div className="row">
                                                                    <div className="col-md-2 col-sm-3 col-xs-4">
                                                                        <img src={greenRightTick} alt="" />
                                                                    </div>
                                                                    <div className="col-md-10 col-sm-9 col-xs-8">
                                                                        <p><strong>Mr. Anthony Other</strong></p>
                                                                        <p><span>Tel: 07734 123456</span></p>
                                                                        <p><span>Email: first.last@name.com</span></p>
                                                                    </div>
                                                                </div> */}
                                                                <div className="points-list flexbox">
                                                                    <img src={greenRightTick} alt="" />
                                                                    <span>
                                                                        <p><strong>{AllReq.customer_details.title} {AllReq.customer_details.first_name} {AllReq.customer_details.last_name}</strong></p>
                                                                        <p><span>Tel: {AllReq.customer_details.phone_number}</span></p>
                                                                        <p><span>Email: {AllReq.customer_details.email}</span></p>
                                                                    </span>
                                                                </div>

                                                                <div className="points-list flexbox">
                                                                    <img src={greenRightTick} alt="" />
                                                                    <span>
                                                                        <p><strong>Install address</strong></p>
                                                                        <p>{AllReq.installation_details.address_line_1}</p>
                                                                        <p>{AllReq.installation_details.post_code}</p>
                                                                    </span>
                                                                </div>
                                                                <div className="points-list flexbox">
                                                                    <img src={greenRightTick} alt="" />
                                                                    <span>
                                                                        <p><strong>Billing address</strong></p>
                                                                        <p>{AllReq.installation_details.address_line_1}</p>
                                                                        <p>{AllReq.installation_details.post_code}</p>
                                                                    </span>
                                                                </div>
                                                                {/* <div className="row">
                                                                    <div className="col-md-2 col-sm-3">
                                                                        <img src={greenRightTick} alt="" />
                                                                    </div>
                                                                    <div className="col-md-10 col-sm-9">
                                                                        <p><strong>Install address</strong></p>
                                                                        <p>1 Two Three Street</p>
                                                                        <p>Any town AB1 2CD</p>
                                                                    </div>
                                                                </div> */}
                                                                {/* <div className="row">
                                                                    <div className="col-md-2 col-sm-3">
                                                                        <img src={greenRightTick} alt="" />
                                                                    </div>
                                                                    <div className="col-md-10 col-sm-9">
                                                                        <p><strong>Billing address</strong></p>
                                                                        <p>1 Two Three Street</p>
                                                                        <p>Any town AB1 2CD</p>
                                                                    </div>
                                                                </div> */}
                                                            </div>
                                                            <div className="col-md-12">
                                                                <div className="happen_box">
                                                                    <div className="happen_heading">What happens next?</div>
                                                                    <p>Sit back, relax and wait for your install. Your heating engineer will arrive at your property between 8-10 a.m. on the day you’ve selected with everything they need. We’ll be in contact with you closer to your date to confirm any further details. </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                            <Accordion defaultActiveKey="0">
                                                <Accordion.Item className="say_cheese" eventKey="0">
                                                    <Accordion.Header>Say cheese!</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="row your_order_details_text">
                                                            <div className="col-md-6">
                                                                <p>
                                                                To make sure your install goes as smooth as possible we’ll need to ask if you could please take a few photographs of your current boiler and email them to <a href="mailto:info@ces-limited.com">info@ces-limited.com</a></p>
                                                                
                                                                <p>Whether you take them on your mobile phone or digital camera, include a photograph of your boiler from the front and underneath, your gas metre and your flue where it exits your property. </p>
                                                                
                                                                <p>This ensures we don’t get any nasty surprises when we arrive delaying which could delay your install.
                                                                </p>
                                                            </div>
                                                            <div className="col-md-6">
                                                                <img className="s_hand_image" src={SuccessHandImage} />
                                                            </div>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            </Accordion>
                                            <div className="row smiley_face">
                                                <div className="col-4">
                                                    <img src={smiley_face} />
                                                </div>
                                                <div className="col-8">
                                                    <p>So, as we’ve said, sit back, </p>
                                                    <p>relax and we’ll do the rest!</p>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-md-4 boiler-detail-right">
                                            <h3>Your package...</h3>
                                            <div className="white-card row">
                                                {
                                                    grant.package_details.map(detail =>
                                                        <div className="row">
                                                            <div className="col-md-3">
                                                                {
                                                                    (detail.icon) ? <img src={"./grants/" + detail.icon} alt="" /> : ""
                                                                }
                                                            </div>
                                                            <div className="col-md-7">{detail.text}</div>
                                                            <div className="col-md-2">
                                                                {(detail.value == "base_price") ?
                                                                    <span>{(grant.currency == "GBP") ? "£" : grant.currency}{(grant.base_price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> :
                                                                    detail.value
                                                                }
                                                            </div>
                                                        </div>
                                                    )
                                                }
                                            </div>
                                            <div className="row black-card">
                                                <div className="col-md-6">Total</div>
                                                <div className="col-md-6">{(grant.currency == "GBP") ? "£" : grant.currency}{(grant.total_price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} <small>(inc VAT)</small></div>
                                            </div>
                                        </div>
                                        <div className="safe-installtion flexbox best-fit-bottom">
                                            <Safeinstalltion />
                                        </div>
                                        
                                    </div>
                                </div>
                            </>) : (
                                <>
                                    <h1>When do you want it?</h1>
                                    <div className="boiler-card first-boiler boiler-detail">
                                        <div className="row checkout-page">
                                            <div className="col-md-8">
                                                <div className="row pl-2 pr-2">
                                                    <div ref={ChooseDate}/>
                                                    <div className="col-12 white-date-box">
                                                        <h3 className="mb-4"><img src={dateIcon} className="date-icon-image pr-5" />Choose your <span className="color-skyblue">installation date</span></h3>

                                                        <Calendar
                                                            minDate={new Date()}
                                                            // showNeighboringMonth={false}
                                                            tileClassName={({ date, view, activeStartDate }) => {

                                                                if (!moment(date).isBetween(moment(activeStartDate).startOf('month'), moment(activeStartDate).endOf('month'), undefined, '[')) {
                                                                    return 'hide_date'
                                                                } else {
                                                                    var ret = 'myvisible_date'
                                                                    if (moment(date).isBefore(moment().subtract(1, 'days'))) {
                                                                        // return 'before_date'
                                                                        ret += ' before_date'
                                                                    }

                                                                    if (mark.find(x => x === moment(date).format("DD-MM-YYYY"))) {
                                                                        ret += ' booked_date disabled_date'
                                                                        // return 'booked_date disabled_date'
                                                                    }

                                                                    if (availabel.find(x => x === moment(date).format("DD-MM-YYYY"))) {
                                                                        ret += ' my_available_date'
                                                                        // return 'my_available_date'
                                                                    }

                                                                    return ret
                                                                }
                                                            }}
                                                            value={installationDate}
                                                            onChange={handleInstallationDate}
                                                        />
                                                        <div className="mt-4 text-center">
                                                            <GetYourQuoteBtn text="Confirm your date" action={handleConfrimDate} bgColor="green" color="white" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <Accordion ref={AccordionSection}>
                                                    <Accordion.Item eventKey="0" className="your_installation" >
                                                        <Accordion.Header>Your installation</Accordion.Header>
                                                        <Accordion.Body>
                                                            <div className="row">
                                                                <div className="col-md-6">
                                                                    <img src="./grants/eng.png" />
                                                                </div>
                                                                <div className="col-md-6 acc-content-text eng_text">
                                                                    <p>Your engineer will arrive between <span className="color-skyblue"> 8.00am and 11.00am</span> on the day on your chosen installation date. </p>

                                                                    <p>They will bring your new boiler and everything that they need to get started with your new heating system. </p>

                                                                    <p>All of our installers are experienced <span className="color-skyblue"> Gas Safe registered engineers</span> that have been rigorously vetted by the CES registration process.</p>
                                                                </div>
                                                            </div>
                                                            <div className="mt-2 text-center installation-date mb-5">
                                                                <GetYourQuoteBtn text="Continue" bgColor="skyblue" color="white" action={handleYourInstallation} />
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item className="your_details" eventKey="1">
                                                        <Accordion.Header>Your Details</Accordion.Header>
                                                        <Accordion.Body>
                                                            <p>This is so we can ensure a smooth process of your order.</p>
                                                            <form >
                                                                <div className="row">
                                                                    <div className="col-md-2">
                                                                        <div className="form-group">
                                                                            <label className="control-label black-label">Title <span className="color-skyblue">*</span></label>
                                                                            <select className="dropdown prename-options" ref={DetailTitle}>
                                                                                <option value="MR">Mr.</option>
                                                                                <option value="MISS">Miss.</option>
                                                                                <option value="MRS">Mrs.</option>
                                                                                <option value="MS">Ms.</option>
                                                                            </select>
                                                                            {
                                                                                FormErrors['title'] ? (
                                                                                    <span className="error">{FormErrors.title}</span>
                                                                                ) : ''
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-5">
                                                                        <div className="form-group">
                                                                            <label className="control-label black-label">First name <span className="color-skyblue">*</span></label>
                                                                            <input type="text" placeholder="e.g Jack" ref={DetailFname} onChange={handleInputChange} />
                                                                            {
                                                                                FormErrors['fname'] ? (
                                                                                    <span className="error">{FormErrors.fname}</span>
                                                                                ) : ''
                                                                            }

                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-5">
                                                                        <div className="form-group">
                                                                            <label className="control-label black-label">Last name <span className="color-skyblue">*</span></label>
                                                                            <input type="text" placeholder="e.g Baxter" ref={DetailLname} />
                                                                            {
                                                                                FormErrors['lname'] ? (
                                                                                    <span className="error">{FormErrors.lname}</span>
                                                                                ) : ''
                                                                            }
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                                <div className="row pt-3 installation-date">

                                                                    <div className="col-md-6 installation-date-col">
                                                                        <div className="form-group">
                                                                            <label className="control-label black-label"> Email <span className="color-skyblue">*</span></label>
                                                                            <input type="text" placeholder="youremail@mail.com" ref={DetailEmail} />
                                                                            {
                                                                                FormErrors['email'] ? (
                                                                                    <span className="error">{FormErrors.email}</span>
                                                                                ) : ''
                                                                            }
                                                                        </div>
                                                                    </div>

                                                                    <div className="col-md-6 installation-date-col">
                                                                        <div className="form-group">
                                                                            <label className="control-label black-label">Confirm email <span className="color-skyblue">*</span></label>
                                                                            <input type="text" placeholder="youremail.mail.com" ref={DetailCEmail} />
                                                                        </div>
                                                                        {
                                                                            FormErrors['cemail'] ? (
                                                                                <span className="error">{FormErrors.cemail}</span>
                                                                            ) : ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                                <div className="row pt-3 installation-date">

                                                                    <div className="col-md-6 installation-date-col">
                                                                        <div className="form-group">
                                                                            <label className="control-label black-label"> Phone number <span className="color-skyblue">*</span></label>
                                                                            <input type="text" placeholder="e.g. 07790225642" ref={DetailPhoneNumber} />
                                                                        </div>
                                                                        {
                                                                            FormErrors['phone'] ? (
                                                                                <span className="error">{FormErrors.phone}</span>
                                                                            ) : ''
                                                                        }
                                                                    </div>
                                                                </div>
                                                            </form>
                                                            <div className="mt-2 text-center installation-date mb-5">
                                                                <GetYourQuoteBtn action={() => { handleDetailfrm() }} text="Continue" bgColor="skyblue" color="white" />
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item className="installation_address" eventKey="2">
                                                        <Accordion.Header>Installation address</Accordion.Header>
                                                        <Accordion.Body>
                                                            <p>Where are we delivering and installing your CES package</p>
                                                            <form>
                                                                <div className="row installation-date">

                                                                    <div className="col-md-12 installation-date-col">

                                                                        <div className="form-group">
                                                                            <label className="control-label black-label"> Search your address <span className="color-skyblue">*</span></label>
                                                                            <input type="text" placeholder="Enter the postcode" ref={DetailPostcode} defaultValue={postcode} onChange={handleAddressChange} />
                                                                            {
                                                                                FormErrors['postcode'] ? (
                                                                                    <span className="error">{FormErrors.postcode}</span>
                                                                                ) : ''
                                                                            }
                                                                        </div>

                                                                        <div className="form-group mt-3">
                                                                            <label className="control-label black-label">Select your address <span className="color-skyblue">*</span></label>
                                                                            <select className="dropdown" ref={DetailAddress} >
                                                                                {
                                                                                    addressList.map((address, index) =>
                                                                                        <option value={address}>{address}</option>
                                                                                    )
                                                                                }
                                                                            </select>
                                                                            {
                                                                                FormErrors['address'] ? (
                                                                                    <span className="error">{FormErrors.address}</span>
                                                                                ) : ''
                                                                            }
                                                                        </div>

                                                                        <div className="row pt-3 installation-date">
                                                                            <a className="color-skyblue full-address-link" onClick={handleManuallyAddress}>Having trouble? enter your address manually</a>
                                                                        </div>

                                                                        {
                                                                            (isManuallyAddress) ? (
                                                                                <div className="form-group">
                                                                                    <label className="control-label black-label">&nbsp;</label>
                                                                                    <input type="text" placeholder="Enter your address" ref={DetailAddress1} />
                                                                                    {
                                                                                        FormErrors['address1'] ? (
                                                                                            <span className="error">{FormErrors.address1}</span>
                                                                                        ) : ''
                                                                                    }
                                                                                </div>
                                                                            ) : ''
                                                                        }

                                                                    </div>

                                                                </div>

                                                            </form>
                                                            <div className="mt-2 text-center installation-date mb-5">
                                                                <GetYourQuoteBtn text="Continue" bgColor="skyblue" color="white" action={() => { handleAddressfrm() }} />
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item className="payment_details" eventKey="3">
                                                        <Accordion.Header>Payment details</Accordion.Header>
                                                        <Accordion.Body>

                                                            {/* <div className="row installation-date">
    
                                                        <div className="col-12">
                                                            <label className="control-label"> Got a discount code?</label>
                                                        </div>
                                                        <div className="col-8 installation-date-col">
                                                            <div className="form-group">
                                                                <input type="text" placeholder="" ref={DetailDiscountCode}/>
                                                                {
                                                                    FormErrors['discount_code'] ? (
                                                                        <span className="error">{FormErrors.discount_code}</span>
                                                                    ) : ''
                                                                }
                                                            </div>
                                                        </div>
                                                        <div className="col-4 installation-date-col apply_btn">
                                                            <div className="form-group">
                                                                <input className="apply-btn" type="button" value="Apply" onClick={() => { handleDiscount() }} />
                                                            </div>
                                                        </div>
    
                                                    </div> */}
                                                            <div className="row installation-date">
                                                                <p>Choose whether you want to pay monthly on finance or pay in full with debit or credit card.</p>
                                                            </div>

                                                            <div className="row pt-3">
                                                                <div className="col-md-6">
                                                                    <div className={(PymentMethod === 'card') ? ' payment_tab active' : 'payment_tab'} onClick={() => { handlePaymentMethod('card', grant.total_price) }}>
                                                                        <p>Pay by debit/credit card</p>
                                                                        <p className="price">{(grant.currency == "GBP") ? "£" : grant.currency}{(grant.total_price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}<span> (inc VAT)</span></p>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6 installation-date-col " onClick={() => { handlePaymentMethod('monthly', grant.total_price) }}>
                                                                    <div className={(PymentMethod === 'monthly') ? ' payment_tab active' : 'payment_tab'}>
                                                                        <p>Pay monthly on finance</p>
                                                                        <p className="price">{(grant.currency == "GBP") ? "£" : grant.currency}{(monthly).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}<span> per month</span></p>
                                                                    </div>
                                                                </div>
                                                            </div>


                                                            <div className={'row pt-3 ' + ((PymentMethod != 'monthly') ? 'InVisible' : '')} >
                                                                <div className="col-md-12 border-tab pt-2">
                                                                    <h3 className="color-skyblue">Pay Monthly</h3>
                                                                    <span>Please check that you have selected the correct Finance Plan with Payment Term before proceeding.</span>
                                                                    <form className="pt-3 pyment-form">

                                                                        <div className="row">
                                                                            <div className="col-md-6 installation-date-col">
                                                                                <div className="form-group">
                                                                                    <label className="control-label color-skyblue">Finance Plan</label>
                                                                                    <select className="dropdown" onChange ={e => calculationForAll(e, grant.total_price, "apr")}>
                                                                                    {
                                                                                        (grant.interest_free)?(
                                                                                            <>
                                                                                                <option value="9.9" selected>9.9% APR</option>
                                                                                                <option value="0.0">0.0% APR</option>
                                                                                            </>
                                                                                        ):(
                                                                                            <><option value="9.9" selected>9.9% APR</option></>)
                                                                                    }
                                                                                    </select>
                                                                                </div>
                                                                            </div>

                                                                            <div className="col-md-6 installation-date-col">
                                                                                <div className="form-group">
                                                                                    <label className="control-label color-skyblue">Choose Payment Term</label>
                                                                                    <select className="dropdown" onChange ={e => calculationForAll(e, grant.total_price, "term")}>
                                                                                    {
                                                                                        grant.interest_free?(
                                                                                            (apr === 0.0)?(
                                                                                                <>
                                                                                                    <option value="12">1 Years (12 Months)</option>
                                                                                                    <option value="24" selected>2 Years (24 Months)</option>
                                                                                                </>
                                                                                            ):(
                                                                                                <>
                                                                                                    {/* <option>3 Years (36 Months)</option>
                                                                                                    <option>4 Years (48 Months)</option>
                                                                                                    <option>5 Years (60 Months)</option> */}
                                                                                                    <option value="36">3 Years (36 Months)</option>
                                                                                                    <option value="48">4 Years (48 Months)</option>
                                                                                                    <option value="60">5 Years (60 Months)</option>
                                                                                                    <option value="84">7 Years (84 Months)</option>
                                                                                                    <option value="120" selected>10 Years (120 Months)</option>
                                                                                                </>
                                                                                            )  
                                                                                        ):(
                                                                                            <>
                                                                                                <option value="36">3 Years (36 Months)</option>
                                                                                                <option value="48">4 Years (48 Months)</option>
                                                                                                <option value="60">5 Years (60 Months)</option>
                                                                                                <option value="84">7 Years (84 Months)</option>
                                                                                                <option value="120" selected>10 Years (120 Months)</option>
                                                                                            </>
                                                                                        )
                                                                                        
                                                                                    }
                                                                                    </select>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div className="form-group pt-3">
                                                                            <label className="control-label color-skyblue"><h3><strong className="chooseDepo">Choose Deposit {`£${(Math.floor((depositPercent/100)*grant.total_price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","))}`}</strong> ({depositPercent}%) </h3></label>
                                                                            <input className="range-slider" type="range" min="0" max="50" step="1" value={depositPercent} onChange={(e) => calculationForAll(e, grant.total_price, "range")}/>
                                                                        </div>
                                                                        <div className="form-group info">
                                                                            <p><b>Monthly Payment: </b> <span className="text-right">{String(monthly).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></p>
                                                                            <p><b>Interest: </b> <span className="text-right">{String(interest).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></p>
                                                                            <p><b>Loan Amount:</b> <span className="text-right">{String(loan).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></p>
                                                                            <p><b>Order Total:</b> <span className="text-right">{String(orderTotal).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span></p>
                                                                        </div>
                                                                    </form>

                                                                </div>
                                                            </div>

                                                            <div className={'row pt-3 ' + ((PymentMethod != 'card') ? 'InVisible' : '')}  >
                                                                <div className="col-md-12 pt-2">

                                                                    <div className="row">
                                                                        <div className="col-md-12 installation-date-col">
                                                                            <div className="form-group">
                                                                                <label className="control-label ">Credit or debit card</label>
                                                                                <form id="my-sample-form">
                                                                                    <div className="custom-card-input maxH50">
                                                                                        <div className="image_card">
                                                                                            <img src="./grants/icons/credit-card.png" width="30" />
                                                                                        </div>
                                                                                        <div id="card-number" className="card_input" type="text" placeholder="Card number" ref={CardNumberInput}> </div>
                                                                                        <div id="expiration-date" pattern="\d{1,2}/\d{1,2}/\d{4}" className="exp_input" type="text" placeholder="MM/YY" ref={ExpiryDateInput} > </div>
                                                                                        <div id="cvv" className="cvc_input" type="text" placeholder="CVC" ref={CVVInput} />
                                                                                    </div>
                                                                                    <button value="submit" id="submit" class="btn InVisible">Pay with Card</button>
                                                                                </form>
                                                                                {
                                                                                    FormErrors['payment'] ? (
                                                                                        <span className="error">{FormErrors.payment}</span>
                                                                                    ) : ''
                                                                                }
                                                                            </div>
                                                                            <div id="paypal-button-container"></div>
                                                                        </div>

                                                                        {/* <PayPalScriptProvider options={
                                                                            {
                                                                                "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
                                                                                "data-client-token": clientToken,
                                                                            }}>
                                                                            <PayPalButtons style={{ layout: "horizontal" }} />
                                                                        </PayPalScriptProvider> */}
                                                                    </div>

                                                                </div>
                                                            </div>

                                                            <div className="accept-tc pt-5">
                                                                <div className="form-group">
                                                                    <label className="box">I agree to the <a href="/terms-and-conditions" target="_blank" className="color-skyblue">CES terms and conditions</a>
                                                                        <input type="checkbox" onChange={handleTC} defaultChecked={acceptTC} />
                                                                        <span className="mark"></span>
                                                                    </label>
                                                                    {
                                                                        FormErrors['acceptTC'] ? (
                                                                            <span className="error">{FormErrors.acceptTC}</span>
                                                                        ) : ''
                                                                    }
                                                                </div>
                                                            </div>
                                                            <div className="choose-installation-date payment-confrim-btn mb-5">
                                                                <GetYourQuoteBtn action={() => { handleSubmission() }} text={(PymentMethod === 'card') ? 'Confirm payment' : 'Start application'} classes="text-center" bgColor="skyblue" color="white" />
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>

                                                {
                                                    (isSubmitted) ? (
                                                        <div className="progressOverley">
                                                            {/* <Spinner animation="grow" variant="primary" /> 
                                                            */}
                                                            <CustomSpinnerComponent />
                                                        </div>
                                                    ) : ''
                                                }

                                            </div>
                                            <div className="col-md-4 boiler-detail-right">
                                                <h3>Your package...</h3>
                                                <div className="white-card row">
                                                    {
                                                        grant.package_details.map(detail =>
                                                            <div className="row">
                                                                <div className="col-md-3">
                                                                    {
                                                                        (detail.icon) ? <img src={"./grants/" + detail.icon} alt="" /> : ""
                                                                    }
                                                                </div>
                                                                <div className="col-md-7">{detail.text}</div>
                                                                <div className="col-md-2">
                                                                    {(detail.value == "base_price") ?
                                                                        <span>{(grant.currency == "GBP") ? "£" : grant.currency}{(grant.base_price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span> :
                                                                        detail.value
                                                                    }
                                                                </div>
                                                            </div>
                                                        )
                                                    }
                                                </div>
                                                <div className="row black-card">
                                                    <div className="col-md-6">Total</div>
                                                    <div className="col-md-6">{(grant.currency == "GBP") ? "£" : grant.currency}{(grant.total_price).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} <small>(inc VAT)</small></div>
                                                </div>
                                            </div>
                                            <div className="safe-installtion flexbox best-fit-bottom">
                                                <Safeinstalltion />
                                            </div>

                                        </div>
                                    </div>
                                    <Footer boilerPerMonthFootnote="none"/>
                                </>
                            )
                        }
                    </>
                )
            }

        </>
    )
}
export default BoilerDetails;

