import "./termsandcondition.css"
import { useEffect } from "react";
import aos from 'aos'

const Termsandcondition = () => {

    // useEffect(()=>{
    //     aos.init({duration:2000})
    // })

    return (
        <>
            <section className="termsandcondition-section">
                <div className="row">
                    <div className="col-md-12"><h1>Terms and Conditions</h1></div>
                </div>
            </section>
            <section className="termsandcondition-second-section">
                <div className="row">
                    <div className="col-md-12 text">
                        <h2>1. Trademarks and intellectual property</h2>
                        <p>Consumer Energy Solutions Ltd is registered In England and Wales with company number 09959339.</p>
                        <p>Consumer Energy Solutions Ltd is registered with the Information Commissioners Office (ICO), number ZA120451, and treats all information supplied by customers as confidential. Information passed on will only be done so to fulfil the obligations set out within these terms and conditions.</p>
                        <p>Consumer Energy Solutions Ltd is registered with the Financial Conduct Authority (FRN 930823) and may introduce you to third party finance providers if this is your preferred method of payment. Consumer Energy Solutions Ltd is a broker and not a lender.</p>
                        <p>Consumer Energy Solutions Ltd is registered, regulated and monitored by Gas Safe (registration number: 626397), Trustmark (registration number: 1726811) and HIES (registration number: CES/A/0508) among other regulatory bodies.</p>
                        <p>Trademarks and other intellectual property of the Company are protected and not to be replicated or reproduced.</p>
                        <p>Photographs and imagery of boilers and components are subject to copyright, with all rights reserved. The use of any photographs or imagery featured on this website or the company’s promotional assets without the written permission of Consumer Energy Solutions is strictly prohibited. Any use of copyrighted imagery for commercial or personal purposes will be charged for, plus legal costs of the copyright owner and financial compensation for copyright infringement relating to commercial gains of the infringer or losses to Consumer Energy Solutions Ltd. To check the copyright status of imagery and photographs, please email info@CES-limited.com</p>

                        <h2>2. Obtaining a quote and the ordering process</h2>
                        <p>The online quote tool provides customers with a fixed, online price for a boiler installation based on the information they enter about their property and current heating system. Customers will be presented with a range of products from which they can select a replacement boiler. These are offered as part of standard itemised packages and include installation by Gas Safe engineers. Customers have the option to proceed with a standard package or purchase additional products or accessories as part of a bundle.</p>
                        <p>If the customer wishes to proceed with the quotation provided, they can select the requested installation date via the booking calendar. Once an installation date is chosen, the customer must then enter their contact and payment information. Full payment is secured before installation, whether that be through credit/debit card, PayPal or an approved and accepted finance agreement. After this point, the order will be passed to the scheduling team and confirmations via email and/or telephone from the Consumer Energy Solutions team will follow.</p>
                        <p>The quotations we provide are only valid on condition the data entered into the quote tool is honest and accurate.</p>
                        <p>If  any information provided by the customer is found to be false or inaccurate, the installation will be halted and a member of our team will contact the customer to provide a another quotation based on the correct information. If you choose to proceed with this quotation and make appropriate provisions for payment, we’ll work with you to schedule a date for your installation. Please note it is not always possible for this to be on the same day as the original installation date. If you decide not to proceed, we’ll cancel your order and issue a full refund.</p>
                        <p>Consumer Energy Solutions reserves the right to refuse or cancel any order.</p>

                        <h2>3. Selecting an appropriate product</h2>
                        <p>Consumer Energy Solutions will make recommendations for boilers and accessories based on the customer’s responses. It is the customer’s sole responsibility to ensure the boiler and accessories they select are suitable in terms of dimensions, power and performance. All the relevant information is listed for each boiler and accessory during the checkout process.</p>
                        
                        <h2>4. Self-survey photographs</h2>
                        <p>After placing an order, the customer will be asked to send photographs of their existing boiler, flue and other listed items and parts via email. These can be emailed to info@ces-limited.com with the order number as reference. If you are unable to provide photographs, please contact us by phone or email ((include the details)).</p>
                        <p>We need the photographs no later than 24 hours before installation and ideally within four hours of placing an order should an installation be booked for the next day. Failure to provide the required photographs may result in the delay or cancellation or your order.</p>
                        <p>It is the customer’s sole responsibility to not upload irrelevant or offensive imagery. Such action may result in legal prosecution.</p>

                        <h2>5. General terms</h2>
                        <p>Once you place your order via the online checkout or by telephone and it is accepted by us, a ‘contract’ between you and us is formed and that contract will be subject to these terms and conditions.</p>
                        <p>By placing an order, you are confirming you are the owner of the property detailed in the quotation (or the email confirmation) or that permission from the owner has been granted.</p>
                        <p>The quotation and ordering processes provided are solely for your personal use. To place an order via the website you must be at least eighteen (18) years old. You may not use the website for any commercial purpose. We reserve the right to make changes to any part of these Terms and Conditions from time to time, so please ensure you check the latest version. We may modify or withdraw the quoting and ordering platforms (or any part of them) temporarily or permanently and we shall not be liable to you or any third party for any modification to, or withdrawal of, the platforms. You must agree to use the platforms on this basis.</p>
                        <p>Any reviews or comments made by members of the public on this website are their opinions only and do not form any part of the specification or advice given by Consumer Energy Solutions about products or services we supply.</p>
                        <p>Any concession, latitude or waiver allowed by Consumer Energy Solutions at any time shall be without prejudice to their strict and full rights under this contract and shall not prevent Consumer Energy Solutions subsequently exercising such rights.</p>
                        <p>This contract is governed by the laws of England and Wales. If there is any dispute between us, it will be dealt with by the courts of England and Wales if your property is England or Wales and by the courts of Scotland if your property is in Scotland.</p>
                        <p>Nothing in this contract affects our legal rights or powers. Nothing in this contract affects any of your statutory rights that cannot be excluded by law.</p>
                    
                        <h2>6. Communication with customers</h2>
                        <p>Throughout the process of booking and having your new boiler installed, Consumer Energy Solutions will communicate with you via telephone, email and text message. There may be instances when we will contact you with unforeseen important information, but our process of communications will largely be as below:</p>
                        <ul className="tc_page_list">
                            <li>Confirmation phone call and text message upon placing order</li>
                            <li>Email order confirmation</li>
                            <li>Photo upload request via email and/or text messages</li>
                            <li>Pre-installation reminder – one day before planned installation date via phone/text message</li>
                            <li>Courtesy phone call/text message after installation</li>
                            <li>Review request link via email</li>
                            <li>Follow-up documentation via email; relevant boiler registrations</li>
                            <li>Service reminders/offers via email or phone call</li>
                            <li>Consumer Energy Solutions will use your contact and address information should our installation engineers need to make contact for any reasons including but not limited to: arrival time, access or technical queries. This information will no longer be accessible to our engineers on completion of your installation.</li>
                        </ul>

                        <h2>7. Respect towards Consumer Energy Solutions employees</h2>
                        <p>We’re sympathetic to the nature of boiler installations and how it can be a stressful time, particularly in winter. However, we will categorically not accept abusive or threatening behaviour towards our staff through any contact channels. Should any member of our team be treated in this manner, Consumer Energy Solutions will cancel your order with immediate effect and charge any relevant cancellation fees. Outside of the cancellation fee, a full refund and/or cancellation of any finance agreement will be provided by Consumer Energy Solutions. Any serious instances of abuse or threats against our team will be reported to the relevant authorities.</p>
                    
                        <h2>8. Payment methods</h2>
                        <p>Consumer Energy Solutions proudly offer a fixed pricing system, details and stipulations of which have been described earlier in our Terms and Conditions. Options and details of our payment methods are as follows.</p>
                        <p>Once an installation date has been chosen, it is reserved for one hour. To confirm the installation date, the payment process (including the completion of any finance documents) needs to be finished within this one-hour period. If the customer does not complete the payment process within an hour, the Company will endeavour to install/deliver equipment on the chosen date, but we reserve the right to offer an alternative delivery/installation slot.</p>
                        <h3>Credit/debit card</h3>
                        <p>Consumer Energy Solutions accept all major credit/debit cards, including American Express. If you choose this as your preferred payment method, we will process the payment in full upon checking out. As per our fixed price model, no further amounts will be charged to your card unless requested and agreed and all details will be stored under the terms of GDPR. All card transactions are processed securely through our payment merchant, Stripe.</p>
                        <h3>PayPal</h3>
                        <p>Customers will be required to login securely to their PayPal account at the point of checkout. The transaction from this point will be processed under standard PayPal terms. As with credit/debit card transactions, the full amount will be charged upon placement of your order.</p>
                        <h3>Finance</h3>
                        <p>Consumer Energy Solutions Ltd acts as a credit broker and not as a lender. Vendigo Finance UK Ltd acts as Consumer Energy Solutions’ finance lender. All finance applications are processed through, and decided by, Vendigo Finance UK Ltd.</p>
                    
                        <h2>9. Finance application process</h2>
                        <p>During an online transaction, the customer will have the option to choose finance as their preferred payment method. On choosing this option, the customer will be directed to a digital finance application through our lender, Vendigo Finance UK Ltd. If you decide to process your order with one of our team over the phone, the finance application can either be completed during the call or you can request the application is sent to you via email.</p>
                    
                        <h2>10. Important information about finance applications</h2>
                        <p>Consumer Energy Solutions has no authority or insight into the finance applications decision-making process.</p>
                        <p>Deposits, if chosen, must be paid before installation. If the opportunity to pay a deposit was not presented or completed during the on-screen checkout process, one of the Customer Energy Solutions customer service team will need to take one from a credit/debit card by telephone.</p>
                        <p>Should your finance application be cancelled before your installation, your booking will be automatically cancelled and a rebooking will be required with the previous installation date not guaranteed.</p>
                        <p>Upon completion of an application paid for using a finance option, you may be asked by the lender to complete a ‘satisfaction notice’ – usually via e-signature. At this point, you will have the opportunity to raise any issues with your installation and halt payment. If, however, Consumer Energy Solutions disputes the reasons for a halted payment or the satisfaction notice request is delayed without a well-communicated reason, you will be issued with an invoice for the installation that will be pursed legally should payment not be received by the stated due date.</p>
                    
                        <h2>11. System flush and workmanship warranty</h2>
                        <h3>ProFlush</h3>
                        <p>As standard, we include a full MagnaCleanse flush of your system before installation to remove sludge and debris using a combination of bespoke chemicals, high-power magnets and agitation equipment. The flush will not clear blocked pipework or system blockages, this will require pipework replacement (not included). To complete the flush, the engineer will need free access to each radiator in the property. If access to all radiators isn’t made available, these radiators will be omitted from the flush process. No partial refunds are offered in these circumstances. Once the flush has been completed, the engineer may carry out a water test and issue a satisfactory certificate to the customer. The workmanship warranty offered for Pro Flush is 12 months from the date of completion.</p>
                   
                        <h2>12. Cancellations</h2>
                        <p>You have the right to cancel your installation booking at any time before it is due to take place. To do so, we kindly request you communicate this to Consumer Energy Solutions at the earliest opportunity by telephone on 01792 721162 or email at info@ces-limited.com.</p>
                   
                        <h2>14. Our obligation to you</h2>
                        <p>During an online transaction, the customer can choose finance as their preferred payment method. On choosing this option, you will be directed to a digital finance application through our lender, Vendigo Finance UK Ltd. Should you process your order with one of our team members over the phone, this application can either be completed during the call or you can request the application is sent to you by email.</p>
                        
                        <h2>15. Your obligation to us</h2>
                        <p>During the fulfilment of this contract, the customer agrees to provide a safe and respectful workplace for any persons attending the installation address. Persons attending a property to carry out work connected with this contract will leave if they encounter rude, abusive or unsafe conditions. If this happens, it will be at the sole discretion of Consumer Energy Solutions whether or not to rearrange installation and what to charge for such a rearrangement. In any event, no refund will be due from Consumer Energy Solutions to the customer if the site is vacated for reasons of safety, abuse or general rudeness.</p>
                   
                        <h2>16. Permits and permissions</h2>
                        <p>Before we attend, it is the customer's responsibility to secure any permissions, licences or permits that may be required to authorise the work, such as consent from landlords, building managers, leaseholders and covenants relating to listed buildings or areas of conservation.</p>
                        <p>It is also the customer’s responsibility to ensure there is adequate vehicle parking within 30 meters of the entrance to the property. Any charges for parking or permits are to be paid for and arranged by the customer or reimbursed to the Company.</p>
                        <p>The customer is also responsible for undertaking a party wall agreement with any neighbouring properties where required in advance of the Company attending site to carry out work.</p>
                   
                        <h2>17. Site inspection</h2>
                        <p>The customer (or their representative) must have complete control over the isolation of gas, water and electricity on the installation date. If safe isolation of all services is not possible, the installation may have to be postponed or cancelled and a refund issued. All costs and co-ordination to renew or repair isolation points are the customer’s responsibility.</p>
                        <p>The Company is only permitted to install gas appliances in domestic properties that are fitted with a domestic gas meter. If your property operates commercially or has a commercial gas meter installed, we are unable to install any appliances and your order will be cancelled and refunded.</p>
                        <p>If, upon physical inspection of the site, it is determined that specialist equipment or a significant amount of extra work is required, the Company will inform the customer of any increase in costs before they are incurred. If the customer declines any extra equipment, work required or costs to facilitate the installation work, they are entitled to a full refund.</p>
                   
                        <h2>18. The installation process</h2>
                        <p>Depending on availability, materials will either be delivered to the property by our suppliers or the appointed installer may bring them.</p>
                        <p>In some circumstances, materials will arrive on site before the installer. On installation day, the customer should be aware deliveries start from 7.30am. Materials must be kept in a safe, dry place.</p>
                        <p>The existing system will be drained where necessary and redundant materials will be carefully disconnected and removed from site. Dust sheets will be used to protect your furnishings.</p>
                        <p>Your old boiler will be decommissioned and removed. The new boiler will be installed on the wall, the pipes connected and the condensing trap installed to the nearest drain or soak away (the installer will discuss the final termination point with you on the day).</p>
                        <p>If your gas supply pipe needs upgrading to meet current standards, this will be completed as part of your fixed price quotation. The installer will discuss the routing of the gas pipe before starting work. If you do not agree to have the pipe upgraded, the order will be cancelled and a refund issued.</p>
                        <p>If applicable, the fanned flue terminal will be carefully cut through the outside wall and sealed. Every care will be taken to minimise the disturbance of brickwork. If necessary, a suitable flue guard will be fitted and protection to eaves or soffits will be included where applicable.</p>
                        <p>All wiring to the boiler, pump and controls (if applicable) will be carried out to current BS 7671 standards and surface installed.</p>
                        <p>The installer will test and adjust the boiler and controls and leave the system in good working order. They  will then ensure you are satisfied with the work and you understand the controls as well as carrying out the necessary safety checks.</p>
                        <p>The boiler will be commissioned and relevant paperwork completed. The installer will demonstrate to the customer (or their representative) how to operate the new boiler, controls and accessories (if applicable).</p>
                        <p>Any redundant materials, including but not limited to your old boiler, packaging, hot water cylinder, storage tank and pipework, will be removed as standard with every installation and disposed of or recycled in accordance with all relevant local authority and/or Government guidelines. If you wish to retain any of these materials, you must inform the Company at the point of order. We will not be liable to return or reimburse material value for such items after the installation has begun. No financial compensation will be issued for materials removed.</p>
                        <p>The engineer will take pictures of your installed equipment for the Company’s auditing process and for our own records. The engineer may also attend with other people, including managers, supervisors, trainees or apprentices. From time to time, the Company may use these images for marketing or promotional purposes.</p>
                        
                        <h2>19. General installation terms</h2>
                        <p>We have assumed your property is in a good state of repair and contains no structural defects or weaknesses. We will not be responsible for any damage caused to your property as a result of any existing defects.</p>
                        <p>While all reasonable care will be taken by the Company, it accepts no liability for any damage to existing plaster work, decorations, flooring, etc. that may occur upon the carrying out of the work detailed. Cuts or holes made to allow for equipment will be made good, but not permanently finished or re-decorated. Floorboards will be reinstated or replaced where necessary, but special and/or laminated floors cannot be permanently re-fixed. Any carpets lifted will be re-laid to the best of our operative's ability, however, we cannot be held responsible for carpets that have been nailed or glued down. Some redecoration may be required and this will be the customer's responsibility and is not included in the price.</p>
                        <p>We have also assumed the existing heating and hot water pipework (not visible for inspection) will be the right size and unaffected by blockages, incorrect falls or leaks. Access, repair and making good unsuitable pipework is the customer’s responsibility.</p>
                        <p>If the existing pipework has a level of sludge and or/scale that a chemical flush cannot remove, the Company will not be accountable for the new boiler’s performance.</p>
                        <p>In some instances, the location of your existing boiler’s 'flue exit hole' may not be suitable, so a new hole will have to be drilled and the old hole will be made good and sealed. The redecoration of the visible sides of this hole to its pre-existing state is the customer’s responsibility. No extra charges are applied for the installation of a new flue hole.</p>
                        <p>It may be necessary to remove decorative coverings such as tiles, boxing in or boiler cupboards to access pipework, flues or other components needed to complete the works. While all care and attention will be paid when removing said items, it is the customer’s sole responsibility to repair, modify or replace these coverings once the installation is complete.</p>
                        <p>The Company accepts no liability for any materials used during the installation process having variations in size, colour, texture and general appearance. When matching materials (such as bricks and tiles) to existing materials that are part of the house, a close match is often not possible due to older products becoming obsolete and the effects of weathering over time. The customer agrees to make available their own materials, at their expense, at the time of the work taking place if they would like a specific material (such as a matching brick) to be used.</p>
                        <p>Properties with specialist wall finishes such as render, timber, cladding, stone or specialist mortar products will be made temporarily weather tight and will not be sympathetic to the building’s fabric. Reinstatement of these specialist finishes are customer’s responsibility; no contribution will be made by the Company.</p>
                        <p>It is not possible for the Company to determine if your shower and your new boiler will be compatible. The Company will not be liable if your existing shower is not compatible with the new boiler for any reason. There are circumstances where compatible showers may still leak or not function correctly with your new boiler due to failed seals, increased pressure or worn cartridges. Shower repairs or replacements are the customer’s responsibility and are excluded from the contract in all circumstances.</p>
                        <p>If new pipework is needed to install your new boiler, we will discuss  the route for these pipes with you before work starts. Pipework will need to be installed in a way that makes the most sense from an engineering point of view, not simply aesthetic. Pipework may need to be surface mounted and clipped in place. We will not bury pipework in the walls or floors and boxing in pipework is the customer’s responsibility.</p>
                        <p>If your boiler is installed in a loft space or you’ve chosen to move it to your loft space as part of the contract, building control regulations stipulate the loft space has to be accessible by a fixed ladder. You will also need a fixed walkway from the loft entrance to your boiler with a minimum of 1m2 of additional fixed boarding beneath the boiler’s location. There must also be a working light. All costs and co-ordination to bring the loft space up to the relevant standard are the customer’s responsibility.</p>
                        <p>We will remove debris from site as part of the fixed price, but this does not include the removal of any dangerous/hazardous waste material such as asbestos. It is the customer’s responsibility to arrange for the safe removal and disposal from site at their own expense and to provide the Company with a Clean Air Certificate as proof this work has been completed. We may halt an installation if a dangerous or hazardous substance is identified during the works. The installation will only resume once they have been removed.</p>
                        <p>Sometimes products or raw materials may not be available as illustrated on the Company’s website. With the exception of the boiler itself, some products may be substituted for items of equivalent regulatory standards. These items are limited to chemicals, standard controllers/thermostats, scale reducers, pumps and valves.</p>
                        <p>While every effort is made to ensure the delivery and installation timescales agreed during the ordering process are met, the Company reserves the right to delay installation without customer compensation in some circumstances, for example material shortages, supplier delays, sickness, fires, strikes, illness, severe weather, terrorism, war and any other causes beyond the control of the Company interfering with its execution or completion of the contract. The Company is not liable for any loss of profit, loss of business, business interruption, loss of salary or holiday entitlement should delays occur.</p>
                        <p>Sometimes things do not go to plan when installing new equipment. The customer agrees to give the Company and its engineers reasonable opportunities to put things right by providing access to the installation address and time to remedy any faults or problems. The customer also undertakes to minimise or avoid any losses it may suffer as a result of the actions (or non-actions) of the Company or the appointed engineer. If remedial works are required, your original installing engineer will return to rectify the issues. In the case of a recurring fault, the Company reserves the right to send an alternative engineer from our network at our sole discretion.</p>
                        <p>If your boiler’s flue (horizontal or vertical) cannot be accessed with standard 7m ladders or if obstacles such as conservatories, extensions or ground obstructions prevent safe access, specialist access equipment such as fixed scaffolding or platforms may be required. The customer accepts the Company cannot jeopardise the safety of engineers and suitable access equipment may be required, for which the customer will pay directly for or will reimburse the Company. All costs will be discussed before work starts and the customer reserves the right to cancel and request a refund if they do not wish to pay for specialist access equipment. The customer should be aware that arranging and erecting specialist equipment may cause delays.</p>
                        <p>We have assumed your existing radiators, valves and external components are in serviceable condition. Replacement or renewal of existing external components are excluded from the contract.</p>
                        <p>We will make every effort to clean the workspace and leave the property in a satisfactory state. However, customers should be aware that, over a short period following installation, dust may settle on surfaces and additional cleaning may be required.</p>
                   
                        <h2>20. Combi boiler replacements</h2>
                        <p>A combination boiler system in some circumstances is likely to provide a lower hot water supply flow rate than a conventional system. This is exaggerated when two hot water supplies are used at the same time. If you have a shower at your property, the Company recommends you consult the manufacturer to confirm it will be compatible with a high-pressure system and will not leak.</p>
                        <p>When re-connecting existing or smart controls to new boilers, we have assumed the existing controller is in serviceable condition, can be re-connected to regulatory standards and is compatible with the new boiler. Any costs to replace or procure replacement parts for existing controllers are the customer’s responsibility.</p>
                   
                        <h2>21. Regular/system replacements</h2>
                        <p>We have assumed any external components not included in our fixed price are in a serviceable condition and meet current regulatory standards. All costs to renew or replace components such as hot water cylinders, header tanks, pumps, zone valves and controllers are the customer’s responsibility unless they are part of an optional upgrade package.</p>
                        <p>We are unable to convert regular boilers to system boilers.</p>
                        <p>In rare circumstances where your hot water is gravity fed or you have no independent control over your hot water and heating, it is not possible to install a modern boiler without upgrading several additional components to meet current building control standards. If you didn’t select to upgrade these components at the checkout stage and we identify they need upgrading, we will provide a cost for this or you are entitled to cancel your order and request a full refund.</p>
                   
                        <h2>22. Converting to a combi boiler</h2>
                        <p>When installing a combi boiler, part of the process includes sealing your central heating system, which involves removing the feed and expansion cistern and raising the water pressure above ‘atmospheric’. Occasionally, these higher working pressures can exacerbate any existing/past leaks or cause new leaks to appear. Where possible, we will access and repair any leaks on pipework during the installation process and we will isolate any leaking radiators. The Company are happy to re-attend on one further occasion for up to three hours free of charge to identify and rectify pipework. Making good décor, fixtures and fittings following a leak is the customer’s responsibility.</p>
                        <p>If you are converting to a combi boiler from a back boiler, the cost of relocating your boiler to a suitable area of your home is included in your fixed price. Our technical team and the engineer on the day will discuss the location of your new boiler. For technical reasons, it is not always possible to accommodate installing the boiler in a specific requested location. If we are unable to reach an agreement on the routing of pipework or location of the new boiler, you are entitled to cancel your order and request a full refund.</p>
                        <p>When converting from a back boiler to a combi boiler, the customer should be aware that the fire that covers the back boiler will no longer be functional. This can be removed and recycled at the customer’s request. Once the back boiler and fire are removed, there will be an exposed chimney cavity. Making good this cavity including décor is the customer’s responsibility.</p>
                        <p>Any water storage tanks in the loft that are decommissioned as part of the work will be left in place unless the engineer is requested to remove them while on site. We can only remove water storage tanks that will fit through the loft hatch without needing to be tilted. Water storage tanks can contain an accumulation of debris and sludge that may damage floor coverings or furnishings if tilted or disturbed when being removed.</p>
                        <p>If you’ve selected to reconnect your existing control to your new combi boiler, it  must be in working order and compatible with a combi boiler. Customers must be aware some controllers, including HIVE, are boiler type-specific and may need additional components to be fully compatible. Sourcing and paying for these components are the customer’s responsibility.</p>
                   
                        <h2>23. Converting to a combi boiler</h2>
                        <p>When installing a combi boiler, part of the process includes sealing your central heating system, which involves removing the feed and expansion cistern and raising the water pressure above ‘atmospheric’. Occasionally, these higher working pressures can exacerbate any existing/past leaks or cause new leaks to appear. Where possible, we will access and repair any leaks on pipework during the installation process and we will isolate any leaking radiators. The Company are happy to re-attend on one further occasion for up to three hours free of charge to identify and rectify pipework. Making good décor, fixtures and fittings following a leak is the customer’s responsibility.</p>
                        
                        <h2>24. Warranties, guarantees and compliance documentation</h2>
                        <p>Following the completed works, our administration department issues a notification to your local council building control and the Gas Safe Register. A confirmation will be sent to your home address.</p>
                        <p>We will issue a copy of the CP1 gas safety record with every installation. If you are a landlord and need a CP12 landlord certificate, you need to add this when ordering and it will be issued alongside your CP1 gas safety record. We cannot retrospectively issue a landlord’s CP12 certificate.</p>
                        <p>We will also register the boiler, controller and accessories directly with the manufacturer. Details or warranty durations will be specified on your order and outlined below. Some warranties (excluding the boiler) are parts only.</p>
                        <p>Once your boiler and relevant accessories have been installed, they are covered immediately by the manufacturer. In very rare occasions, new products may not function correctly when first installed. Manufacturers have strict quality control processes, but parts can become dislodged in transit causing a ‘zero hour failure’. In the event of a zero hour failure, the manufacturer will be required to attend the site and repair the product - we cannot replace it. We will liaise with the manufacturer to arrange the soonest possible date to attend and repair the product.</p>
                   
                        <h2>25. 12-month workmanship warranty</h2>
                        <p>As standard with every installation, we offer a full 12-month workmanship warranty. This covers any products, pipework or electrical equipment installed by the Company. In some instances, we may instruct the manufacturer of a faulty product to complete on-site repairs. The Company works proactively with customers and product manufacturers/suppliers in such circumstances to rectify the issue as quickly as possible. Sometimes faulty equipment needs refitting, which can cause significant delays and inconvenience. We will do all we reasonably can to minimise disruption to the household.</p>
                        <p>Items excluded from the workmanship warranty include:</p>
                        <ul className="tc_page_list">
                            <li>Products not installed by us</li>
                            <li>Products not supplied by us</li>
                            <li>Existing products or components fitted alongside the new boiler, for example, hot water tanks, zone valves, radiators, or pre-existing pipework</li>
                            <li>Cosmetic repair</li>
                            <li>Building fabric such as roof repairs, plaster, render, etc.</li>
                            <li>Drainage blockages or utility supply issues</li>
                            <li>Compensation for down time</li>
                            <li>Misuse of equipment, damage (accidental or deliberate)</li>
                            <li>Properties used for commercial purposes</li>
                            <li>Interference by third parties</li>
                            <li>If in the event the Customer requests a visit to investigate or repair an issue and upon attending the property, no issue is identified or the issue was caused by customer misuse, damage or third party interference, the Company reserves the right to charge a reasonable fee outlined below:</li>
                        </ul>
                        <p>£65+VAT callout<br/>
                            £45+VAT for each hour thereafter<br/>
                            Parts at trade price + 15% + VAT<br/>
                            In certain circumstances, we may request to pre-authorise an agreed amount before attending the site.
                        </p>
                        <br />
                        <p>Unless explicitly pre-agreed the Company will not reimburse the customer for third party appointed labour to rectify an issue, regardless of fault.</p>
                   </div>
                </div>
            </section>
        </>
    );
}

export default Termsandcondition;
