import { Link, useLocation } from "react-router-dom";
import logo from "./../../../assets/images/ces-boiler-shop-logo-white.svg";
import partner1 from "./../../../assets/images/vaillant-logo.png";
import partner2 from "./../../../assets/images/adey-logo.png";
import partner3 from "./../../../assets/images/worcester-logo.png";
import award1 from "./../../../assets/images/fast-growth-50.png";
import award2 from "./../../../assets/images/2019-awards.png";
import award3 from "./../../../assets/images/2020-boiler-award.png";
import award4 from "./../../../assets/images/2020-large-scale-awards.png";
import award5 from "./../../../assets/images/award5.png";
import footerAward from "./../../../assets/images/footer/footerAwardNew.png";
import accreditations from "./../../../assets/images/footer-accreditations.svg";
import accreditationsMobile from "./../../../assets/images/Accreditations-mobile-new.png";
import "./Footer.css";
import { useEffect } from "react";
import aos from "aos";

const Footer = () => {

  // console.log("footer", params.boilerPerMonthFootnote);

  const location = useLocation();
  const disp = (location.pathname === "/")?"block":"none";
  const boilerPerMonthFootnote = (location.pathname === "/result"?"block":"none");
  // if(params.boilerPerMonthFootnote === "none"){
  //   boilerPerMonthFootnote = "none";
  // }

  useEffect(() => {
    aos.init({ duration: 1000 });
  }, []);

  return (
    <>
      <footer data-aos="fade-up">
        <section className="footer-bottom">
          <div className="row">
            <div className="box-container">
              <p className="footer-section-title">We partner with the best</p>
              <div className="flexbox">
                <div className="img-box">
                  <img src={partner1} alt="" />
                </div>
                <div className="img-box">
                  <img src={partner3} alt="" />
                </div>
                <div className="img-box">
                  <img src={partner2} alt="" />
                </div>
              </div>
            </div>
            <div className="box-container award">
              <p className="footer-section-title">Our recent awards</p>
              <img src={footerAward} alt="" className="till-desktop"/>
              <div className="flexbox till-mobile">
                <div className="new-award-section">
                  <div className="img-box custom_award_space">
                    {/* <img src={award1} alt="" /> */}
                  </div>
                  <div className="img-box custom_award_space">
                    <img src={award1} alt="" />
                  </div>
                  <div className="img-box custom_award_space">
                    <img src={award5} alt="" />
                  </div>
                  <div className="img-box custom_award_space">
                    {/* <img src={award1} alt="" /> */}
                  </div>
                </div>
                <div className="old-award-section"  >
                  <div className="img-box custom_award_space">
                    <img src={award3} alt="" />
                  </div>
                  <div className="img-box custom_award_space ">
                    <img src={award4} alt="" />
                  </div>
                  <div className="img-box custom_award_space">
                    <img src={award2} alt="" />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-md-12">
              <p className="footer-section-title">CES Accreditations</p>
              <img className="only-for-desktop" src={accreditations} alt="" />
              <img
                className="only-for-mobile"
                src={accreditationsMobile}
                alt=""
              />
            </div>
          </div>
        </section>
        <section style={{display:disp}}>
          <p><b>* Save up to £50 per month:</b> According to Vaillant’s recent case study (see our page: <a href="/boilerpage" style={{textDecoration:"none"}}>www.cesboilershop.co.uk/boilers</a>) when an ‘A’ rated combi boiler was replaced with the 
            Vaillant ecoTEC Green IQ in an average family home the homeowners saved around £50 per month on their heating bills resulting in a £588 saving for the year. With those 
            figures in mind the Green IQ would pay for itself in under five years and earn the family an additional £588 a year for the rest of its lifetime – which, if serviced regularly, 
            could be anything up to 15 to 20 years.
          </p>
          <p>
              <b>†	Futureproof:</b> All boilers offered through CES Boiler Shop can be easily converted to use a 20% Hydrogen mix reducing 7% CO2 emissions. Currently the 20% Hydrogen 
                rollout is expected from 2023. Following on from this will be Hydrogen-Ready capability using 100% Hydrogen in our existing gas network. This is a much longer roll out 
                process and is designed to ultimately replace natural gas. It is estimated that the first hydrogen town will be 2030.
          </p>
        </section>
        <section style={{display:boilerPerMonthFootnote}}>
          <br/>
          {/* <br/> */}
        <b>* (9.9% APR):</b> (9.9% APR with 50% deposit over 120 months)
        <br/>
          <br/>
        </section>
        <section className="footer-top">
          <div className="row">
            <div className="col-md-4">
              <p className="address-title">Consumer Energy Solutions</p>
              <p className="address">
                1-6 Pockets Wharf
                <br />
                Maritime Quarter
                <br />
                Swansea SA1 3XL
                <br />
                UK
              </p>
              <p className="tel">
                Tel: <a href="tel:01792721162">01792 721162</a>
              </p>
              <p className="email">
                E-mail:{" "}
                <a href="mailto:info@ces-limited.com">info@ces-limited.com</a>
              </p>
            </div>
            <div className="col-md-4 further-section only-for-desktop">
              <p className="further-title">Further information</p>
              <p className="quick-link">
                <Link to="/terms-and-conditions">Terms & Conditions &gt;</Link>
              </p>
              <p className="quick-link">
                <Link to="/privacy">Privacy Policy &gt;</Link>
              </p>
              <p className="quick-link">
                <Link to="/cookie-policy">Cookie Policy &gt;</Link>
              </p>
              <p className="quick-link">
                <Link to="/sitemap">Sitemap &gt;</Link>
              </p>
            </div>
            <div className="further-section only-for-mobile">
              <span className="quick-link">
                <Link to="/terms-and-conditions">Terms & Conditions </Link> |{" "}
              </span>
              <span className="quick-link">
                <Link to="/privacy">Privacy Policy </Link> |{" "}
              </span>
              <span className="quick-link">
                <Link to="/cookie-policy">Cookie Policy</Link> |{" "}
              </span>
              <span className="quick-link">
                <Link to="/sitemap">Sitemap</Link>
              </span>
            </div>
            <div className="col-md-4 mobile-background">
              <Link className="nav--link" to="/" exact>
                <img className="nav-item logo" src={logo} alt="Website logo" />
              </Link>
            </div>
            <div className="col-md-12 only-for-desktop">
              Consumer Energy Solutions Limited is registered in England and
              Wales, No 09959339, and regulated by the Financial Conduct
              Authority, FRN 930823. <br />
              We are a credit broker, not a lender, and offer credit products
              through a panel of lenders. Credit is subject to age and status;
              terms and conditions apply.
            </div>
            <br/>
            <br/>
            <br/>
            <div className="col-md-12 text-center developBy_Text">
              Site Developed and Managed by <a href="https://jcasptechnologies.com" target="_blank">JCaspTechnologies</a>
            </div>
          </div>
        </section>
      </footer>
    </>
  );
};

export default Footer;
