import "./startsurvey.css"
import { useHistory } from 'react-router-dom';
import { useEffect } from "react";
import aos from 'aos';

const StartSurveypage = (params) => {

    useEffect(() => {
        aos.init({duration:400})
    })

    const history = useHistory();
    if(JSON.parse(localStorage.getItem('questions'))){
        let questions = JSON.parse(localStorage.getItem('questions'));
        if(!questions[0].isCurrent){
            history.push("/survey") //Return to survey if first question is not current
        }
    }
    if(JSON.parse(localStorage.getItem('surveyTerminated'))){
        history.push("/survey") //Return to survey if survey is terminated
    }
    return (
        <>
            <section className="startpage find-section" data-aos="fade-up">
                <div className="row">
                    <div className="col-md-6">
                        <h1 >Discover the new <br />boiler in your life</h1>
                        <p>Starting with your postcode below, fill out our concise survey so we can get a picture of your current heating system and property type – it only takes three minutes (honest, we timed it!). We’ll then present you with a short list of boilers that we feel are right for you and your property.</p>
                        <form action="/survey" method="get">
                            <div className="form-group get-started-survey">
                                <label className="control-label">Enter your postcode to begin</label>
                                <div className="flexbox">
                                    <input required className="form-control" name="postcode"/>
                                    <button type="submit">Get started</button>
                                </div>
                            </div>
                        </form>
                        
                    </div>
                    <div className="col-md-6">&nbsp;</div>
                </div>
            </section>
        </>    
    )   
}
export default StartSurveypage
