import "./faq-details.css"
import axios from 'axios';
import React, { Component } from 'react'
import GetYourQuoteBtn from "../../components/get-your-quote-btn/get-your-quote-btn";
import { Accordion } from "react-bootstrap";
import { duration } from "moment";

export class FAQs extends Component {

    state = {
        listData: [],
        selectedCat: {
            name: '',
            _id: '',
        },
        selectedQ: '',
    }

    componentDidMount = async () => {
        var slug = this.props.match.params.slug
        var all = JSON.parse(localStorage.getItem('faqs'))
        var faqID = all.filter(a => {
            if (a.slug == slug) {
                return a;
            }
        })

        faqID = faqID[0];
        if (faqID) {
            await this.setState({
                selectedCat: faqID  
            })
            await this.getAll(faqID._id);
        } else {
            this.props.history.goBack()
        }
    }

    getAll = async (id) => {
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/faq/category/${id}`,
        };

        var res = await axios(config)

        var selectedQ = localStorage.getItem('selectedFaq');
        if(selectedQ != null){
            await this.setState({
                selectedQ: selectedQ
            })
            localStorage.removeItem('selectedFaq')
        } else {
            await this.setState({
                selectedQ: res.data.docs[0]._id
            })
        }

        this.setState({
            listData: res.data.docs
        })
    }

    handlePage = (slug, _id = '') => {
        this.props.history.push(`./faq/${slug}`)
    }

    handleSearch = () => {
        this.getAllCategory();
    }

    setSearch = (e) => {
        this.setState({
            search: e.target.value
        })
    }

    render() {
        return (
            <>
                <section className="faqs-section">
                    <div className="row justify-content-md-center">
                        <div className="col-md-12 text-center capitalize"><h1>{this.state.selectedCat.name}</h1></div>
                    </div>
                </section>
                <section className="faqs-second-section">
                    <div className="row">
                        {
                            (this.state.listData.length > 0) ? (
                                    <Accordion defaultActiveKey={this.state.selectedQ}>
                                        {
                                            this.state.listData.map(faq => 
                                                <Accordion.Item eventKey={faq._id} className="faq-card">
                                                    <Accordion.Header>{faq.question}</Accordion.Header>
                                                    <Accordion.Body>
                                                        <div className="desc-list-wrapper" dangerouslySetInnerHTML={{__html: faq.answer }} />
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                            )
                                        }
                                    </Accordion>     
                            ) : (
                                <div className="notfound_faq">
                                    Result not found
                                </div>
                            )
                        }
                        <div className="col-md-12">
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default FAQs
