import "./blogDetails.css"
import axios from 'axios';
import React, { Component } from 'react'
import moment from 'moment';
import facebookIcon from './../../../assets/images/blog/facebook.svg'
import twitterIcon from './../../../assets/images/blog/twitter.svg'
import whatsappIcon from './../../../assets/images/blog/whatsapp.svg'
import messengerIcon from './../../../assets/images/blog/messenger.svg'

export class BlogDetails extends Component {

    state = {
        details: [],
        related_blogs: [],
        slug: ''
    }

    componentDidMount = async () => {
        var slug = this.props.match.params.slug
        await this.setState({
            slug: slug
        })
        await this.getDetail();
    }

    getDetail = async () => {
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/blog/details/${this.state.slug}`,
        };
    
        axios(config).then((res)=>{
            this.setState({
                details: res.data
            })
            this.getAllblogs();
        }).catch((error) => {
            this.props.history.goBack()
        })
    }

    getAllblogs = async () => {
        var slug = this.state.details.category._id
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/blog/category/${slug}?page=${1}&perPage=10`,
        };
    
        axios(config).then((res)=>{
            this.setState({
                related_blogs: res.data.docs
            })
        }).catch((error) => {
        })
    }

    handleBlogDetails = async (slug) => {
        await this.props.history.push(`/blog/${slug}`)
        this.setState({
            slug: slug
        })

        await this.getDetail();
    }

    render() {
        return (
            <>
                <section className="blog-section">
                    <div className="row justify-content-md-center">
                        <div className="col-md-12 text-center capitalize"><h1>{this.state.details.headline}</h1></div>
                    </div>
                </section>
                <section className="blog_details">
                    <div className="row">
                        <div className="col-md-8">
                            <div className="share_buttons">
                                <a href={`http://www.facebook.com/sharer.php?u=${window.location.href}`} className="facebook_btn" rel='nofollow' target='_blank' title='Share This On Facebook'>
                                    <img src={facebookIcon} alt=""/>
                                    <span>Facebook</span>
                                </a>
                                <a href={`https://twitter.com/share?url=${window.location.href}`} className="twitter_btn" rel='nofollow' target='_blank' title='Tweet This'>
                                    <img src={twitterIcon} />
                                    <span>Twitter</span>
                                </a>
                                <a href={`https://web.whatsapp.com/send?text=${window.location.href}`} className="whatsapp_btn" target='_blank'>
                                    <img src={whatsappIcon} />
                                    <span>Whatsapp</span>
                                </a>
                                <a href={ `fb-messenger://share/?link=${window.location.href}`} className="messanger_btn">
                                    <img src={messengerIcon } />
                                    <span>Messenger</span>
                                </a>
                            </div>
                            <img src={this.state.details.featured_image} className="mb-4" />
                            <div className="my_html" dangerouslySetInnerHTML={{__html: this.state.details.content}}></div>
                        </div>
                        <div className="col-md-4">
                            <h2>Related Articles</h2>
                            <div className="white-box-blog">
                                <div className="row">
                                   {
                                       this.state.related_blogs.map(rb => {
                                            if(rb._id != this.state.details._id){
                                                return <div className="row my_blog" onClick={() => this.handleBlogDetails(rb.slug) }>
                                                    <div className="col-md-4">
                                                        <img src={rb.featured_image} />
                                                    </div>
                                                    <div className="col-md-8 color-skyblue r-b-heading" >
                                                        {rb.headline}
                                                    </div>
                                                    <div className="col-12">
                                                        <hr />
                                                    </div>
                                                </div>
                                            }
                                        })
                                   }
                                    
                                   
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default BlogDetails
