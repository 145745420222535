import Navbar from './../components/navbar/Navbar';
import Footer from './../components/footer/Footer';
import { useLocation } from 'react-router-dom'
const Layout = (props) =>{
    const location = useLocation();
    return (
    <main>
        <Navbar isHomepage={ (location.pathname == "/") ? 1 : 0 }></Navbar>
        {props.children}
        <Footer />
    </main>
    )
}

export default Layout;