import "./get-your-quote-btn.css"

const GetYourQuoteBtn = (props) => {
    // let toreturn;
    let bg_color = props.bgColor //bgColor should be declared in index.css file as variable
    console.log(bg_color);
    let color = props.color
    let action = props.action ? props.action : null
    let icon = (props.icon && props.icon == "calendar") ? <img src={"./grants/icons/calendar.png"}/> : ""
    icon = (props.icon == "chat") ? <img src={"./grants/icons/Chat-icon.png"} className="chat_image"/> : icon
    
        return (
            <div className="quote-button"><button className={`${bg_color}glow`} onClick={action} style={{backgroundColor: `var(--color-${bg_color})`, color: color}}>{icon}{props.text}</button></div>  
        )    
}
export default GetYourQuoteBtn;
