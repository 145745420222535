import "./faq.css"
import axios from 'axios';
import React, { Component } from 'react'
import GetYourQuoteBtn from "./../../components/get-your-quote-btn/get-your-quote-btn";

export class FAQs extends Component {

    state = {
		listData: [],
        search: '',
    }

    componentDidMount = () => {
		this.getAllCategory();
	}

    getAllCategory = async () => {
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/faq/categories?search=${this.state.search}`,
        };

        var res = await axios(config)
        localStorage.setItem('faqs', JSON.stringify(res.data.docs))
        this.setState({
            listData: res.data.docs
        })
    }

    handlePage = (slug, _id = '') => {
        if(_id != ''){
            localStorage.setItem('selectedFaq', _id);
        }
        this.props.history.push(`./faq/${slug}`)
    }

    handleSearch = () => {
        this.getAllCategory();
    }

    setSearch = async (e) => {
        await this.setState({
            search: e.target.value
        })
        this.getAllCategory();
    }

    render() {
        return (
            <>
                <section className="faqs-section mb-4">
                    <div className="row justify-content-md-center ">
                        <div className="col-md-12 text-center"><h1>FAQs</h1></div>
                    </div>
                </section>
                <section className="faqs-second-section">
                    <div className="row">
                        <div className="col-12 mb-4">
                            <div className="row justify-content-md-center">
                                <div className="col-md-6 col-sm-12">
                                    <div class="input-group">
                                        <input type="text" placeholder="Search by keyword" onChange={this.setSearch} value={this.state.search}/>
                                    </div>
                                </div>
                            </div>
                            {/* <div className="row justify-content-md-center ">
                                <div className="col-md-6 col-sm-12 mt-2 mb-4 text-center my_serch_btn">
                                    <GetYourQuoteBtn text="Search" bgColor="skyblue" color="white" action={ this.handleSearch} />
                                </div>
                            </div> */}
                        </div>


                        {
                            (this.state.listData.length>0)?(
                                this.state.listData.map(faq => 
                                    <div className="col-md-6 col-xs-12 my_faq_cat">
                                        <div className="faq_card">
                                            <h2 className="faq_heading" onClick={() => this.handlePage(faq.slug)}>{faq.name}</h2>
                                            
                                            {
                                                faq.questions.map(q => 
                                                    <div>
                                                        <a onClick={() => this.handlePage(faq.slug, q._id)} >{q.question}</a>
                                                    </div>
                                                )
                                            }
                                            <div className="view_all_link">
                                                <a onClick={() => this.handlePage(faq.slug)} className="text-muted">View all questions</a>
                                            </div>
                                        
                                        </div>
                                    </div>
                                )
                            ) : (
                                <div className="notfound_faq">
                                    Result not found
                                </div>
                            )
                        }
                        <div className="col-md-12">
                        </div>
                    </div>
                </section>
            </>
        );
    }
}

export default FAQs
