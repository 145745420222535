import "./blog.css"
import axios from 'axios';
import React, { useEffect,Component } from 'react'
import moment from 'moment';

export class Blogs extends Component {

    state = {
        listData: [],
        search: '',
        selected_category: '',
        current_page: 1,
        per_page: 9,
        pagination: {},
        categories: [],
        selectedCategory: ''
    }

    componentDidMount = async () => {
        await this.getAll();
        await this.getAllCategory();
    }

    getAll = async () => {

        if(this.state.selectedCategory == ''){
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}/blog?page=${this.state.current_page}&perPage=${this.state.per_page}&search=${this.state.search}`,
            };
        } else {
            var config = {
                method: 'get',
                url: `${process.env.REACT_APP_API_URL}/blog/category/${this.state.selectedCategory}?page=${this.state.current_page}&perPage=${this.state.per_page}`,
            };
        }

        var res = await axios(config)
        this.setState({
            listData: res.data.docs,
            pagination: res.data
        })
    }

    getAllCategory = async () => {
        var config = {
            method: 'get',
            url: `${process.env.REACT_APP_API_URL}/blog/categories`,
        };

        var res = await axios(config)
        this.setState({
            categories: res.data,
        })
    }

    handleSearch = () => {
        this.getAllCategory();
    }

    handleCategory = async (e) => {
        await this.setState({
            selectedCategory: e.target.value,
            current_page: 1,
            search: ''
        })

        this.getAll()
    }

    setSearch = async (e) => {
        await this.setState({
            search: e.target.value,
            selectedCategory: '',
        })

        await this.getAll();
    }

    handleBlogDetails = (slug) => {
        this.props.history.push(`/blog/${slug}`)
    }

    jumpPage = async (page) => {
        await this.setState({
            current_page: page
        })

        await this.getAll()
    }

    render() {
        return (
            <>
                <section className="blog-section">
                    <div className="row justify-content-md-center">
                        <div className="col-md-12 text-center capitalize"><h1>Read our latest blog articles</h1></div>
                        <div className="col-md-12 text-center"><p>Read the latest news, tips and advice from Derek & the  team.</p></div>
                    </div>
                </section>
                <section className="blog-filter-section">
                    <div className="row justify-content-md-center">
                        <div className="col-md-8 col-xs-12">
                            <div className="row justify-content-md-center align-items-center">
                                <div className="col-md-8 col-sm-6">
                                    <input type="text" placeholder="Search by keyword" onChange={this.setSearch} value={this.state.search} />
                                </div>
                                <div className="col-md-1 text-center col-sm-2 mr-m-1">
                                    OR
                                </div>
                                <div className="col-md-3 col-sm-4">
                                    <select onChange={this.handleCategory}>
                                        <option value="">All</option>
                                        {
                                            this.state.categories.map((val, index) => 
                                            <option value={val._id} selected={(this.state.selectedCategory == val._id) ? 'selected' : ''}>{val.name}</option>
                                            )
                                        }
                                    </select>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                <section className="blog_category_list">
                    <div className="row">
                        {
                            (this.state.listData.length >0) ? (
                            
                                this.state.listData.map(blog =>
                                    <div className="blog_item col-12 col-sm-6 col-lg-4 mb-4">
                                        <a className="inner_item" onClick={() => this.handleBlogDetails(blog.slug)}>
                                            <figure>
                                                <img src={blog.featured_image} />
                                                <span class="view-date position-absolute text-block">
                                                    <em>{moment(blog.timestamp).format('D')}</em>
                                                    {moment(blog.timestamp).format('MMM')}
                                                </span>
                                            </figure>
                                            <div className="description">
                                                <div className="heading"><h4>{blog.headline}</h4></div>
                                                <p>{blog.content.replace(/<[^>]*>?/gm, '').substring(0, 85) }...</p>
                                            </div>
                                        </a>
                                        <div className="blog_category">
                                            <div>
                                                <strong>Category: {blog.category.name}</strong>
                                            </div>
                                        </div>
                                    </div>
                                )
                            ) : (<>
                                <div className="text-center">
                                    No blog found
                                </div>
                            </>)
                        }
                    </div>

                    {
                        (this.state.listData.length >0) ? 
                            <div className="row">
                                <nav aria-label="Blog_pages">
                                    <ul className="pagination justify-content-md-end">
                                        <li className={`page-item ${this.state.pagination.hasPrevPage ? "" : "disabled"}`}>
                                            <a className="page-link" onClick={() => { this.jumpPage(this.state.pagination.page-1) }} tabindex="-1">Previous</a>
                                        </li>
                                        {
                                            [...Array(this.state.pagination.totalPages)].map((val, index) => 
                                                <li className={`page-item ${(this.state.pagination.page == (index+1)) ? 'active': ''}`}>
                                                    <a className="page-link" onClick={() => this.jumpPage(index+1)}>{index+1}</a>
                                                </li>
                                            )
                                        }
                                        <li className={`page-item ${this.state.pagination.hasNextPage ? "" : "disabled"}`}>
                                            <a className="page-link" onClick={() => this.jumpPage(this.state.pagination.page+1)}>Next</a>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        : ''
                    }

                </section>
            </>
        );
    }
}

export default Blogs
