import { useEffect } from "react";
import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import ReactPlayer from 'react-player'

import "./privacy.css"
import siteLogoColored from "./../../../assets/images/aboutus/CES-Logo.svg"
import aboutSecondSection from "./../../../assets/images/aboutus/Placeholder pic.png"
import directorImg from "./../../../assets/images/aboutus/3-directors.png"
import trustPilotStars from "./../../../assets/images/aboutus/Trustpilot-stars.png"

import truspilotImg from "./../../../assets/images/CES-Trustpilot.png"

import GetYourQuoteBtn from "../../components/get-your-quote-btn/get-your-quote-btn"
import TrustpilotSlider from "../../components/trustpilot-slider/TrustpilotSlider"
import PriceGuarantee from "../../components/price-guarantee/PriceGuarantee";
import aos from 'aos';


const Privacy = () => {

    // useEffect(() => {
    //     aos.init({duration:2000})
    // },[])

    return (
        <>
            <section className="privacy-section">
                <div className="row">
                    <div className="col-md-12"><h1>Privacy Policy</h1></div>
                </div>
            </section>
            <section className="privacy-second-section">
                <div className="row">
                    <div className="col-md-12 text">
                    {/* <p className="color-skyblue"><b>This is the Privacy Notice for Consumer Energy Solutions Limited.</b></p>
                    <p>Registered address: The Third Floor, Langdon House Langdon Road, Swansea Waterfront, Swansea, West Glamorgan, Wales, SA1 8QY</p>
                    <p>Company Registration Number is: 09959339</p>
                    <p>Tel: 01792 721 140</p>
                    <p>E-mail – <a href="mailto:info@ces-limited.com" className="color-skyblue">info@ces-limited.com</a></p>
                    <p>We are registered with the Information Commissioners Office (ICO) Regulatory number: ZA387531</p>
                    <p>We are a data controller of any data we hold and gather. We receive data from a number of data providers. A full list is available on request.</p> */}

                    <h2>Privacy Policy</h2>
                    <p>This page sets out our policies on the collection, use and disclosure of personal information on our website.</p>
                    <p>By choosing to use our service, you are agreeing to the terms of this policy. The data we collect is used by us to provide and improve our service. We will not use or share your information with anyone except as described in this Privacy Policy. </p>
                    <p>Unless otherwise defined, the terms used in this Privacy Policy have the same meanings as those in our Terms and Conditions.</p>

                    <h2>Information collection and use</h2>
                    <p>For a better experience while using our website, we may require you to provide us with certain personal information, including but not limited to your name, phone number and postal address. The information we collect will be used to contact or identify you.</p>
                    
                    <h2>Log Data</h2>
                    <p>Whenever you visit our website, we collect information your browser sends to us called Log Data. This may include information such as your computer’s Internet Protocol (‘IP’) address, browser version, pages of our website you visit, the time and date of your visit, the time spent on those pages and other statistics.</p>

                    <h2>Cookies</h2>
                    <p>Cookies are small data files commonly used as an anonymous unique identifier. These are sent to your browser from the website you visit and are stored on your computer’s hard drive.</p>
                    <p>
                    Our website uses cookies to collect information and to improve our website. You have the option to either accept or refuse these and know when a cookie is being sent to your computer. If you choose to refuse our cookies, you may not be able to use some sections of our website. Please see our separate ‘Cookie Policy’ for further details.
                    </p>

                    <h2>Service providers</h2>
                    <p>We may employ third-party companies and individuals to: </p>
                    <ul className="privacy_page_list">
                        <li>facilitate our website;</li>
                        <li>provide the service on our behalf;</li>
                        <li>perform service-related tasks; or</li>
                        <li>assist us in analysing how our Service is used.</li>
                    </ul>
                    <p>These third parties have access to your personal information so they can perform the tasks assigned to them on our behalf. However, they are obligated not to disclose or use the information for any other purpose.</p>

                    <h2>Security</h2>
                    <p>We value your trust in providing us with your personal information, so we are doing our best to protect it. But remember, no method of transmission over the internet or electronic storage is 100% secure and reliable and we cannot guarantee its absolute security. </p>

                    <h2>Links to other sites</h2>
                    <p>Our website may contain links to other sites not operated by us. If you click on a third-party link, you will be directed to that site and we strongly advise you review its Privacy Policy. We have no control over, and assume no responsibility for, the content, privacy policies or practices of any third-party sites or services.</p>
                    
                    <h2>Children’s privacy</h2>
                    <p>Our website does not address anyone under the age of 13 and we do not knowingly collect personal identifiable information from anyone under that age. If we discover a child under 13 has provided us with personal information, we immediately delete this from our servers. If you are a parent or guardian and you are aware your child has provided us with personal information, please contact us so we can complete the necessary actions. </p>

                    <h2>Changes to this Privacy Policy</h2>
                    <p>We may update our Privacy Policy from time to time, so we advise you to review this page periodically for any changes. We will notify you of any changes by posting the new Privacy Policy on this page. These changes are effective immediately, after they are posted on this page.</p>

                    <h2>Contact us</h2>
                    <p>If you have any questions or suggestions about our Privacy Policy, do not hesitate to contact us at: <a href="mailto:info@ces-limited.com">info@ces-limited.com.</a></p>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Privacy;
