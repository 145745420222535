import "./price-guarantee.css"
import personImg from "./../../../assets/images/aboutus/Lee-Harding.png"
import warrantyImg from "./../../../assets/images/10-year-warranty-seal.svg"
import bestPricingImg from "./../../../assets/images/best-pricing-mobile.png"
import GetYourQuoteBtn from "../get-your-quote-btn/get-your-quote-btn"
import { useHistory } from "react-router-dom";
// import aos from 'aos';
// import { useEffect } from "react"

const PriceGuarantee = (params) => {

    // useEffect(() => {
    //     aos.init({duration:400})
    // }, [])
    let classNames = params.className ? params.className : "";

    let history = useHistory();

    // handleQuote FUNCTION TO REDIRECT TO STARTSURVEY PAGE
    const handleQuote = () => {
        history.push("./startsurvey");
    }
    return (
        <>
            <section className={"price-guarantee "+classNames}>
                <div className="row">
                    <div className="price-content col-md-5">
                        <div className="section-title" data-aos="fade-up">The CES culture</div>
                        <p data-aos="fade-up">
                            What’s important to you is even more important to us!
                        </p>
                        <p className="content" data-aos="fade-up">
                            No matter your age, location, income or shoe size we won’t charge you any more or any less. And, as many of our happy customers will confirm, we treat everyone to the same high standards of customer service. From our customer service staff right the way through to our installers
                            <br />– we are all CES people.
                        </p>
                        <div className="price-button">
                            <GetYourQuoteBtn action={ handleQuote } text="Get your quote" bgColor="skyblue" color="white"></GetYourQuoteBtn>
                        </div>
                    </div>
                    <div className="price-images col-md-7">
                        <img src={personImg} alt="" className="show-desktop" />
                        <img src={bestPricingImg} alt="" className="show-mobile" />
                        <img src={warrantyImg} alt="" className="show-desktop" />
                    </div>
                </div>
            </section>
        </>
    )
}
export default PriceGuarantee;
