import CountUp from "react-countup";
import VisibilitySensor from "react-visibility-sensor";
import ReactPlayer from 'react-player'

import "./Aboutus.css"
import siteLogoColored from "./../../../assets/images/aboutus/CES-Logo.svg"
import aboutSecondSection from "./../../../assets/images/aboutus/Placeholder pic.png"
import directorImg from "./../../../assets/images/aboutus/directors.png"
import trustPilotStars from "./../../../assets/images/aboutus/Trustpilot-stars.png"
import about1 from './../../../assets/images/aboutus/about-1.png'
import about2 from './../../../assets/images/aboutus/about-2.png'

import truspilotImg from "./../../../assets/images/CES-Trustpilot.png"

import GetYourQuoteBtn from "../../components/get-your-quote-btn/get-your-quote-btn"
import TrustpilotSlider from "../../components/trustpilot-slider/TrustpilotSlider"
import PriceGuarantee from "../../components/price-guarantee/PriceGuarantee";
import aos from 'aos'
import { useEffect, useState} from "react";
import { duration } from "moment";

const Aboutus = () => {

    const [chat, setChat] = useState(true);

    useEffect(() => {
        aos.init({duration:2000});
    },[])

    //LIVE CHAT POP
    const liveChat = () => {
        if(chat){
            window.HubSpotConversations.widget.open();
            setChat(false)
        }else{
            window.HubSpotConversations.widget.close();
            setChat(true)
        }
    }

    return (
        <>
            <section className="about-section">
                <div className="row">
                    <div className="col-md-12"><h1>About us</h1></div>
                    <div className="col-md-6">
                        
                    </div>
                    <div className="col-md-6">
                        
                    </div>
                </div>
            </section>
            <section className="about-second-section" data-aos="fade-up">
                <div className="row">
                    <div className="col-md-6 text">
                        <p className="sub_heading">We’re not the first and we won’t be the last, but we are definitely a different boiler seller to all the others out there.</p>

                        <p>At CES we approach the boiler install market from two distinct angles: <strong>our background</strong> and <strong>our objective.</strong></p>
                        <p><strong>Our background</strong> has seen us operating in the highly regulated ECO funded sector for the last six years. As an organisation we install a range of heating systems and insulation into UK homes to transform them into more energy efficient households at no cost to the resident, designed to reduce fuel poverty and carbon emissions. We’d like to transfer the high standards we’ve had to adhere to in the ECO market over to our paying customers too. </p>
                        <p><strong>It’s the only way we know how.</strong></p>
                        <p>We are obviously Gas Safe and Trustmark registered as well as being accredited with HIES and GDGC to give you peace of mind, along with being MCS and NIC EIC certified and registered with NAPIT among many, many others. If there’s an accreditation to be had we’ve got it.</p>
                        <p>And being accredited installers with Vaillant, Worcester Bosch and Adey and having worked so closely with them over the years we’ve built up a great support network, guaranteeing double fast response times.</p>

                        <div className="about-image-left">
                            <img src={about2} alt=""/>
                        </div>
                    </div>
                    <div className="col-md-6 img">
                        <div className="about-logo-right">
                            <img src={siteLogoColored} alt=""/>
                        </div>
                        <div className="mt-about-custom">
                            <img src={about1} alt=""/>
                        </div>

                        <p>
                            <strong>Our objective</strong> is to offer the very best boilers for the very best prices – simple. We feel having the best is a real financial investment, and that is no more fitting than with our real state-of-the-art, future proof boilers, putting money back in your pocket for the lifetime of the machine. For instance, installing the Vaillant Green IQ could save you and your family over £500 a year!</p>
                        
                        <p><strong>But it’s not about us, it’s about you. Your options, your choice, your future. </strong></p>
                        
                    </div>
                </div>
            </section>
            <section className="director-image-section full-width" data-aos="fade-up">
                <img src={directorImg} alt=""/>
            </section>
            {/* <section className="our-values-section">
                <h2>Our Values</h2>
                <div className="row">
                    <div className="col-md-6">
                        <p>
                            Lorem ipsum dolor sit amet, consectetuer adipiscing 
                            elit, sed diam nonummy nibh euismod tincidunt ut 
                            laoreet dolore magna aliquam erat volutpat. Ut wisi 
                            enim ad minim veniam, quis nostrud exerci tation 
                            ullamcorper suscipit lobortis nisl ut aliquip ex ea 
                            commodo consequat. Duis autem vel eum iriure 
                            dolor in hendrerit in vulputate velit esse molestie 
                            consequat, vel illum dolore eu feugiat nulla facilisis at 
                            vero eros et accumsan et iusto odio dignissim qui 
                            blandit praesent luptatum zzril delenit augue duis 
                            dolore te feugait nulla facilisi.
                        </p>    
                        <div className="quote">
                            <p>“Quote from CES director here, 
                                quote from CES director here, 
                                quote from CES director, quote 
                                from CES director.”
                            </p>
                            <span>Director name</span>
                        </div>
                        <p>
                            Lorem ipsum dolor sit amet, consectetuer adipiscing 
                            elit, sed diam nonummy nibh euismod tincidunt ut 
                            laoreet dolore magna aliquam erat volutpat. Ut wisi 
                            enim ad minim veniam, quis nostrud exerci tation 
                            ullamcorper suscipit lobortis nisl ut aliquip ex ea 
                            commodo consequat. Duis autem vel eum iriure 
                            dolor in hendrerit in vulputate velit esse molestie 
                            consequat, vel illum dolore eu feugiat nulla facilisis at 
                            vero eros et accumsan et iusto odio dignissim qui 
                            blandit praesent luptatum zzril delenit augue duis 
                            dolore te feugait nulla facilisi.
                        </p>
                    </div>
                    <div className="col-md-6">
                        <div className="quote mt0">
                            <p>“Quote from CES director here, 
                                quote from CES director here, 
                                quote from CES director, quote 
                                from CES director.”
                            </p>
                            <span>Director name</span>
                        </div>
                        <p>
                            Lorem ipsum dolor sit amet, consectetuer adipiscing 
                            elit, sed diam nonummy nibh euismod tincidunt ut 
                            laoreet dolore magna aliquam erat volutpat. Ut wisi 
                            enim ad minim veniam, quis nostrud exerci tation 
                            ullamcorper suscipit lobortis nisl ut aliquip ex ea 
                            commodo consequat. Duis autem vel eum iriure 
                            dolor in hendrerit in vulputate velit esse molestie 
                            consequat, vel illum dolore eu feugiat nulla facilisis at 
                            vero eros et accumsan et iusto odio dignissim qui 
                            blandit praesent luptatum zzril delenit augue duis 
                            dolore te feugait nulla facilisi.
                        </p>
                        <div className="quote">
                            <p>“Quote from CES director here, 
                                quote from CES director here, 
                                quote from CES director, quote 
                                from CES director.”
                            </p>
                            <span>Director name</span>
                        </div>
                    </div>
                </div>
            </section> */}
            <section className="counter-section full-width">
                <div className="row">
                    <div className="col-md-4">
                        <VisibilitySensor partialVisibility>
                            {({ isVisible }) => (
                            <div>
                                {isVisible ? <CountUp start={0} end={2016} duration={3}/> : null}
                                <p>CES is founded</p>
                            </div>
                            )}
                        </VisibilitySensor>
                    </div>
                    <div className="col-md-4">
                        <VisibilitySensor partialVisibility>
                            {({ isVisible }) => (
                            <div>
                                {isVisible ? <CountUp start={0} end={1371} duration={3} separator={","}/> : null}
                                <p>Boilers installed</p>
                            </div>
                            )}
                        </VisibilitySensor>
                    </div>
                    <div className="col-md-4">
                        <VisibilitySensor partialVisibility>
                            {({ isVisible }) => (
                            <div>
                                {isVisible ? <CountUp start={0} end={920} duration={3}/> : null}
                                <p>Heating systems installed</p>
                            </div>
                            )}
                        </VisibilitySensor>
                    </div>
                </div>
            </section>
            {/* <section className="video-section">
                <h2>More from director, Lewis John...</h2>
                <ReactPlayer className="video-player" url='https://www.youtube.com/watch?v=ysz5S6PUM-U' width='100%'/>
            </section> */}
            <section className="single-trustpilot">
                <h2>Excellent company</h2>
                <p>“Excellent company. Professional from start to finish. Jamal kept us informed at every stage. Engineers were polite, worked really hard and cleaned up beautifully. Would definitely recommend them.”</p>
                <p className="reviewer">Mr Neil Pattison</p>
                <img src={trustPilotStars} alt=""/>
            </section>
            <PriceGuarantee className="about-page"/>
            <section className="trustpilot full-width about-page">
                <div className="row">
                    <div className="col-lg-2 col-md-3 col-sm-5 col-xs-5">
                        <img src={truspilotImg} alt="" />
                    </div>
                    <div className="col-lg-10 col-md-9 col-sm-7 col-xs-7">
                        <TrustpilotSlider></TrustpilotSlider>
                    </div>
                </div>
            </section>
            <section className="faq-section text-center about-page" data-aos="fade-up">
                <div className="section-title">Any questions?</div>
                <p className="">We are all unique just like our various questions and queries. <br />Chat live below or contact us for further information</p>
                <div onClick={liveChat}>
                <GetYourQuoteBtn text="Chat with us" icon="chat" bgColor="skyblue" color="white"></GetYourQuoteBtn>
                </div>
            </section>
        </>
    );
}

export default Aboutus;
