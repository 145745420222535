import "./Notfound.css"
import aos from 'aos';
import { duration } from "moment";
import { useEffect } from "react";


const Notfound = () => {

    useEffect(() => {
        aos.init({duration:"400"})
    }, [])

    return (
        <div className="not-found full-height flexbox flexbox-centered" data-aos="fade-down">
            <h3>404 | Page not found</h3>
        </div>
    )
}
 export default Notfound;
 