import { Route, Switch } from 'react-router-dom';
import './App.css';
import Layout from './views/layouts/Layout';
import Aboutus from './views/pages/aboutus/Aboutus';
import Boilerpage from './views/pages/boilerpage/Boilerpage';
import Financialpage from './views/pages/financialpage/financialpage';
import Contactpage from './views/pages/contactpage/Contact';
import Privacypage from './views/pages/privacy/privacy';
import Homepage from './views/pages/homepage/Homepage';
import Notfound from './views/pages/notfound/Notfound';
import Resultpage from './views/pages/resultspage/Resultpage';
import StartSurveypage from './views/pages/startsurveypage/startsurvey';
import Survey from './views/pages/survey/survey';
import Faqspage from './views/pages/faq-list/faq';
import Faqpage from './views/pages/faq/faq-details';
import BlogPage from './views/pages/blog/blog';
import BlogDetailPage from './views/pages/blogDetails/blogDetails';
import TermsandconditionPage from './views/pages/termsandcondition/termsandcondition';
import CookiePage from './views/pages/cookie/cookies'
import ScrollToTop from './scrollTop'
import Covidpolicy from './views/pages/covidpolicy/covidpolicy';
import Trustpilot from "./views/components/trustpilot/Trustpilot"
import TrustpilotM from "./views/components/trustpilot/TrustpilotM"

function App() {
  return (
    <Layout>
      <ScrollToTop>
        <Switch>
          <Route path="/" exact>
            <Homepage />
          </Route>
          <Route path="/aboutus">
            <Aboutus />
          </Route>
          <Route path="/result">
            <Resultpage />
          </Route>
          <Route path="/boilerpage">
            <Boilerpage />
          </Route>
          <Route path="/financialpage">
            <Financialpage />
          </Route>
          <Route path="/startsurvey">
            <StartSurveypage />
          </Route>
          <Route path="/survey">
            <Survey />
          </Route>
          <Route path="/contact">
            <Contactpage />
          </Route>
          <Route path="/privacy">
            <Privacypage />
          </Route>
          <Route path="/terms-and-conditions">
            <TermsandconditionPage />
          </Route>
          <Route path="/ces-legal-terms-and-conditions">
            <TermsandconditionPage />
          </Route>
          <Route path="/cookie-policy">
            <CookiePage />
          </Route>
          <Route path="/covidpolicy">
            <Covidpolicy />
          </Route>
          <Route path="/trustpilot">
            <Trustpilot/>
          </Route>
          <Route path="/trustpilotM">
            <TrustpilotM/>
          </Route>
          <Route path="/faqs" component={Faqspage} />
          <Route path="/faq/:slug?" component={Faqpage} />
          <Route path="/blogs" component={BlogPage} />
          <Route exact path="/blog/:slug?" component={BlogDetailPage} />
          <Route path="*">
            <Notfound />
          </Route>
        </Switch>
      </ScrollToTop>
    </Layout>
  );
}

export default App;
