import "./contact.css"
import GoogleMapReact from 'google-map-react';
import teamImg from "../../../assets/images/contact/Team-pic.png"
import teamImgMobile from "../../../assets/images/contact/Team-pic-mobile.png"
import liveChat from "../../../assets/images/contact/Live-chat-box.png"
import validator from 'validator';
import { useRef, useEffect, useState} from "react";
import aos from 'aos';
import axios from "axios";
import Swal from 'sweetalert2'

const AnyReactComponent = ({ text }) => <div>{text}</div>;


const Contactpage = (params) => {

  const [FormErrors, setFormErrors] = useState({});
  const [isSubmitted, setisSubmitted] = useState(false);
  const [chat, setChat] = useState(true);

  const NameContactName = useRef();
  const PhoneContactPhone = useRef();
  const EmailContactEmail = useRef();
  const SubjectContactSubject = useRef();
  const MessageContactMessage = useRef();

  useEffect(() => {
    aos.init({duration:2000});
  },[]);

  let defaultProps = {
    center: {
      lat: 59.95,
      lng: 30.33
    },
    zoom: 11
  };
  if (params.lat) {
    defaultProps.center.lat = params.lat
  }
  if (params.lng) {
    defaultProps.center.lng = params.lng
  }
  if (params.zoom) {
    defaultProps.zoom = params.zoom
  }

  //Added by David Jcasp dev. 14-0ct-2021 start
  const contactFormSubmit = function (e) {

    e.preventDefault();

    if (EmailContactEmail.current.value == '') {
        FormErrors.ContactEmail = 'Please enter email.'
    } else if (!validator.isEmail(EmailContactEmail.current.value)) {
        FormErrors.ContactEmail = 'Please enter valid email.'
    } else {
        delete FormErrors.ContactEmail;
    }

    if (PhoneContactPhone.current.value == '') {
        FormErrors.ContactPhone = 'Please enter phone number.'
    } else if (!validator.isMobilePhone(PhoneContactPhone.current.value)) {
        FormErrors.ContactPhone = 'Please enter valid phone number.'
    } else {
        delete FormErrors.ContactPhone;
    }

    if (NameContactName.current.value == '') {
        FormErrors.ContactName = 'Please enter your name.'
    } else {
        delete FormErrors.ContactName;
    }

    if (SubjectContactSubject.current.value == '') {
      FormErrors.ContactSubject = 'Please enter the subject.'
    } else {
        delete FormErrors.ContactSubject;
    }

    if (MessageContactMessage.current.value == '') {
      FormErrors.ContactMessage = 'Please enter your enquiry.'
    } else {
        delete FormErrors.ContactMessage;
    }

    setFormErrors({ ...FormErrors })

    // if (Object.keys(FormErrors).length === 0) {

    //     let questions = localStorage.getItem('questions') ? localStorage.getItem('questions') : [];
    //     questions = JSON.parse(questions)

    //     let selectedQueation = [];

    //     for (let index = 0; index < questions.length; index++) {
    //         const q = questions[index];
    //         var selectedOption = q.options.filter(option => option.isSelected == true)

    //         let so = null;
    //         if (selectedOption.length > 0) {
    //             so = selectedOption[0].value
    //         }
    //         selectedQueation.push({
    //             question_value: q.question_value,
    //             selectedOption: so
    //         })
    //     }

    //     var QuoteRequests = {
    //         email: EmailQuoteEmail.current.value,
    //         boiler_key: EmailQuoteBoiler,
    //         telephone: EmailQuotePhone.current.value,
    //         subscribe: EmailQuoteSubscribe,
    //         questions: selectedQueation
    //     };
    //     var config = {
    //         method: 'post',
    //         url: `${process.env.REACT_APP_API_URL}/quote/sent-email`,
    //         headers: {
    //             'Content-Type': 'application/json'
    //         },
    //         data: QuoteRequests
    //     };
    //     setisSubmitted(true)
    //     axios(config)
    //         .then(res => {
    //             setisSubmitted(false)
    //             Swal.fire(
    //                 'Success!',
    //                 'Quote submitted successfully.',
    //                 'success'
    //             ).then((result) => {
    //                 closeEmailQuote();
    //             })
    //         }).catch(err => {
    //             setisSubmitted(false)
    //             Swal.fire(
    //                 'Error!',
    //                 'Internal server error.',
    //                 'error'
    //             ).then((result) => {
    //                 closeEmailQuote();
    //             })
    //         })

    //     return ''
    // } else {
    //     return ''
    // }
}
  //Added by David Jcasp dev. 14-Oct-2021 end

  //LIVE CHAT POP
  const liveChats = () => {
    if(chat){
        window.HubSpotConversations.widget.open();
        setChat(false)
    }else{
        window.HubSpotConversations.widget.close();
        setChat(true)
    }
  }

  return (
    <>
      <section className="contact-page address-section" data-aos="fade-up">
        <h1>Contact us</h1>
        <div className="row">
          <div className="col-md-6">
            <p>T: 01792 721162</p>
            <p>E: info@ces-limited.com</p>
            <p className="color-skyblue">Consumer Energy Solutions</p>
            <p>
              1-6 Pockets Wharf<br />
              Maritime Quarter<br />
              Swansea SA1 3XL<br />
              UK
            </p>
          </div>
          <div className="col-md-6">
            <GoogleMapReact
              bootstrapURLKeys={{ key: "AIzaSyAIa4XLiNxzo_pDZs0EJh6WAKryQaauqmE" }}
              defaultCenter={defaultProps.center}
              defaultZoom={defaultProps.zoom}
            >
              <AnyReactComponent
                lat={defaultProps.center.lat}
                lng={defaultProps.center.lng}
                text="My Marker"
              />
            </GoogleMapReact>
          </div>
        </div>
      </section>
      <section className="contact-page team-section full-width" data-aos="fade-up">
        <img src={teamImg} className="only-for-desktop" />
        <img src={teamImg} className="only-for-mobile" />
        <img src={liveChat} className="only-for-desktop" onClick={liveChats}/>
      </section>
      <section className="contact-page contact-form full-width" data-aos="fade-up">
        <div className="contact-form-wrapper">
          <img src={liveChat} className="only-for-mobile" />
          <h2 data-aos="fade-up">Make an enquiry</h2>
          <form onSubmit={contactFormSubmit} data-aos="fade-up">
            <div className="row">
              <div className="col-md-6">
                <div className="form-group">
                  <label className="control-label">Your name</label>
                  <input className="form-control" name="name" ref={NameContactName}/>
                  {
                    FormErrors['ContactName'] ? (
                    <span className="error">{FormErrors.ContactName}</span>
                    ) : ''
                  }
                </div>
                <div className="form-group">
                  <label className="control-label">Your number</label>
                  <input className="form-control" name="number" type="tel" ref={PhoneContactPhone}/>
                  {
                    FormErrors['ContactPhone'] ? (
                    <span className="error">{FormErrors.ContactPhone}</span>
                    ) : ''
                  }
                </div>
                <div className="form-group">
                  <label className="control-label">Your email</label>
                  <input className="form-control" name="email" type="email" ref={EmailContactEmail}/>
                  {
                    FormErrors['ContactEmail'] ? (
                    <span className="error">{FormErrors.ContactEmail}</span>
                    ) : ''
                  }
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label className="control-label">Your subject</label>
                  <input className="form-control" name="subject" type="text" ref={SubjectContactSubject}/>
                  {
                    FormErrors['ContactSubject'] ? (
                    <span className="error">{FormErrors.ContactSubject}</span>
                    ) : ''
                  }
                </div>
                <div className="form-group">
                  <label className="control-label">Your enquiry</label>
                  <textarea className="form-control" name="enquiry" resize="vertical" rows="4" ref={MessageContactMessage}></textarea>
                  {
                    FormErrors['ContactMessage'] ? (
                    <span className="error">{FormErrors.ContactMessage}</span>
                    ) : ''
                  }
                </div>
              </div>
              <div className="col-md-12 text-center">
                <button type="submit" className="contact-submit">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </section>
    </>
  )
}
export default Contactpage;
