import "./Boilerpage.css"
import boilerImg from "../../../assets/images/boilerpage/Boiler.png"
import boilerImgmob from "../../../assets/images/boilerpage/Boiler.png"
import greenIQ from "../../../assets/images/boilerpage/IQ-Background.png"
import boilerCombiImg from "../../../assets/images/boilerpage/Combi-diagram.png"
import systemBoilerImg from "../../../assets/images/boilerpage/System-diagram.png"
import regularBoilerImg from "../../../assets/images/boilerpage/Regular-diagram.png"
import magnaCleanImg from "../../../assets/images/boilerpage/Magnaclean.png"
import parterAdey from "../../../assets/images/boilerpage/Adey-logo.png"
import BigCaseStudy from '../../../assets/images/boilerpage/Big-case-study.png'
import Pro3monitoring from '../../../assets/images/boilerpage/Pro3-monitoring-pic.png'
import GetYourQuoteBtn from "../../components/get-your-quote-btn/get-your-quote-btn"
import LastBoiler from "../../../assets/images/boilerpage/Boiler-cta-background.png"

import Pdf from "../../../assets/pdf/Kilbourne Rd_Case_Study v2 no crops.pdf"
import { useHistory, Link } from "react-router-dom";
import aos from 'aos';
import { useEffect } from "react";

const Boilerpage = (params) => {

    useEffect(() => {
        aos.init({duration:2000})
    },[]);

    const history = useHistory();

    const startSurvey = () =>{
        console.log("heerhekrhkejrejr")
        history.push("/startsurvey");
    }

    const handletoPDF = function () {
       window.open(Pdf);
    }

    return (
        <>
            <section className="boilerpage boilers-section" data-aos="fade-up">
                <div className="row">
                    <div className="col-md-6">
                        <img src = {boilerImgmob} alt="" align="right" className="only-for-mobile"/>
                        <h1 data-aos="fade-up">Boilers</h1>
                        <p>
                            A new boiler is usually one of the more expensive purchases that you’ll make as a homeowner and rightly so. When the cold months start to draw in it’s what keeps us all warm, comfy and cosy. When you step out of that shower in December it’s what keeps us all from wondering why we’ve stepped out into the artic circle!</p>
                        <p>So it’s important to choose the right boiler, from the right people, and we’d like to think the right people would be us. At CES we’re here to help you every step of the way no matter if you’ve got a combi boiler, a system boiler or a regular boiler. We know we’ve got the perfect solution for you!
                        </p>
                    </div>
                    <div className="col-md-6 img-part">
                        <img src = {boilerImg} alt="" className="only-for-desktop"/>
                    </div>
                </div>
            </section>
            <section className="boilerpage greeniq-section full-width" data-aos="fade-up">
                {/* <img src={greenIQ} alt="" className="only-for-desktop"/> */}

                <div className="column pos-relative">
                    <h2 data-aos="fade-up">Focus on the <span>Green IQ</span></h2>
                    <p data-aos="fade-up">The ecoTEC Green iQ is the best in the business. It gives you the ultimate in energy efficiency, the lowest fuel consumption, lowest running costs and is unlike any other boiler on the market.</p>
                    <p data-aos="fade-up">Sometimes that can be hard to believe but click on the case study below and see how an actual family saved over £500 on their annual fuel bill.</p>
                    <ul data-aos="fade-up">
                        <li>ErP ‘A’ rated boiler with up to <strong>94% efficiency</strong>, helping to <strong>reduce your carbon footprint and save money on your fuel bills.</strong></li>
                        <li>Premium quality stainless steel heat exchanger for <strong>superior heat transfer</strong> and <strong>highly efficiency.</strong></li>
                        <li>Unique heat exchanger offers <strong>built-in heat recovery</strong> providing the highest efficiency boiler <strong>reducing bills even further.</strong></li>
                        <li>Fantastic <strong>hot water delivery</strong> of up to <strong>17.8 litres per minute,</strong> only heating the water you require.</li>
                        <li><strong>85% recyclable</strong> and automatically adjusts to any type of gas.</li>
                        <li>Quality German design and engineering which is manufactured at award-winning UK facilities.</li>
                        <li>Compatible with Vaillant’s vSMART™ app for <strong>heating on the go.</strong></li>
                    </ul>
                    <div className="only-for-mobile cursor-pointer" onClick={() => { handletoPDF() }}>
                        <img src={BigCaseStudy}/>
                    </div>
                    <div className="space_iq_center">
                        <GetYourQuoteBtn action={handletoPDF} text="View Case Study" bgColor="green" color="white"></GetYourQuoteBtn>
                    </div>
                    <div className="absolute_case_study_cercle only-for-desktop" onClick={() => { handletoPDF() }}>
                        <img src={BigCaseStudy}/>
                    </div>
                </div>
            </section>
            <section className="boilerpage boiler-combi" data-aos="fade-up">
                <h2 data-aos="fade-up">What boiler system is right for you?</h2>
                <div className="row">
                    <div className="col-md-6" data-aos="fade-up">
                        <img src={boilerCombiImg} alt=""/>
                    </div>
                    <div className="col-md-6" data-aos="fade-up">
                        <h2>Combi boilers</h2>
                        <p>
                        A combination or ‘combi’ boiler is an all in one water heater and acentral heating unit. With a combi boiler there is no requirement for hot water storage cylinders that system boilers need, or cold water storage tanks that regular boilers depend on.
                        </p>
                        <p>
                        Combi boilers directly heat water from the mains water supply, heating water on demand as the hot water tap gets turned on.  
                        </p>
                        <Link to="/startsurvey">
                            <GetYourQuoteBtn text="Get your quote" bgColor="skyblue" color="white"></GetYourQuoteBtn>
                        </Link>
                    </div>
                </div>
            </section>
            <section className="boilerpage system-boilers" data-aos="fade-up">
                <div className="row">
                    <div className="col-md-6" data-aos="fade-up">
                        <h2 data-aos="fade-up">System boilers</h2>
                        <p>
                        System boilers are perfect for homes with a high hot water consumption, usually due to requiring hot water in multiple bathrooms at the same time.
                        </p>
                        <p>
                        A separate cylinder storing hot water is able to provide a constant supply of hot water throughout the home, unlike combi boilers which can only heat water on demand.
                        </p>
                        <Link to="/startsurvey">
                            <GetYourQuoteBtn text="Get your quote" bgColor="white" color="var(--color-darkskyblue)"></GetYourQuoteBtn>
                        </Link>
                    </div>
                    <div className="col-md-6">
                        <img src={systemBoilerImg} alt="" data-aos="fade-up"/>
                    </div>
                </div>
            </section>
            <section className="boilerpage regular-boilers" data-aos="fade-up">
                <div className="row">
                    <div className="col-md-6">
                        <img src={regularBoilerImg} alt="" data-aos="fade-up"/>
                    </div>
                    <div className="col-md-6" data-aos="fade-up">
                        <h2>Regular boilers</h2>
                        <p>
                        If you live in a large home, have multiple bathrooms with a high hot water demand, then a regular or heat only boiler could be the system for you.
                        </p>
                        <p>
                        Regular boilers need a cold water storage tank in the loft to feed the hot water cylinder.
                        </p>
                        <Link to="/startsurvey">
                            <GetYourQuoteBtn text="Get your quote" bgColor="skyblue" color="white"></GetYourQuoteBtn>
                        </Link>
                    </div>
                </div>
            </section>
            <section className="boilerpage clean-section" data-aos="fade-up">
                <div className="row">
                    <div className="col-md-6" data-aos="fade-up">
                        <img src = {magnaCleanImg} alt="" align="right" className="only-for-mobile" data-aos="fade-up"/>
                        <h2>Keep it clean</h2>
                        <p>We supply and fit an industry leading Adey MagnaClean Pro3 magnetic filter with every single boiler we install.  </p>
                        <ul>
                            <li>Monitor high levels of harmful sludge in your home’s heating system tackling any potential problems before they even happen.</li>
                            <li>Which in turn increases the life of your heating system.</li>
                            <li>Helping to keep the system running efficiently.</li>
                            <li>Making sure the radiators warm up faster and lowering the risk of a breakdown.</li>
                        </ul>
                        <p className="partner-txt">Partnering with</p>
                        <img className="partner-img" src={parterAdey} alt=""/>
                    </div>
                    <div className="col-md-6 only-for-desktop">
                        <img src={magnaCleanImg} alt=""/>
                    </div>
                    <div className="col-md-12 text-center">
                        <Link to="/startsurvey">
                            <GetYourQuoteBtn text="Get your quote" bgColor="skyblue" color="white"></GetYourQuoteBtn>
                        </Link>
                    </div>
                </div>
            </section>
            <section className="boilerpage monitoring-section" data-aos="fade-up">
                <h2 data-aos="fade-up">24/7 monitoring</h2>
                <div className="row">
                    <div className="col-md-6" data-aos="fade-up">
                        <p>As a part of the Adey MagnaClean Pro3 we will monitor your heating system’s performance 24/7 ensuring any sludge probelms are nipped in the bud before they even happen. In addition if you go for the Vaillant ecoTEC Green IQ boiler, the most advanced boiler on the market, we can also monitor the actual boiler’s health through detailed performance data, fault code history and diagnostic insights. 
                        </p>

                        <ul>
                            <li>Immediate notification of a fault on the appliance.</li>
                            <li>Secure customer database, for quick maintenance.</li>
                            <li>Helping to keep the system running efficiently.</li>
                            <li>Live water pressure monitoring.</li>
                        </ul>
                    </div>
                    <div className="col-md-6">
                        {/* <img src={Pro3monitoring} /> */}
                    </div>
                    <div className="col-md-12 text-center">
                        <Link to="/startsurvey">
                            <GetYourQuoteBtn text="Get your quote" bgColor="skyblue" color="white"></GetYourQuoteBtn>
                        </Link>
                    </div>
                </div>
            </section>
            <section className="boilerpage smarttech-section">
                <h2 data-aos="fade-up">Smart technology</h2>
                <div className="row">
                    <div className="col-md-6" data-aos="fade-up">
                        <p>To complement your new ‘A’ grade boiler we offer a range of smart controls, giving you ease and flexibility at your fingertips.
                        </p>
                        <p>
                        Whether it’s a one or two zone or a multi zone system, we’ve got you covered with the Vaillant sensoCOMFORT or the sensoHOME controls.
                        </p>
                    </div>
                    <div className="col-md-6" data-aos="fade-up">
                        <ul>
                            <li>Weather compensating, supplied with outdoor sensors</li>
                            <li>Intuitive interface with energy dashboard, providing insights into your heating system performance and energy consumption</li>
                            <li>App controlled with the sensoNET</li>
                            <li>Time programme assistant</li>
                        </ul>
                    </div>
                    <div className="col-md-12 text-center">
                        <Link to="/startsurvey">
                            <GetYourQuoteBtn text="Get your quote" bgColor="skyblue" color="white"></GetYourQuoteBtn>
                        </Link>
                    </div>
                </div>
            </section>
            <section className="boilerpage get-quote text-center">
                {/* <img src={LastBoiler} alt=""/> */}
                <h2  data-aos="fade-up">Get an instant, fixed-price quote on your new boiler today</h2>
                <Link to="/startsurvey">
                    <GetYourQuoteBtn action={startSurvey} text="Get your quote" bgColor="skyblue" color="white"></GetYourQuoteBtn>
                </Link>
            </section>

        </>    
    )   
}

export default Boilerpage