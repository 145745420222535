import React,{useEffect} from 'react';
import "./cookies.css";
import aos from 'aos';

const Cookies = () => {

    // useEffect(() => {
    //     aos.init({duration:2000})
    // })

    return (
        <>
            <section className="cookies-section">
                <div className="row">
                    <div className="col-md-12"><h1>Cookie Policy</h1></div>
                </div>
            </section>
            <section className="cookies-second-section">
                <div className="row">
                    <div className="col-md-12 text">
                        <h2>What are cookies?</h2>
                        <p>Cookies are temporary files that automatically download to your phone, tablet or computer when you open a website that uses them.</p>
                        <p>They tell the website which page you're on, which page you've come from and what information you've put in.</p>
                        <p>Cookies help websites recognise you and we use them to remember what you told us about your old boiler and flue so we can show you the right boilers for your home.</p>
                        <p>And when you come to check out, we use them to remember your postcode and address details so we know where to send your boiler.</p>
                        <p>Cookies also help us remember you – even if you leave the site and come back. This means you don’t have to answer the ‘Find a boiler’ questions every time you visit – even if you close the browser window by accident.</p>
                        <p>Our TrustPilot cookie helps you access a whole host of Consumer Energy Solutions reviews to help you make the most informed decision. And our Live Chat uses a cookie so we can help if you get stuck. We also use a Hotjar Cookie that helps us understand how you've interacted with each page so we can optimise the website.</p>
                        <p>Cookies help us understand how our previous customers have used the site. That also helps us continue making improvements to our site so it performs better, faster and more smoothly than ever before.</p>
                        <p>Remember, you can choose not to accept cookies at any time, but remember doing so may mean some features of the website may not operate as intended.</p>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Cookies;
