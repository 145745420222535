import { useEffect } from "react";
import "./covidpolicy.css"
import aos from 'aos';

const Covidpolicy = () => {

    // useEffect(() => {
    //     aos.init({duration:2000})
    // },[])

    return (
        <>
            <section className="covid-section">
                <div className="row">
                    <div className="col-md-12"><h1>Consumer Energy Solutions (CES) Coronavirus Policy</h1></div>
                    <p>Right now, being comfortable and warm in your own home  has never been so important. We’re doing everything we can to keep you warm, reduce your carbon emissions and save you money – and that didn’t even stop for us throughout the Coronavirus pandemic.</p>
                </div>
            </section>
            <section className="cookies-second-section">
                <div className="row">
                    <div className="col-md-12 text">
                        <h2>We’re still here for you</h2>
                        <p>Because of the work we do in the ‘Funded’ sector the Government and Ofgem categorised the energy efficiency improvements we make to people’s home as essential work, setting expectations that the work needed to continue throughout the pandemic. This means throughout the upheaval of the last two years and all the lockdowns we faced we at CES never ever downed tools. We worked our socks off trying to keep our vulnerable customers from feeling the cold through the winter months. Our working practices had to be safe, and had to reflect the situation that we were all in. We feel Coronavirus precautionary measures are now second nature to us even during more recent times and the relaxing of the rules!</p>
                        <h2>Personal Protective Equipment (PPE)</h2>
                        <p>You’ve probably heard a lot about PPE in the last two years and this is something that our heating engineers will use. All of our installers wear gloves, shoe covers, masks and visors whilst working in our customers' property. This is for our customers' safety and the safety of our team. This will complement the other steps we will take to ensure “social distancing”, maintaining two metres of distance between people wherever possible.</p>
                        <h2>Social distancing: Vacating your property or a “Safe Zone” approach?</h2>
                        <p>You could vacate your property whilst we are working or alternatively, if that’s not practical, we’ll create “safe zones''.  That means that zones will be created in a property so the customer and our installers will not be in the same room, or even same floor at the same time. We’ll be able to explain more about this when you speak to our team.</p>
                        <h2>Monitoring & testing</h2>
                        <p>Because our operatives are carrying out essential work, they are constantly monitored for any symptoms of Covid-19 and are also routinely tested every 2 weeks.</p>
                        <br/>
                        <br/>
                        <br/>
                        <p>If you need our assistance during this challenging time our team is waiting for your enquiry, and are very happy to help.</p>
                        <p>We’ve got your back!</p>
                    </div>
                </div>
            </section>
        </>
    )

}

export default Covidpolicy;
