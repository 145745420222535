import trustpilot from "./../../../assets/images/Trust-Pilot-Rating.png";
import logo from "./../../../assets/images/ces-boiler-shop-logo-white.svg";
import menuIcon from "./../../../assets/images/menu-icon.svg";
import "./Navbar.css";
import { Link, Switch } from "react-router-dom";
import {useEffect} from "react"
import { useHistory } from "react-router-dom";

const Navbar = (path) => {
    const history = useHistory();
    const toggleMenu = function () {
        var menu = document.querySelector('.dropdown-menu');
        let glow = document.querySelector('.menu-link');

        if(menu.classList.contains('show')){
            menu.classList.remove('show')
            glow.classList.remove('glow');
        } else {
            menu.classList.add('show');
            // CHANGE DONE HERE FOR GLOW OF MENU ICON
            glow.classList.add('glow');
        }
    }
    
    const hidMenu =() => {
        var hidMenu = document.querySelector('.dropdown-menu');
            hidMenu.classList.remove('show')
    }

    const shwMenu = () => {
        var shwMenu = document.querySelector('.dropdown-menu');
            shwMenu.classList.add('show');
        // CHANGE DONE HERE FOR GLOW OF MENU ICON
        let glow = document.querySelector('.dropdown-menu');
        glow.classList.add('glow');
    }

    const movePage = function(page) {
        history.push("./"+page);
        var menu = document.querySelector('.dropdown-menu')
        menu.classList.remove('show')
    }

    return(
        <div id="navbar">
        <Switch>
        <div className={"navbar navbar-transparent flexbox flexbox-vr-centered flexbox-space-between "+ ((path.isHomepage == 1) ? '' : "not-homepage")}>
            <img className="nav-item trustpilot" src={trustpilot} alt="Trustpilot icon"/>
            <Link className="nav--link" to="/" exact><img className="nav-item logo" src={logo} alt="Website logo"/></Link>
            <div className="my_menu" >
                <div className="menu-link" onMouseOver={toggleMenu} onMouseLeave={hidMenu} onClick={toggleMenu}>
                    <img className="nav-item menu-icon" src={menuIcon} alt="Menu toggle icon"/>
                    MENU
                </div>

                <ul className="dropdown-menu" onMouseOver={shwMenu} onMouseLeave={hidMenu}>
                    <li><Link to="./aboutus" onClick={() => { movePage('aboutus') } }>About us</Link></li>
                    <li><Link to="./boilerpage" onClick={() => { movePage('boilerpage') } } >Boilers</Link></li>
                    <li><Link to="./contact" onClick={() => { movePage('contact') } }>Contact us</Link></li>
                </ul>
                
            </div>
            
        </div>
        </Switch>
        </div>
    )   
}


export default Navbar;