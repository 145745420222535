import { Link } from "react-router-dom"

import maskIcon from "./../../../assets/images/covid-safe-icon.svg"

//import "./Safeinstalltion.css"

const Safeinstalltion = (params) => {
    return (
        <>
            <img className="covid-icon" src={maskIcon} alt="" />
            <div className="safe-install">
                <p>COVID Safe installation</p>
                <Link to="covidpolicy">See our policy &gt;</Link>
            </div>
        </>
    )    
}

export default Safeinstalltion;
