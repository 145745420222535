import "./Homepage.css"
import fibreoptic from "./../../../assets/images/home-fibre-optic.jpg"
import getstartedicon from "./../../../assets/images/get-started-icon.svg"
import futureProofIcon from "./../../../assets/images/future-proof-icon.svg"
import systemCleanseIcon from "./../../../assets/images/system-cleanse-icon.svg"
import monitoringIcon from "./../../../assets/images/monitoring-tech-icon.svg"
import wirelessIcon from "./../../../assets/images/wireless-controls-icon.svg"
import fixedPriceIcon from "./../../../assets/images/fixed-price-icon.svg"
import spreadCostIcon from "./../../../assets/images/spread-cost-icon.svg"
import laptopImg1 from "./../../../assets/images/homepage/laptopImg1.png"
import laptopImg2 from "./../../../assets/images/homepage/laptopImg2.png"
import laptopImg3 from "./../../../assets/images/homepage/laptopImg3.png"
import boilerImg from "./../../../assets/images/best-boiler.svg"
import boilerImgMobile from "./../../../assets/images/homepage/best-boiler-mobile-svg.png"

import personImg from "./../../../assets/images/Lee-Harding-cutout.png"
import warrantyImg from "./../../../assets/images/10-year-warranty-seal.svg"
import bestPricingImg from "./../../../assets/images/best-pricing-mobile.png"
import flexiIcon1 from "./../../../assets/images/pay-in-full-icon.svg"
import flexiIcon2 from "./../../../assets/images/interest-free-icon.svg"
import flexiIcon3 from "./../../../assets/images/spread-cost-icon-blue.svg"
import smartTechImg from "./../../../assets/images/smart-tech-cutout.png"
import magnaCleanImg from "./../../../assets/images/magnaclean-filter.png"
import truspilotImg from "./../../../assets/images/CES-Trustpilot.png"
import truspilotLogo from "./../../../assets/images/Asset 5trustpilot.svg"
import savingSection from "./../../../assets/images/boiler-dark-extended.jpg"
// import iqImg from "./../../../assets/images/green-iq-close-up.jpg"
import topBackgoungAward from "./../../../assets/images/homepage/home-award_background.png"
import topAward from "./../../../assets/images/homepage/2.svg"
import topAwardTop from "./../../../assets/images/homepage/awardsvg.svg"
import topAwardTopios from "./../../../assets/images/homepage/awardsvg-ios.svg"

import GetYourQuoteBtn from "../../components/get-your-quote-btn/get-your-quote-btn"
import { Link } from "react-router-dom"
import TrustpilotSlider from "../../components/trustpilot-slider/TrustpilotSlider"
import Trustpilot from "../../components/trustpilot/Trustpilot"
import TrustpilotM from "../../components/trustpilot/TrustpilotM"
import Safeinstalltion from "../../components/safe-installation/Safeinstallation"
import PriceGuarantee from "../../components/price-guarantee/PriceGuarantee"
import { useHistory } from "react-router-dom";
import { useEffect, useState } from "react"
import aos from 'aos';
// import ReactPixel from 'react-facebook-pixel';
import 'aos/dist/aos.css';


const Homepage = () => {

    const [trust, setTrust] = useState("");
    const [chat, setChat] = useState(true);

    useEffect(() => {
        window.onload = () => {setTrust("https://uk.trustpilot.com/review/ces-limited.com");}
        aos.init({ duration: 1500});
    }, [])

    const history = useHistory();
    const handleFindmore = function () {
        history.push("./financialpage");
    }
    // HANDLE FUNCTION TO REDIRECT TO STARTSURVEY
    const handleQuote = () => {
        history.push("./startsurvey");
    }
    // HANDLE FUNCTION TO REDIRECT TO BOILER PAGE
    const handleToBoiler = () => {
        history.push("./boilerpage")
    }
    //LIVE CHAT POP
    const liveChat = () => {
        if(chat){
            window.HubSpotConversations.widget.open();
            setChat(false)
        }else{
            window.HubSpotConversations.widget.close();
            setChat(true)
        }
    }
    return (
        <>
            <section className="top-section homepage relative">
                {/* <div className="header kb-wrap">
                     <img src={fibreoptic} alt=""/> 
                </div> */}
                <div className="abosolute image-section show-desktop">
                    <img src={topAward} onClick={()=>{window.fbq("trackCustom","Award")}}/>
                </div>
                <div className="abosolute image-section show-mobile">
                    <div className="ios-only">
                        <img src={topAwardTopios} />
                    </div>
                    <div className="android-only">
                        <img src={topAwardTop} />
                    </div>
                    
                </div>
                <div className="abosolute text-section">
                    <div className="section-heading">Tomorrow’s heating today</div>
                    <span>Save up to <strong>£50 per month</strong>* on your heating bills with the market’s most efficient boiler,</span>
                    <div className="bold-heading">now available on finance</div>
                </div>
                <div className="abosolute button-section">
                    <div className="start-button">
                        <Link to="/startsurvey"><img src={getstartedicon} alt="" onClick={()=>{window.fbq('trackCustom', 'startButton')}}/></Link>
                    </div>
                    <br />
                    <span>Get started</span>
                    <div className="trustpilot-mobile">
                        <img src={truspilotLogo} alt=""/>
                    </div>
                </div>
            </section>
            <section className="saving-section">
                <div className="saving-benefits flexbox flexbox-space-between">
                    <div className="icon-div" data-aos="fade-up">
                        <img src={spreadCostIcon} alt="" />
                        <p>Spread the cost <br />with interest <br /> free credit</p>
                    </div>
                    
                    <div className="icon-div" data-aos="fade-up">
                        <img src={systemCleanseIcon} alt="" />
                        <p>System cleanse <br />& filter as<br /> standard</p>
                    </div>
                    <div className="icon-div" data-aos="fade-up">
                        <img src={monitoringIcon} alt="" />
                        <p>24/7 monitoring <br />technology</p>
                    </div>
                    <div className="icon-div" data-aos="fade-up">
                        <img src={wirelessIcon} alt="" />
                        <p>Wireless <br />controls as <br />standard</p>
                    </div>
                    <div className="icon-div" data-aos="fade-up">
                        <img src={fixedPriceIcon} alt="" />
                        <p>100% <br />fixed price</p>
                    </div>
                    <div className="icon-div" data-aos="fade-up">
                        <img src={futureProofIcon} alt="" />
                        <p>Future proof<br />technology</p>
                    </div>
                </div>
                <div className="savings-title" data-aos="fade-up">Click below and get the Vaillant Green IQ and save up to £500 off your heating bills.</div>
                <GetYourQuoteBtn action={ handleQuote } text="Get your quote" bgColor="skyblue" color="white"></GetYourQuoteBtn>
            </section>

            <section  className="saving-section-mobile">
                <img src={savingSection} alt="" data-aos="fade-up"/>
                <div className="saving-lower-part" data-aos="fade-up">
                    <div className="saving-benefits flexbox flexbox-space-between" data-aos="fade-up">
                        <div className="icon-div" data-aos="fade-up">
                            <img src={spreadCostIcon} alt="" />
                            <p>Spread <br />the cost</p>
                        </div>
                        <div className="icon-div" data-aos="fade-up">
                            <img src={systemCleanseIcon} alt="" />
                            <p>System cleanse <br />& filter as standard</p>
                        </div>
                        <div className="icon-div" data-aos="fade-up">
                            <img src={monitoringIcon} alt="" />
                            <p>24/7 monitoring <br />technology</p>
                        </div>
                        <div className="icon-div" data-aos="fade-up">
                            <img src={wirelessIcon} alt="" />
                            <p>Wireless <br />controls as standard</p>
                        </div>
                        <div className="icon-div" data-aos="fade-up">
                            <img src={fixedPriceIcon} alt="" />
                            <p>100% <br />fixed price</p>
                        </div>
                        <div className="icon-div" data-aos="fade-up">
                            <img src={futureProofIcon} alt="" />
                            <p>Future proof<br />technology</p>
                        </div>
                    </div>
                    <div className="savings-title" data-aos="fade-up">Click below and get the Vaillant Green IQ and save up to £500 off your heating bills.</div>
                    <GetYourQuoteBtn action={ handleQuote } text="Get your quote" bgColor="skyblue" color="white"></GetYourQuoteBtn>
                </div>
            </section>
            {/* <section className="trustpilot full-width till-desktop" data-aos="fade-up"> */}
                {/* <div className="row">
                    <div className="col-lg-2 col-md-3 col-sm-5 col-xs-5">
                        <img src={truspilotImg} alt="" />
                    </div>
                    <div className="col-lg-10 col-md-9 col-sm-7 col-xs-7">
                        <TrustpilotSlider></TrustpilotSlider>
                    </div>
                </div> */}
                {/* <!-- TrustBox widget - Carousel --> */}
                    {/* <div style={{padding:"4vw 0"}} class="trustpilot-widget" data-locale="en-GB" data-template-id="53aa8912dec7e10d38f59f36" data-businessunit-id="5ec25e93e43e9e000114f432" data-style-height="140px" data-style-width="100%" data-theme="light" data-stars="4,5" data-review-languages="en">
                    <a href="https://uk.trustpilot.com/review/ces-limited.com" target="_blank" rel="noopener">Trustpilot</a>
                    </div> */}
                {/* <!-- End TrustBox widget --> */}
            {/* </section> */}
            {/* <section style={{display:"Grid", placeItems:"center", display:"hidden"}} className="trustpilot full-width till-mobile" data-aos="fade-up" >

                    <div style={{padding:"4vw 0 0 0"}} class="trustpilot-widget" data-locale="en-GB" data-template-id="53aa8912dec7e10d38f59f36" data-businessunit-id="5ec25e93e43e9e000114f432" data-style-height="280px" data-style-width="100%" data-theme="light" data-stars="4,5" data-review-languages="en">
                    <a href="https://uk.trustpilot.com/review/ces-limited.com" target="_blank" rel="noopener">Trustpilot</a>
                    </div>

            </section> */}
            {/* ADDED BY DAVID 20-10-2021 START */}
            <div className="bg-d">
                <Trustpilot/>
                {/* <div style={{padding:"4vw 0"}} class="trustpilot-widget" data-locale="en-GB" data-template-id="53aa8912dec7e10d38f59f36" data-businessunit-id="5ec25e93e43e9e000114f432" data-style-height="140px" data-style-width="100%" data-theme="light" data-stars="4,5" data-review-languages="en">
                    <a href={trust} target="_blank" rel="noopener">Trustpilot</a>
                </div> */}
            </div>
            <div className="bg-m">
                <TrustpilotM/>
                {/* <div style={{padding:"6vw 0 0 0"}} class="trustpilot-widget" data-locale="en-GB" data-template-id="53aa8912dec7e10d38f59f36" data-businessunit-id="5ec25e93e43e9e000114f432" data-style-height="320px" data-style-width="100%" data-theme="light" data-stars="4,5" data-review-languages="en">
                    <a href={trust} target="_blank" rel="noopener">Trustpilot</a>
                </div> */}
            </div>
            {/* ADDED BY DAVID 20-10-2021 END */}
            <section className="how-it-works" data-aos="fade-up">
                <div className="savings-title text-center">How it works</div>
                <div className="saving-benefits flexbox flexbox-space-between">
                    <div className="laptop-div text-center">
                        <img src={laptopImg1} alt="" className="laptop-desk" />
                        <p className="text-center show-desktop">Answer a few <br />simple questions <br />about your home</p>
                        <p className="text-center show-mobile">Answer a few simple questions <br />about your home</p>
                    </div>
                    <div className="laptop-div text-center">
                        <img src={laptopImg2} alt="" className="laptop-desk"/>
                        <p className="text-center show-desktop">Select your <br />boiler and <br />install date</p>
                        <p className="text-center show-mobile">Select your boiler and install date</p>
                    </div>
                    <div className="laptop-div text-center">
                        <img src={laptopImg3} alt="" className="laptop-desk"/>
                        <p className="text-center show-desktop">Choose how to pay <br />– in full or spread <br />the cost</p>
                        <p className="text-center show-mobile">Choose how to pay – <br />in full or spread the cost</p>
                    </div>
                </div>
                <div className="spacer">
                <GetYourQuoteBtn action={ handleQuote } text="Get your quote" bgColor="skyblue" color="white" data-aos="fade-up"></GetYourQuoteBtn>
                </div>
            </section>
            <section className="best-fitting" >
                <div className="row">
                    <div className="boiler-image col-md-4">
                        <img className="show-desktop" src={boilerImg} alt="" data-aos="fade-up"/>
                        <img className="show-mobile" src={boilerImgMobile} alt=""/>
                    </div>
                    <div className="best-fit-wrap col-md-8" data-aos="fade-up">
                        <div className="best-fit-title" data-aos="fade-up">
                            <h1>Be sure you’re fitting the best</h1>
                        </div>
                        <div className="best-fit-content" data-aos="fade-up">
                            <p>As well as being the most energy efficient boiler on the market, the ecoTEC Green IQ is recyclable and hydrogen ready.</p>
                            <p className="text-green" data-aos="fade-up">Getting the Green IQ installed could save you a huge <span className="bold">£500</span> a year off your heating bills.</p>
                        </div>
                        <div className="best-fit-bottom row">
                            <div className="col-md-5" >
                                <GetYourQuoteBtn action={ handleToBoiler } text="Find out more" bgColor="green" color="white"></GetYourQuoteBtn>
                            </div>
                            <div className="col-md-7 flexbox">
                                <Safeinstalltion></Safeinstalltion>
                                {/* <img className="covid-icon" src={maskIcon} alt="" />
                                <div className="safe-install">
                                    <p>COVID Safe installation</p>
                                    <Link to="privacy">See our policy &gt;</Link>
                                </div> */}
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <PriceGuarantee className="homepage" data-aos="fade-up"/>
            {/* <section className="price-guarantee">
                <div className="row">
                    <div className="price-content col-md-5">
                        <div className="section-title">The CES culture</div>
                        <p>
                        What’s important to you is even more important to us!
                        </p>
                        <p>
                        No matter your age, location, income or shoe size we won’t charge you any more or any less. And, as many of our happy customers will confirm, we treat everyone to the same high standards of customer service. From our customer service staff right the way through to our installers<br />– we are all CES people.
                        </p>
                        <div className="price-button">
                        <GetYourQuoteBtn text="Get your quote" bgColor="skyblue" color="white"></GetYourQuoteBtn>
                        </div>
                    </div>
                    <div className="price-images col-md-7">
                        <img src={personImg} alt="" className="show-desktop"/>
                        <img src={bestPricingImg} alt="" className="show-mobile"/>
                        <img src={warrantyImg} alt="" className="show-desktop"/>
                    </div>
                </div>
            </section> */}
            <section className="flexible-ways" data-aos="fade-up">
                <div className="section-title text-center" data-aos="fade-up">Flexible ways to pay</div>
                <div className="row">
                    <div className="col-md-4 flexi-started">
                        <div className="flexi-icon text-center" data-aos="fade-up">
                            <img src={flexiIcon3} alt=""/>
                        </div>
                        <div className="flexi-text text-center" data-aos="fade-up">
                        Pay in monthly <br />instalments
                        </div>
                        <div className="flexi-button">
                            <GetYourQuoteBtn action={ handleFindmore } text="Find out more" bgColor="skyblue" color="white"></GetYourQuoteBtn>
                        </div>
                    </div>
                    <div className="col-md-4 flexi-started">
                        <div className="flexi-icon text-center" data-aos="fade-up">
                            <img src={flexiIcon2} alt=""/>
                        </div>
                        <div className="flexi-text text-center" data-aos="fade-up">
                        24 month <br />interest free
                        </div>
                        <div className="flexi-button">
                            <GetYourQuoteBtn action={ handleFindmore } text="Find out more" bgColor="skyblue" color="white"></GetYourQuoteBtn>
                        </div>
                    </div>
                    <div className="col-md-4 flexi-started">
                        <div className="flexi-icon text-center" data-aos="fade-up">
                            <img src={flexiIcon1} alt=""/>
                        </div>
                        <div className="flexi-text text-center" data-aos="fade-up">
                        Pay in full with a <br />one off payment
                        </div>
                        <div className="flexi-button">
                            <GetYourQuoteBtn action={ handleFindmore } text="Find out more" bgColor="skyblue" color="white"></GetYourQuoteBtn>
                        </div>
                    </div>
                </div>
                <div className="quote-btn show-mobile">
                    <GetYourQuoteBtn action={ handleQuote } text="Get your quote" bgColor="skyblue" color="white"></GetYourQuoteBtn>
                </div>
            </section>
            <section className="for-future" data-aos="fade-up">
                <div className="row">
                    <div className="col-md-6 show-desktop" data-aos="fade-up">&nbsp;</div>
                    <div className="col-md-6 future-content show-desktop" data-aos="fade-up">
                        <div className="section-title" data-aos="fade-up">Built for the future</div>
                        <p data-aos="fade-up">The most energy effcient and futureproof<sup style={{fontSize:"1.8vw"}}>†</sup> boiler available</p>
                        <GetYourQuoteBtn action={ handleToBoiler } text="Find out more" bgColor="skyblue" color="white"></GetYourQuoteBtn>
                    </div>
                    <div className="col-md-12 col-sm-12 show-mobile mobile-future-img">
                        {/* <img className="iq-img" src={iqImg} /> */}
                    </div>
                    <div className="col-md-12 col-sm-12 show-mobile future-content">
                        <div className="section-title" data-aos="fade-up">Built for the future</div>
                            <p data-aos="fade-up">The most energy effcient and futureproof<sup style={{fontSize:"3.8vw"}}>†</sup> boiler available</p>
                            <GetYourQuoteBtn action={ handleToBoiler } text="Find out more" bgColor="skyblue" color="white"></GetYourQuoteBtn>
                        </div>
                </div>
            </section>
            <section className="smart-tech chevron" data-aos="fade-up">
                <div className="row">
                    <div className="col-md-6 future-content" data-aos="fade-up">
                        <div className="section-title" data-aos="fade-up">Smart technology</div>
                        <p data-aos="fade-up">A range of apps and controls at your fingertips even when you’re not at home.</p>
                        <GetYourQuoteBtn action={ handleToBoiler } text="Find out more" bgColor="skyblue" color="white"></GetYourQuoteBtn>
                    </div>
                    <div className="col-md-6" data-aos="fade-up">
                        <img src={smartTechImg} alt="" />
                    </div>
                </div>
            </section>
            <section className="keep-it-clean chevron-reverse" data-aos="fade-up">
                <div className="row">
                    <div className="col-md-6" data-aos="fade-up">
                        <img src={magnaCleanImg} alt="" />
                    </div>
                    <div className="col-md-6 future-content" data-aos="fade-up">
                        <div className="section-title"data-aos="fade-up">Keep it clean</div>
                        <p data-aos="fade-up">We install an Adey MagnaClean filter to monitor your water quality, ensuring your radiators are nice and hot and your system works in top condition.</p>
                        <GetYourQuoteBtn action={ handleToBoiler } text="Find out more" bgColor="skyblue" color="white"></GetYourQuoteBtn>
                    </div>
                </div>
            </section>
            <section className="faq-section text-center" data-aos="fade-up">
                <div className="section-title">Any questions?</div>
                <p className="">We are all unique just like our various questions and queries. <br />Chat live below or contact us for further information</p>
                <div onClick={liveChat}>
                <GetYourQuoteBtn text="Chat with us" icon="chat" bgColor="skyblue" color="white"></GetYourQuoteBtn>
                </div>
            </section>
        </>
    )
}

export default Homepage;
