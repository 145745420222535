import React, {useEffect, useRef} from 'react';
const Trustpilot = () => {
  
  const ref = useRef(null);
  useEffect(() => {
    if (window.Trustpilot) {
    window.Trustpilot.loadFromElement(ref.current, true);
    }
  }, []);

  return (
  <div
    ref={ref} 
    className="trustpilot-widget" style={{padding:"4vw 0"}} data-locale="en-GB" data-template-id="53aa8912dec7e10d38f59f36" data-businessunit-id="5ec25e93e43e9e000114f432" data-style-height="140px" data-style-width="100%" data-theme="light" data-stars="4,5" data-review-languages="en">
    <a href="https://uk.trustpilot.com/review/ces-limited.com" target="_blank" rel="noopener">Trustpilot</a>
  </div>
    );
  };
export default Trustpilot;