import { FaRedo } from "react-icons/fa"
import { useEffect, useState } from "react"
import StepperComponent from "../../components/stepper/stepper"
import "./survey.css"
import GetYourQuoteBtn from "../../components/get-your-quote-btn/get-your-quote-btn"
import backIcon from "../../../assets/images/survey/Back-icon.png"
import chatIcon from "../../../assets/images/resultspage/Chat-icon.png"
import { useHistory } from 'react-router-dom';
import queryString from 'query-string'
import aos from "aos"
import axios from "axios"

const Survey = (params) => {
    const history = useHistory();
    let stepNo = localStorage.getItem('activeStep') ? localStorage.getItem('activeStep'): 0
    let [activeStep, changeStep] = useState(stepNo)

    let QueryData = queryString.parse(window.location.search);
    if('postcode' in QueryData){
        localStorage.setItem('postcode', QueryData.postcode)
    }
    
    const fetchData = async () => {
        const res = await fetch(`${process.env.REACT_APP_DOMAIN_URL}/q-files/warmzilla-questionnaire-real.json`)
        const json = await res.json()
        return json.all_questions
    }
    const [questions, setQuestions] = useState([])
    const [surveyTerminated, setSurveyTermination] = useState(true)
    const [prevQuestions, setPrevQuestions] = useState([])
    const [defaulQuestions, setDefaulQuestions] = useState([])
    const [attemptedQuestions, setAttemptedQuestions] = useState([])


    useEffect(() => {
        fetchData().then(response => {
            setDefaulQuestions(response);
            if(JSON.parse(localStorage.getItem('questions'))){
                setQuestions(JSON.parse(localStorage.getItem('questions')))
                console.log("Local Questions", JSON.parse(localStorage.getItem('questions')), questions);
                checkIfSubmitted(JSON.parse(localStorage.getItem('questions')))
            }else{
                setQuestions(response)
                console.log(questions, response);
                localStorage.setItem('questions', JSON.stringify(response));
            }
        });
        
        if(localStorage.getItem('surveyTerminated')){
            setSurveyTermination(JSON.parse(localStorage.getItem('surveyTerminated')))
        }else{
            localStorage.setItem('surveyTerminated', false)
            setSurveyTermination(false)
        }
        if(JSON.parse(localStorage.getItem('prevQuestions'))){
            setPrevQuestions(JSON.parse(localStorage.getItem('prevQuestions')))
        }
        if(JSON.parse(localStorage.getItem('attemptedQuestions'))){
            setAttemptedQuestions(JSON.parse(localStorage.getItem('attemptedQuestions')))
        }

        aos.init({duration:2000})
    }, [])
    const checkIfSubmitted = function(q){
        let isCurrQue = q.filter((question)=>{
            return (question.isCurrent == true)
        });
        console.log(q)
        let anyOptSelected = q[0].options.filter((option)=>{
            return (option.isSelected == true)
        })
        if(isCurrQue.length <= 0 && anyOptSelected.length > 0){
            history.push("./result");
        }
        // else{
        //     if(JSON.parse(localStorage.getItem('attemptedQuestions'))){
        //         let attmepQues = JSON.parse(localStorage.getItem('attemptedQuestions'));
        //         if(attmepQues.some(e => e.question_value == isCurrQue[0].question_value)){
        //             history.push("./result");
        //         }
        //     }
        // }
    }
    const optionHoverIn = function(e){
        // console.log("optionHoverIn", e);
        let img = e.target.querySelector("img");
        if(img){
            let icon = img.getAttribute("data-icon");
            let iconText = e.target.querySelector("p");
            img.setAttribute("src", `./grants/icons/${icon}-r.svg`)
            iconText.style.fontFamily = "'Museo 700'"
        }
    }
    const optionHoverOut = function(e){
        //console.log("optionHoverOut", e);
        let img = e.target.querySelector("img");
        if(img){
            let icon = img.getAttribute("data-icon");
            let iconText = e.target.querySelector("p");
            if(!e.target.classList.contains("selected")){
                img.setAttribute("src", `./grants/icons/${icon}.svg`)
            }
            iconText.style.fontFamily = "inherit"
        }
    }
    const selectOption = function(e){   
        let currQues = e.target.closest(".question-section").getAttribute("data-question");
        let selectedOption = e.target.getAttribute("data-value");
        let nextQue = e.target.getAttribute("data-next");
        if(JSON.parse(localStorage.getItem('prevQuestions'))){
            let p = JSON.parse(localStorage.getItem('prevQuestions'))
            p.push(currQues)
            setPrevQuestions(p)
            localStorage.setItem('prevQuestions', JSON.stringify(p));
        }else{
            let a = [];
            a.push(currQues)
            setPrevQuestions(JSON.stringify(a))
            localStorage.setItem('prevQuestions', JSON.stringify(a));
        }
        if(JSON.parse(e.target.getAttribute('data-t'))){
            localStorage.setItem('surveyTerminated', true)
            setSurveyTermination(true)
            return;
        }else{
            // Terminate flow if boiler type is standart or back boiler and user does not want to convert to combi boiler
            if(currQues == "boiler_type"){

                    if(["standard", "back_boiler"].includes(selectedOption)){ // Terminate flow if selected standart or back boiler in the boiler type and chooses not to convert to combi
                        let q3 = questions.map((question)=>{
                            if(question.question_value == "convert_to_combi"){
                                let opts = question.options.map((option) => {
                                    option.terminateFlow = false
                                    if(["no"].includes(option.value)){
                                        option.terminateFlow = true
                                    }
                                    return option
                                });
                                question.options = opts
                            }
                        })
                        setQuestions(q3);
                        localStorage.setItem('questions', JSON.stringify(q3));
                    }else if(["system"].includes(selectedOption)){ // Redirect to what best describe your home questoin if the boiler type is system and selected no to convert to combi
                        let q3 = questions.map((question)=>{
                            if(question.question_value == "convert_to_combi"){
                                let opts = question.options.map((option) => {
                                    option.terminateFlow = false
                                    if(["no"].includes(option.value)){
                                        option.nextQuestion = "home_description"
                                    }
                                    return option
                                });
                                question.options = opts
                            }
                        })
                        setQuestions(q3);
                        localStorage.setItem('questions', JSON.stringify(q3));
                    }
                    if(["standard", "back_boiler","system"].includes(selectedOption)){ 
                        let q3 = questions.map((question)=>{
                            if(question.question_value == "current_boiler_place"){ //Change the options of currenct boiler qestion based on the options selected earlier
                                let opts = question.options.filter((option) => {
                                    if(["utility_room", "kitchen", "garage", "airing_cupboard", "somewhere_else"].includes(option.value)){
                                        option.nextQuestion = "new_boiler_different_place"
                                        return option
                                    }
                                });
                                question.options = opts
                            }
                            if(question.question_value == "new_boiler_different_place"){ //Change the option text of the question
                                let opts = question.options.map((option) => {
                                    if("yes" == option.value){
                                        option.text = "Move somewhere else"
                                    }
                                    return option
                                });
                                question.options = opts
                            }
                            if(question.question_value == "new_boiler_place"){ //Show airing cupboard question for flow for the boiler type other than combi
                                let opts = question.options.map((option) => {
                                    if("airing_cupboard" == option.value){
                                        option.nextQuestion = "airing_cupboard"
                                    }else{
                                        option.nextQuestion = "home_description"
                                    }
                                    return option
                                });
                                question.options = opts
                            }
                        })
                        setQuestions(q3);
                        localStorage.setItem('questions', JSON.stringify(q3));
                    }else{
                        let q3 = questions.map((question)=>{
                            if(question.question_value == "current_boiler_place"){ 
                                let defaultCurrBoilerQues = defaulQuestions.filter((q) => {
                                    if(q.question_value == "current_boiler_place"){
                                        return q;
                                    }
                                })
                                if(defaultCurrBoilerQues.length > 0){
                                    question.options = defaultCurrBoilerQues[0].options
                                }
                            }
                            if(question.question_value == "new_boiler_different_place"){ //Change back to default
                                let defaultCurrBoilerQues = defaulQuestions.filter((q) => {
                                    if(q.question_value == "new_boiler_different_place"){
                                        return q;
                                    }
                                })
                                if(defaultCurrBoilerQues.length > 0){
                                    question.options = defaultCurrBoilerQues[0].options
                                }
                            }
                            if(question.question_value == "new_boiler_place"){ //Change back to default
                                let defaultCurrBoilerQues = defaulQuestions.filter((q) => {
                                    if(q.question_value == "new_boiler_place"){
                                        return q;
                                    }
                                })
                                if(defaultCurrBoilerQues.length > 0){
                                    question.options = defaultCurrBoilerQues[0].options
                                }
                            }
                            return question;
                        })
                        setQuestions(q3);
                        localStorage.setItem('questions', JSON.stringify(q3));
                    }
                //})
                
            }
        }
       // let questionStatus = 0;
        /*Remove dependent questions of old selection start*/
        let attempted = attemptedQuestions.filter((question) => {
            return (question.question_value == currQues)
        });
        console.log("attempted", attempted)
        if(attempted.length > 0){
            let oldSelected = attempted[0].options.filter((option) => {
                return (option.isSelected == true)
            })
            console.log("oldSelected", oldSelected)
            if(oldSelected.length > 0){
                oldSelected = oldSelected[0]
                if(oldSelected.value != selectedOption){
                    let dependentQues = oldSelected.dependendQues
                    console.log("dependentQues", dependentQues)
                    let q4 = questions.map((question) => {
                        if(dependentQues.includes(question.question_value)){
                            console.log("dependentQues question", question)
                            let o1 = question.options.map((option) => {
                                option.isSelected = false
                                return option
                            })
                            question.options = o1
                        }
                        return question
                    })
                    setQuestions(q4);
                    localStorage.setItem('questions', JSON.stringify(q4));

                }
            }
        }
        /*Remove dependent questions of old selection end*/
        let questions2 = questions.map((question, i)=>{
            //if(questionStatus > 0){
            if(question.question_value == nextQue){
                question.isCurrent = true;
                let step = parseInt(question.stepNo) - 1;
                changeStep(step);
                localStorage.setItem('activeStep', step)
              // questionStatus = 0;
            }else{
                question.isCurrent = false;
            }
            if(question.question_value == currQues){
                // if(queLen == i+1){
                //     question.isCurrent = true;
                //   //  questionStatus = 0;
                //     history.push("./result");
                // }
               // ++questionStatus;
                let opt = question.options.map((option)=>{
                    if(option.value == selectedOption){
                        option.isSelected = true
                    }else{
                        option.isSelected = false
                    }
                    return option;
                })
                question.options = opt;
            }
            return question
        });
        setQuestions(questions2);
        localStorage.setItem('questions', JSON.stringify(questions2));
        
        if(nextQue == null){
            console.log("NULL NEXT QUESTION");
            history.push("./result");
        }else{
            if(attemptedQuestions.some(e => e.question_value == nextQue) && !["current_boiler_place", "new_boiler_different_place", "new_boiler_place"].includes(nextQue)){
                console.log("REDIRECTED TO RESULT")
                history.push("./result");
            }
        }

        // let currQuestion = document.querySelector(".current-question")
        // let nextQuestion = currQuestion.nextSibling;
        // if(nextQuestion){
        //     changeStep(++activeStep);
        //     localStorage.setItem('activeStep', activeStep)
        // }
    }
    const goToPrevious = function(e){
        // let currQuestion = document.querySelector(".current-question")
        // if(currQuestion.previousSibling){
        //     changeStep(--activeStep);
        //     localStorage.setItem('activeStep', activeStep)

        //     let currLoc = 0
        //     let currQues = currQuestion.getAttribute("data-question")
        //     let questions2 = questions.map((question, i)=>{
        //         question.isCurrent = false;
        //         if(question.question_value == currQues){
        //             currLoc = i
        //         }
        //         return question
        //     });
        //     questions2[currLoc - 1].isCurrent = true
        //     setQuestions(questions2);
        //     localStorage.setItem('questions', JSON.stringify(questions2));
        // }
        console.log("prevQuestions", prevQuestions)
        if(prevQuestions.length > 0){
            let currQues = e.target.closest(".question-section").getAttribute("data-question");
            let q4 = questions.map((question) => {
                if(question.question_value == currQues){
                    question.options.map((option) => {
                        option.isSelected = false
                    })
                }
                if(question.question_value == prevQuestions[prevQuestions.length - 1]){
                    question.isCurrent = true
                    console.log("QUESTION", question)
                    let step = parseInt(question.stepNo) - 1;
                    changeStep(step);
                    localStorage.setItem('activeStep', step)
                }else{
                    question.isCurrent = false
                }
                return question
            })
            setQuestions(q4);
            localStorage.setItem('questions', JSON.stringify(q4));
            prevQuestions.pop();
            localStorage.setItem('prevQuestions', JSON.stringify(prevQuestions));
        }
    }
    const restartSurvey = function(e){
        localStorage.setItem('activeStep', 0)
        localStorage.setItem('surveyTerminated', false)
        localStorage.setItem('prevQuestions', JSON.stringify([]));
        localStorage.removeItem("attemptedQuestions")
        setSurveyTermination(false)
        fetchData().then(response => {
            setQuestions(response)
            localStorage.setItem('questions', JSON.stringify(response));
            history.push("./startsurvey");
        });
    }
    const backToSurvey = () => {
        setSurveyTermination(false);
        history.push("./survey");
    }
    if(surveyTerminated == true){
        return (
            <section className="survey stepper-info extrapage">
                <div className="row">
                    <h2 className="pl-section pt-heading">We’re going to need <br />some extra info. {surveyTerminated}</h2>

                    <div className="col-md-6 pl-section">
                        <p>Based on your answers so far we need to ask a few more questions to get you the very best solution.</p>
                        <p>
                            Call us on <span className="color-skyblue big_size">01792 721162</span> or:
                        </p>
                        <GetYourQuoteBtn icon="chat" text="Chat with us" bgColor="skyblue" color="white"></GetYourQuoteBtn>

                        <div className="col-md-12 survey-buttons text-left">
                            <div className="restart-survey" onClick={restartSurvey}><FaRedo /> Restart survey {surveyTerminated}</div>
                        </div>
                            {
                                // (prevQuestions.length > 0) ? 
                                <div className="row go-to-previous question" onClick={backToSurvey}>
                                    <img src={backIcon} alt=""/> Back to previous page
                                </div>
                                // : ""
                            }
                    </div>
                    <div className="col-md-6 mobile-margin-top-8">
                        <img src="./grants/extra-page-image.png" />
                    </div>
                    <div className="col-md-12">
                        <img src="./grants/extra-page-divider-image.png" className="full_image" />
                    </div>
                </div>
            </section>
        )
    }
    return (
        <>
            <section className="survey stepper-info">
                <div className="row">
                    <StepperComponent activeStep={activeStep} />
                    <div className="col-md-12 survey-buttons">
                        <div className="restart-survey" onClick={restartSurvey}><FaRedo /> Restart survey {surveyTerminated}</div>
                    </div>
                </div>
            </section>
            <div>
            {
                questions.map((question, index) => 
                    
                    <section key={question.question_value} data-question={question.question_value} className={"question-section text-center "+ ((question.isCurrent) ? "current-question cssanimation fadeInBottom" : "")}>
                        <h2>{question.question_text}</h2>
                        <div className="row">
                            {
                                question.options.map(option => 
                                    <div key={option.value} className={"col-md-3 option "+ ((option.isSelected) ? "selected": "")} onClick={selectOption} onMouseEnter={optionHoverIn} onMouseOut={optionHoverOut} data-t={option.terminateFlow} data-next={option.nextQuestion} data-value={option.value}>
                                        <img className="option-icon" data-icon={option.icon} src={ option.isSelected == false ? `./grants/icons/${option.icon}.svg` : `./grants/icons/${option.icon}-r.svg`}/>
                                        <p>{option.text}</p>
                                    </div>
                                ) 
                            }
                        </div>
                            {
                                (prevQuestions.length > 0) ? 
                                <div className="row go-to-previous" onClick={goToPrevious}>
                                    <img src={backIcon} alt=""/> Back to previous page
                                </div>
                                : ""
                            }
                    </section>
                )
            }
            </div>
            {/* <section className="question-section text-center">
                <h2>Where do you want your new boiler?</h2>
                <div className="row">
                    <div className="col-md-2">
                        <div className="iconDiv"></div>
                        <p>Same Place</p>
                    </div>
                    <div className="col-md-2">
                        <div className="iconDiv"></div>
                        <p>Utility room</p>
                    </div>
                    <div className="col-md-2">
                        <div className="iconDiv"></div>
                        <p>Kitchen</p>
                    </div>
                    <div className="col-md-2">
                        <div className="iconDiv"></div>
                        <p>Bedroom</p>
                    </div>
                    <div className="col-md-2">
                        <div className="iconDiv"></div>
                        <p>Garage</p>
                    </div>
                    <div className="col-md-2">
                        <div className="iconDiv"></div>
                        <p>Attic or loft</p>
                    </div>
                    <div className="col-md-2">
                        <div className="iconDiv"></div>
                        <p>Airing cupboard</p>
                    </div>
                    <div className="col-md-2">
                        <div className="iconDiv"></div>
                        <p>Somewhere else</p>
                    </div>
                </div>
            </section> */}
            <section className="faq-section text-center about-page" data-aos="fade-up">
                <div className="section-title">Any questions?</div>
                <p className="">We are all unique just like our various questions and queries. <br />Chat live below or contact us for further information</p>
                <GetYourQuoteBtn text="Chat with us" icon="chat"  bgColor="skyblue" color="white"></GetYourQuoteBtn>
            </section>
        </>
    )    
}
export default Survey
