import "./Resultpage.css";
import { FaRedo, FaSearch } from "react-icons/fa";

import GetYourQuoteBtn from "../../components/get-your-quote-btn/get-your-quote-btn";
import emailIcon from "../../../assets/images/resultspage/Email-icon.png";
import LoaderImage from "../../../assets/images/favloader.gif";
import chatIcon from "../../../assets/images/resultspage/Chat-icon.png";
import Safeinstalltion from "../../components/safe-installation/Safeinstallation";
import StepperComponent from "../../components/stepper/stepper";
import { useHistory } from "react-router-dom";
import { useState, useEffect, useRef } from "react";
import BoilerDetails from "../../components/boiler-detail/boiler-detail";
import Footer from '../../components/footer/Footer';
import InputRange from "react-input-range";
import { Button, Modal } from "react-bootstrap";
import validator from "validator";
import axios from "axios";
import Spinner from "react-bootstrap/Spinner";
import Swal from "sweetalert2";
import aos from "aos";
import CustomSpinnerComponent from "../../components/custom-spinner/custom-spinner";
import vaillantGreenIpBatch from "../../../assets/images/resultspage/flashBS.png";
import freeFlash from "../../../assets/images/resultspage/InterestFreeFlashBS.png";
// import vaillantGreenIpBatch from "../../../assets/images/resultspage/vaillant-green-iq-batch.png";


const Resultpage = (params) => {
  const history = useHistory();
  const [showEditQuestions, setShowEditQuestions] = useState(false);
  const [questions, setQuestions] = useState([]);
  const [availableGrants, setAvailableGrants] = useState([]);
  const [selectedGrant, setSelectedGrant] = useState({});
  const [isActive, setisActiveFinance] = useState(null);
  const [RangeFinance, setRangeFinance] = useState(50);
  const [showEmailQuote, setshowEmailQuote] = useState(false);
  const [EmailQuoteBoiler, setEmailQuoteBoiler] = useState("");
  const [EmailQuoteSubscribe, setEmailQuoteSubscribe] = useState(false);
  const [FormErrors, setFormErrors] = useState({});
  const [isSubmitted, setisSubmitted] = useState(false);
  const [currentStep, setcurrentStep] = useState(4);
  const [isLoading, setIsLoading] = useState(true);
  const [above, setAbove] = useState(false);
  const [isPlumeKit, setIsPlumeKit] = useState(false)
  const [isVerticalFlue, setIsVerticalFlue] = useState(false)
  const [isConvertBoiler, setIsConvertBoiler] = useState(false)
  const [isDifferentPlace, setIsDifferentPlace] = useState(false)
  const [isAdded , setIsAdded] = useState(false)
  const [apr, setApr] = useState(9.9);
  const [loanTerm, setLoanTerm] = useState(120);
  const [deferredTerm, setDeferredTerm] = useState();
  const [monthly, setMonthly] = useState();
  const [loan, setLoan] = useState();
  const [orderTotal, setOrderTotal] = useState();
  const [interest, setInterest] = useState();
  const [currentDepo, setCurrentDepo] = useState();
  const [chat, setChat] = useState(true);
  //to display arp and term
  const [aprValue, setAprValue] = useState(9.9);


  setTimeout(() => {
    setIsLoading(false);
  }, 2000);

  const EmailQuoteEmail = useRef();
  const EmailQuotePhone = useRef();

  const fetchGrants = async () => {
    const grantsData = await fetch(
      `${process.env.REACT_APP_DOMAIN_URL}/q-files/warmzilla-grants.json`
    ); //get all the grants
    const grantsDataJson = await grantsData.json();
    return grantsDataJson.all_grants;
  };
  useEffect(() => {
    if (JSON.parse(localStorage.getItem("questions"))) {
      let q = JSON.parse(localStorage.getItem("questions"));
      let questionAns = {};
      let ques = q.filter((question) => {
        let selectedOpt = question.options.some(
          (option) => option.isSelected == true
        );
        if (selectedOpt) {
          let v = question.question_value;
          questionAns[v] = question.options.filter((option) => {
            return option.isSelected;
          })[0].value;
          return question;
        }
      });
      setQuestions(ques);
      localStorage.setItem("attemptedQuestions", JSON.stringify(ques));
      console.log("Local Questions", ques);
      /*GRANT ELIGIBITY LOGICS START */
      let grantsDataJson = [];
      fetchGrants().then((response) => {
        grantsDataJson = response;
        let tempGrantList = [];

        if (questionAns.hasOwnProperty("boiler_type")) {
          if (questionAns.boiler_type == "combi") {
            if (
              questionAns.hasOwnProperty("bathtubs") &&
              ["2", "3+"].includes(questionAns.bathtubs)
            ) {
              let g1 = grantsDataJson.filter((grant) => {
                setAbove(true); //Added by David 20-10-2021
                return grant.key == "vaillant_843_43kw";
              });
              if (g1.length > 0) {
                tempGrantList.push(g1[0]);
              }
            } else if (
              questionAns.hasOwnProperty("bathtubs") &&
              ["0", "1"].includes(questionAns.bathtubs)
            ) {
              let g2 = grantsDataJson.filter((grant) => {
                return (
                  grant.key == "vaillant_835_35kw" ||
                  grant.key == "worcester_4000_30kw" ||
                  grant.key == "vaillant_828_28kw" ||
                  grant.key == "worcester_4000_25kw"
                );
              });
              if (g2.length > 0) {
                g2.forEach((gg) => {
                  tempGrantList.push(gg);
                });
              }
            }
          } else {
            if (
              questionAns.hasOwnProperty("convert_to_combi") &&
              questionAns.convert_to_combi == "no" &&
              questionAns.boiler_type == "system"
            ) {
              let g3 = grantsDataJson.filter((grant) => {
                return (
                  grant.key == "vaillant_627_27kw" ||
                  grant.key == "vaillant_630_30kw" ||
                  grant.key == "vaillant_618_18kw"
                );
              });
              if (g3.length > 0) {
                g3.forEach((gg) => {
                  tempGrantList.push(gg);
                });
              }
            } else if (
              questionAns.hasOwnProperty("convert_to_combi") &&
              questionAns.convert_to_combi == "yes"
            ) {
              if (
                questionAns.hasOwnProperty("bathtubs") &&
                ["2", "3+"].includes(questionAns.bathtubs)
              ) {
                let g1 = grantsDataJson.filter((grant) => {
                  return grant.key == "vaillant_843_43kw";
                });
                if (g1.length > 0) {
                  tempGrantList.push(g1[0]);
                }
              } else if (
                questionAns.hasOwnProperty("bathtubs") &&
                ["0", "1"].includes(questionAns.bathtubs)
              ) {
                let g2 = grantsDataJson.filter((grant) => {
                  return (
                    grant.key == "vaillant_835_35kw" ||
                    grant.key == "worcester_4000_30kw" ||
                    grant.key == "vaillant_828_28kw" ||
                    grant.key == "worcester_4000_25kw"
                  );
                });
                if (g2.length > 0) {
                  g2.forEach((gg) => {
                    tempGrantList.push(gg);
                  });
                }
              }
            }
          }
        }
        //Removing duplicate entries from the grants array
        tempGrantList = tempGrantList.filter((grant, index) => {
          grant.isSelected = false;
          const _thing = JSON.stringify(grant);
          return (
            index ===
            tempGrantList.findIndex((obj) => {
              return JSON.stringify(obj) === _thing;
            })
          );
        });

        for (let index = 0; index < tempGrantList.length; index++) {
          const gr = tempGrantList[index];
          
          if(isAdded == false){
              const ExtraCostQ = JSON.parse(localStorage.getItem('questions'));
              var isPlumeKit = false;
              var isVerticalFlue = false;
              var isConvertBoiler = false;
              var isDifferentPlace = false;

              ExtraCostQ.forEach((q) => {
                  if(q.question_value == 'flue_from_ground'){
                      var selectedOption = q.options.filter((option) => {
                          return (option.isSelected)
                      })
                      if(selectedOption.length > 0){
                          if(selectedOption[0].value == 'less_than_2'){
                            isPlumeKit = true;
                          }
                      }
                  }

                  if(q.question_value == 'flue_from_another_property'){
                      var selectedOption = q.options.filter((option) => {
                          return (option.isSelected)
                      })
                      if(selectedOption.length > 0){
                          if(selectedOption[0].value == 'less_than_1'){
                            isPlumeKit = true;
                          }
                      }
                  }

                  if(q.question_value == 'flue_from_window_door'){
                      var selectedOption = q.options.filter((option) => {
                          return (option.isSelected)
                      })
                      if(selectedOption.length > 0){
                          if(selectedOption[0].value == 'no'){
                            isPlumeKit = true ;
                          }
                      }
                  }

                  if(q.question_value == 'flue_under_structure'){
                      var selectedOption = q.options.filter((option) => {
                          return (option.isSelected)
                      })
                      if(selectedOption.length > 0){
                          if(selectedOption[0].value == 'yes'){
                            isPlumeKit = true ;
                          }
                      }
                  }

                  if(q.question_value == 'flue_out'){
                      var selectedOption = q.options.filter((option) => {
                          return (option.isSelected)
                      })
                      if(selectedOption.length > 0){
                          if(selectedOption[0].value == 'roof'){
                            isVerticalFlue = true;
                          }
                      }
                  }

                  if(q.question_value == 'convert_to_combi'){
                      var selectedOption = q.options.filter((option) => {
                          return (option.isSelected)
                      })
                      if(selectedOption.length > 0){
                          if(selectedOption[0].value == 'yes'){
                            isConvertBoiler = true;
                          }
                      }
                  }

                  if(q.question_value == 'new_boiler_different_place'){
                      var selectedOption = q.options.filter((option) => {
                          return (option.isSelected)
                      })
                      if(selectedOption.length > 0){
                          if(selectedOption[0].value == 'yes'){
                            isDifferentPlace = true;
                          }
                      }
                  }
              })

              // (isPlumeKit == true) ? (gr.total_price += 180) : (gr.total_price += 0)

              // (isVerticalFlue == true) ? (gr.total_price += 300) : (gr.total_price += 0)

              // (isConvertBoiler == true) ? (gr.total_price += 1020) : (gr.total_price += 0)

              // (isDifferentPlace == true) ? (gr.total_price += 1020) : (gr.total_price += 0)

              if(isPlumeKit == true){
                gr.total_price += 180;
              }

              if(isVerticalFlue == true){
                gr.total_price += 300;
              }

              if(isConvertBoiler == true){
                gr.total_price += 1020;
              }

              if(isDifferentPlace == true){
                gr.total_price += 1020;
              }
            tempGrantList[index] = gr;
            setIsAdded(true)
          }
        }

        setAvailableGrants(tempGrantList);

        
        console.log(
          "availableGrants",
          availableGrants,
          "tempGrantList",
          tempGrantList
        );
      });
      //Logics to show grants based on the answers

      /*GRANT ELIGIBITY LOGICS END */
    } else {
      history.push("./startsurvey");
    }

    setTimeout(function() {
      let cards = document.querySelectorAll(".boiler-card");
      cards.forEach((card) => {
        var el = card.querySelector("h2");
        var divHeight = el.offsetHeight;
        var lineHeight = parseInt(
          document.defaultView
            .getComputedStyle(el, null)
            .getPropertyValue("line-height")
        );
        var lines = divHeight / lineHeight;
        if (lines <= 1) {
          card.classList.add("one-line-title");
        }
      });
    }, 100);

    //Added by David@JCasp 28-10-2021 for default value finance option start
    // var resultDefault = new window.VendigoCalculatorWidget().calculate(2000, 400, 9.9, 36, 0);
    var resultDefault = new window.VendigoCalculatorWidget().calculate(2000, 400, aprValue, loanTerm, 0);
    setMonthly(resultDefault.monthlyPayment);
    setLoan(resultDefault.loanAmount);
    setOrderTotal(resultDefault.totalRepayment);
    setInterest(resultDefault.costOfFinance);
    //Added by David@JCasp 28-10-2021 for default value finance option end

    aos.init({ duration: 2000 });
  }, []);

  //Added by david@JCasp 28-10-2021 to display loan amount to client start
  const calLoan = (result) => {
    setMonthly(result.monthlyPayment);
    setLoan(result.loanAmount);
    setOrderTotal(result.totalRepayment);
    setInterest(result.costOfFinance);
  }
  //Added by david@JCasp 28-10-2021 to display loan amount to client end


  const fetchData = async () => {
    const res = await fetch(
      `${process.env.REACT_APP_DOMAIN_URL}/q-files/warmzilla-questionnaire-real.json`
    );
    const json = await res.json();
    return json.all_questions;
  };
  const restartSurvey = function(e) {
    localStorage.setItem("activeStep", 0);
    localStorage.setItem("surveyTerminated", false);
    localStorage.setItem("prevQuestions", JSON.stringify([]));
    localStorage.removeItem("attemptedQuestions");
    fetchData().then((response) => {
      localStorage.setItem("questions", JSON.stringify(response));
      history.push("./startsurvey");
    });
  };
  const showHideEditQuestions = function() {
    let btn = document.querySelector(".review-edit");
    if (showEditQuestions) {
      btn.classList.remove("active");
      setShowEditQuestions(false);
    } else {
      btn.classList.add("active");
      setShowEditQuestions(true);
    }
  };
  const editAnswer = function(i) {
    let toBeEdited = questions[i];
    let qq = JSON.parse(localStorage.getItem("questions"));
    let q1 = qq.map((question) => {
      if (question.question_value == toBeEdited.question_value) {
        question.isCurrent = true;
      } else {
        question.isCurrent = false;
      }
      return question;
    });
    let step = parseInt(toBeEdited.stepNo) - 1;
    localStorage.setItem("questions", JSON.stringify(q1));
    localStorage.setItem("activeStep", step);
    history.push("./survey");
  };
  const selectGrant = function(index) {
    setcurrentStep(5);
    setSelectedGrant(availableGrants[index]);
    // window.scrollTo(0, 0);
    // document.getElementById("divFirst").scrollIntoView();
  };

  const handleFinance = function(id, grantTotal) {
    let financeOptions = document.querySelector("#finance-" + id);
    let boilerCard = financeOptions.parentNode.parentNode.parentNode;

    //Added by David@Jcasp 29-10-2021 for default value start
    // let result = new window.VendigoCalculatorWidget().calculate(grantTotal, 80, 9.9, 36, 0);
    let result = new window.VendigoCalculatorWidget().calculate(grantTotal, Math.floor(grantTotal/2), aprValue, loanTerm, 0);
    calLoan(result);
    //Added by David@Jcasp 29-10-2021 for default value start

    if (isActive === id) {
      setisActiveFinance(null);
      boilerCard.querySelector(".row").style.paddingBottom = `4vw`;
    } else {
      setisActiveFinance(id);
      setTimeout(function() {
        let bh = (boilerCard.offsetHeight * 75) / 100;
        let style = window.getComputedStyle(boilerCard.querySelector(".row"));
        let v = style.getPropertyValue("padding-bottom");
        console.log(financeOptions.offsetHeight, boilerCard.offsetHeight, bh);
        if (financeOptions.offsetHeight > bh) {
          boilerCard.querySelector(
            ".row"            
          ).style.paddingBottom = `calc(${v} + ${financeOptions.offsetHeight -
            bh * 0.85}px)`;
        } else {
          boilerCard.querySelector(".row").style.paddingBottom = `calc(${v})`;
        }
      }, 10);
      // if()
    }
  };

  const closeFinance = function(i) {
    setisActiveFinance(null);
    let financeOptions = document.querySelector("#finance-" + i);
    let boilerCard = financeOptions.parentNode.parentNode.parentNode;
    boilerCard.querySelector(".row").style.paddingBottom = `4vw`;
  };

  //added by David@JCasp 28-10-2021 value change on deposite change start
  // const handleRangeChange = function(val, totalGrant) {
  //   setRangeFinance(val.target.value);
  //   let depositPercent = val.target.value;
  //   let depositAmount = Math.floor((depositAmount/100)*totalGrant);
  //   let result = new window.VendigoCalculatorWidget().calculate(totalGrant, depositAmount, aprValue, loanTerm, 0);
  //   calLoan(result);
  // };
  //added by David@JCasp 28-10-2021 value change on deposite change start

  //added by David@Jcasp 22-11-2021 value change on select option of apr start
  // const handleOptionAPR = (e, totalGrant) => {
  //   setAprValue(parseFloat(e.target.value));
  //   let depositeAmount = parseFloat(e.target.value);
  //   let depositePercent = Math.floor((depositeAmount/100)*totalGrant);
  //   let result = new window.VendigoCalculatorWidget().calculate(totalGrant, depositePercent, aprValue, loanTerm, 0);
  //   calLoan(result);
  // }
  // const handleOptionLoan = (e, totalGrant) => {
  //   setLoanTerm(parseFloat(e.target.value));
  //   let depositeAmount = parseFloat(e.target.value);
  //   let depositePercent = Math.floor((depositeAmount/100)*totalGrant);
  //   let result = new window.VendigoCalculatorWidget().calculate(totalGrant, depositePercent, aprValue, loanTerm, 0);
  //   calLoan(result);
  // }

  const calculationForAll = (e, totalGrant, option) => {
    let percentage;
    let apr;
    let term;
    if(percentage === undefined){
      percentage = parseInt(RangeFinance);
    }
    if(apr === undefined){
      apr = parseFloat(aprValue);
    }
    if(term === undefined){
      term = parseInt(loanTerm);
    }
    if(option === "range"){
      setRangeFinance(parseInt(e.target.value));
      percentage = parseInt(e.target.value);
    }
    if(option === "apr"){
      apr = parseFloat(e.target.value);
      setAprValue(parseFloat(e.target.value));
    }
    if(option === "term"){
      term = parseInt(e.target.value);
      setLoanTerm(parseInt(e.target.value));
    }
    console.log("percentage", percentage);
    console.log("apr", apr);
    console.log("term", term);
    // let depositPercent = parseFloat(e.target.value);
    let depositAmount= Math.floor((percentage/100)*totalGrant);
    let result = new window.VendigoCalculatorWidget().calculate(totalGrant, depositAmount, apr, term, 0);
    calLoan(result);
  }
  //added by David@Jcasp 22-11-2021 value change on select option of apr end

  const handleEmailQuote = function(grant_name) {
    setEmailQuoteBoiler(grant_name);
    setshowEmailQuote(true);
  };

  const closeEmailQuote = function() {
    setshowEmailQuote(false);
    setEmailQuoteBoiler("");
    setEmailQuoteSubscribe(false);
    setFormErrors({});
    EmailQuoteEmail.current.value = "";
    EmailQuotePhone.current.value = "";
  };

  const submitEmailQuote = function() {
    if (EmailQuoteEmail.current.value == "") {
      FormErrors.QuoteEmail = "Please enter email.";
    } else if (!validator.isEmail(EmailQuoteEmail.current.value)) {
      FormErrors.QuoteEmail = "Please enter valid email.";
    } else {
      delete FormErrors.QuoteEmail;
    }

    if (EmailQuotePhone.current.value == "") {
      FormErrors.QuotePhone = "Please enter phone number.";
    } else if (!validator.isMobilePhone(EmailQuotePhone.current.value)) {
      FormErrors.QuotePhone = "Please enter valid phone number.";
    } else {
      delete FormErrors.QuotePhone;
    }

    if (EmailQuotePhone.current.value == "") {
      FormErrors.QuotePhone = "Please enter phone number.";
    } else if (!validator.isMobilePhone(EmailQuotePhone.current.value)) {
      FormErrors.QuotePhone = "Please enter valid phone number.";
    } else {
      delete FormErrors.QuotePhone;
    }

    setFormErrors({ ...FormErrors });

    if (Object.keys(FormErrors).length === 0) {
      let questions = localStorage.getItem("questions")
        ? localStorage.getItem("questions")
        : [];
      questions = JSON.parse(questions);

      let selectedQueation = [];

      for (let index = 0; index < questions.length; index++) {
        const q = questions[index];
        var selectedOption = q.options.filter(
          (option) => option.isSelected == true
        );

        let so = null;
        if (selectedOption.length > 0) {
          so = selectedOption[0].value;
        }
        selectedQueation.push({
          question_value: q.question_value,
          selectedOption: so,
        });
      }

      var QuoteRequests = {
        email: EmailQuoteEmail.current.value,
        boiler_key: EmailQuoteBoiler,
        telephone: EmailQuotePhone.current.value,
        subscribe: EmailQuoteSubscribe,
        questions: selectedQueation,
      };
      var config = {
        method: "post",
        url: `${process.env.REACT_APP_API_URL}/quote/sent-email`,
        headers: {
          "Content-Type": "application/json",
        },
        data: QuoteRequests,
      };
      setisSubmitted(true);
      axios(config)
        .then((res) => {
          setisSubmitted(false);
          Swal.fire(
            "Success!",
            "Quote submitted successfully.",
            "success"
          ).then((result) => {
            closeEmailQuote();
          });
        })
        .catch((err) => {
          setisSubmitted(false);
          Swal.fire("Error!", "Internal server error.", "error").then(
            (result) => {
              closeEmailQuote();
            }
          );
        });

      return "";
    } else {
      return "";
    }
  };

  const handleSunscribe = function() {
    var newSubscribe = EmailQuoteSubscribe == false ? true : false;
    setEmailQuoteSubscribe(newSubscribe);
  };

  //LIVE CHAT POP
  const liveChat = () => {
    if(chat){
        window.HubSpotConversations.widget.open();
        setChat(false)
    }else{
        window.HubSpotConversations.widget.close();
        setChat(true)
    }
  }
  // const totalPrice = (data) => {
  //   return setTotalGrant(data);
  // }
  // console.log("totalPrice from parent", totalGrant);

  return (
    <>
      {isLoading === false ? (
        <>
          <section className="result stepper-info">
            <div className="row">
              <StepperComponent activeStep={currentStep} />
              <div className="col-md-12 survey-buttons">
                <div className="restart-survey" onClick={restartSurvey}>
                  <FaRedo /> Restart survey
                </div>
                <div className="review-edit" onClick={showHideEditQuestions}>
                  <FaSearch /> Review / edit answers
                </div>
              </div>
              {showEditQuestions ? (
                <div className="row edit-questions">
                  {questions.map((question, index) => (
                    <div className="col-md-6">
                      <div className="row">
                        <div className="col-md-7">{question.question_text}</div>
                        <div className="col-md-3">
                          {
                            question.options.filter((option) => {
                              return option.isSelected;
                            })[0].text
                          }
                        </div>
                        <div
                          className="col-md-2 edit-btn text-center"
                          onClick={() => editAnswer(index)}
                        >
                          <FaSearch /> Edit
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              ) : (
                ""
              )}
            </div>
          </section>
          {availableGrants.length > 0 ? (
            <section className="recommended-boiler">
              {selectedGrant &&
              Object.keys(selectedGrant).length > 0 &&
              selectedGrant.constructor === Object ? (
                /*AFTER GRANT SELECTION */
                <BoilerDetails boiler={selectedGrant} scroll="installDate"/>
              ) : (
                <>
                  <h1>
                    <span style={{ color: "var(--color-green)" }}>
                      Great news!
                    </span>{" "}
                    We’ve found your new boiler…
                  </h1>
                  {availableGrants.map((grant, index) => (
                    // <h1>{index+1}</h1>
                    <div
                      className={
                        "boiler-card " + (index == 0 ? "first-boiler" : "")
                      }
                    >
                      <div className="row">
                        <div className="recommentNumber">
                          <div className="number">
                            <span>{index+1}</span>
                          </div>
                        </div>
                        <div
                          className={
                            "col-md-3 " +
                            (grant.best_buy_image || grant.special_offer_image
                              ? "has-best-buy"
                              : "")
                          }
                        >
                          <img
                            className="grant-img"
                            src={"./grants/" + grant.boiler_image}
                            alt=""
                          />
                          {grant.best_buy_image ? (
                            <img
                              className="best_buy_logo"
                              src={"./grants/" + grant.best_buy_image}
                              alt=""
                            />
                          ) : (
                            ""
                          )}

                          {grant.special_offer_image ? (
                            <img
                              className="special_offer_logo"
                              src={"./grants/" + grant.special_offer_image}
                              alt=""
                            />
                          ) : (
                            ""
                          )}
                          {grant.interest_free ? (
                            <img
                              className="interest_free_logo"
                              src={freeFlash}
                              alt=""
                            />
                          ) : (
                            ""
                          )}
                        </div>
                        <div className="col-md-9 pos-relative">
                          <h2>{grant.name}</h2>
                          <p>{grant.description}</p>
                          <div className="grey-card">
                            <div className="grey-title">
                              Fixed price including installation:
                            </div>
                            <div className="grey-subtitle">
                              <span>
                                {grant.currency == "GBP" ? "£" : grant.currency}
                                {(grant.total_price) //base_price
                                  .toString()
                                  .replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                              </span>{" "}
                              inc VAT or
                              <br />
                              <span>
                                {grant.currency == "GBP" ? "£" : grant.currency}
                                {grant.base_price_per_month}
                              </span>{" "}
                              per month ({grant.percent_rate}% APR)*
                              {(grant.discount_offer)?<img src={vaillantGreenIpBatch} className="vaillantGreenIpBatch" alt=""/>:""}  
                              {/* <img src={vaillantGreenIpBatch} className="vaillantGreenIpBatch" alt=""/> */}
                            </div>
                            <div className="grey-card-lower">
                              <div className="text-part">
                                <div className="email-quote">
                                  <div onClick={() => { handleEmailQuote(grant.key); }}>
                                    <img src={emailIcon} alt="" />
                                    Email your quote
                                  </div>
                                  <div className="cwu">
                                    <img src={chatIcon} alt="" />
                                    Chat with us 24/7
                                  </div>
                                </div>
                                {/* <div className="row">
                                  <GetYourQuoteBtn
                                    action={() => {
                                      handleFinance(index);
                                    }}
                                    text="Finance options"
                                    bgColor="skyblue"
                                    color="white"
                                  />
                                  <GetYourQuoteBtn
                                    text="More info."
                                    bgColor="green"
                                    action={() => {
                                      selectGrant(index);
                                    }}
                                    color="white"
                                  />
                                </div> */}
                                {/* <div className="email-quote">
                                  <div
                                    onClick={() => {
                                      handleEmailQuote(grant.key);
                                    }}
                                  >
                                    <img src={emailIcon} alt="" />
                                    Email your quote
                                  </div>
                                  <div className="cwu">
                                    <img src={chatIcon} alt="" />
                                    Chat with us 24/7
                                  </div>
                                </div> */}
                              </div>
                              <div className="icon-part">
                                <img src={"./grants/" + grant.warranty_image} />
                              </div>
                            </div>
                            <div className="row">
                                  <GetYourQuoteBtn
                                    action={() => {
                                      handleFinance(index, grant.total_price);
                                    }}
                                    text="Finance options"
                                    bgColor="skyblue"
                                    color="white"
                                  />
                                  <GetYourQuoteBtn
                                    text="More info."
                                    bgColor="green"
                                    action={() => {
                                      selectGrant(index);
                                    }}
                                    color="white"
                                  />
                                </div>
                          </div>
                          <div className="safe-installtion flexbox best-fit-bottom">
                            <Safeinstalltion />
                          </div>
                          <div
                            id={"finance-" + index}
                            className={
                              isActive === index
                                ? "pos-absolute show"
                                : "pos-absolute"
                            }
                          >
                            <div className="finance-section">
                              <div
                                className="finance-close-icon"
                                onClick={() => closeFinance(index)}
                              >
                                X
                              </div>
                              <div className="finance-box">
                                <h1>Pay Monthly</h1>
                                <p>
                                  Please check that you have selected the
                                  correct Finance Plan with Payment Term before
                                  proceeding.
                                </p>
                                <form>
                                  <div className="form-group">
                                    <label className="control-label">
                                      Finance Plan
                                    </label>
                                    <select className="dropdown" onChange ={e => calculationForAll(e, grant.total_price, "apr")}>
                                        {
                                          (grant.interest_free)?(
                                              <>
                                                  <option value="9.9" selected>9.9% APR</option>
                                                  <option value="0.0">0.0% APR</option>
                                              </>
                                            ):(
                                              <><option value="9.9" selected>9.9% APR</option></>)
                                        }
                                    </select>
                                  </div>

                                  <div className="form-group">
                                    <label className="control-label">
                                      Choose Payment Term
                                    </label>
                                    <select className="dropdown" onChange ={e => calculationForAll(e, grant.total_price, "term")}>
                                    {
                                        grant.interest_free?(
                                            (aprValue === 0.0)?(
                                                <>
                                                    <option value="12">1 Years (12 Months)</option>
                                                    <option value="24" selected>2 Years (24 Months)</option>
                                                </>
                                            ):(
                                                <>
                                                    {/* <option>3 Years (36 Months)</option>
                                                    <option>4 Years (48 Months)</option>
                                                    <option>5 Years (60 Months)</option> */}
                                                    <option value="36">3 Years (36 Months)</option>
                                                    <option value="48">4 Years (48 Months)</option>
                                                    <option value="60">5 Years (60 Months)</option>
                                                    <option value="84">7 Years (84 Months)</option>
                                                    <option value="120" selected>10 Years (120 Months)</option>
                                                </>
                                            )  
                                        ):(
                                            <>
                                                <option value="36">3 Years (36 Months)</option>
                                                <option value="48">4 Years (48 Months)</option>
                                                <option value="60">5 Years (60 Months)</option>
                                                <option value="84">7 Years (84 Months)</option>
                                                <option value="120" selected>10 Years (120 Months)</option>
                                            </>
                                        )
                                          
                                      }
                                    </select>
                                  </div>

                                  {/* <div className="form-group">
                                    <label className="control-label">
                                      Choose Deposit £130.00{" "}
                                    </label>
                                    <input
                                      className="range-slider"
                                      type="range"
                                      min="0"
                                      max="100"
                                      step="1"
                                      value={RangeFinance}
                                      onChange={() => {
                                        handleRangeChange();
                                      }}
                                    />
                                  </div> */}
                                  {/* ADDED BY DAVID TO TEST THE CALCULATION 27 OCT 2021 START */}
                                  <div className="form-group">
                                    <label className="control-label">
                                      {/* Choose Deposit £130.00{" "} */}
                                      Choose Deposit {`£${(Math.floor((RangeFinance/100)*grant.total_price)).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} (${RangeFinance}%)`}
                                    </label>
                                    <input
                                      className="range-slider"
                                      type="range"
                                      min="0"
                                      max="50"
                                      step="1"
                                      value={RangeFinance}
                                      // onChange={(e) => handleRangeChange(e, grant.total_price)}
                                      onChange={(e) => calculationForAll(e, grant.total_price, "range")}
                                    />
                                  </div>
                                  {/* ADDED BY DAVID TO TEST THE CALCULATION 27 OCT 2021 END */}


                                  <div className="form-group info">
                                    <p>
                                      <b>Monthly Payment: </b>{" "}
                                      <span className="text-right">{(monthly).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                    </p>
                                    <p>
                                      <b>Interest: </b>{" "}
                                      <span className="text-right">{(interest).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}</span>
                                    </p>
                                    <p>
                                      <b>Loan Amount:</b>{" "}
                                      <span className="text-right">
                                        {(loan).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                      </span>
                                    </p>
                                    <p>
                                      <b>Order Total:</b>{" "}
                                      <span className="text-right">
                                        {(orderTotal).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")}
                                      </span>
                                    </p>
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>

                        <Modal show={showEmailQuote}>
                          <Modal.Header closeButton onClick={closeEmailQuote}>
                            <Modal.Title>Enter your quote</Modal.Title>
                          </Modal.Header>
                          <Modal.Body>
                            <p>
                              Enter your email address and mobile phone number
                              in the boxes below so we can send this quote to
                              you.
                            </p>
                            <form>
                              <div className="form-group">
                                <label className="control-label black-label">
                                  {" "}
                                  Email <span className="color-skyblue">*</span>
                                </label>
                                <input
                                  type="text"
                                  placeholder="youremail@mail.com"
                                  ref={EmailQuoteEmail}
                                />
                                {FormErrors["QuoteEmail"] ? (
                                  <span className="error">
                                    {FormErrors.QuoteEmail}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="form-group mt-4">
                                <label className="control-label black-label">
                                  {" "}
                                  Phone number{" "}
                                  <span className="color-skyblue">*</span>
                                </label>
                                <input
                                  type="text"
                                  placeholder="e.g. 07790225642"
                                  ref={EmailQuotePhone}
                                />
                                {FormErrors["QuotePhone"] ? (
                                  <span className="error">
                                    {FormErrors.QuotePhone}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>

                              <div className="form-group mt-4">
                                <label className="box my_subscribe">
                                  I am happy to receive a fixed price quote from
                                  CES Boiler Shop
                                  <input
                                    type="checkbox"
                                    onChange={handleSunscribe}
                                  />
                                  <span className="mark" />
                                </label>
                                {FormErrors["subscribe"] ? (
                                  <span className="error">
                                    {FormErrors.subscribe}
                                  </span>
                                ) : (
                                  ""
                                )}
                              </div>
                            </form>
                          </Modal.Body>
                          <Modal.Footer>
                            <Button
                              variant="secondary"
                              onClick={closeEmailQuote}
                            >
                              Close
                            </Button>
                            <Button
                              variant="primary"
                              className="color-skyblue"
                              onClick={submitEmailQuote}
                            >
                              Email Quote
                            </Button>
                          </Modal.Footer>
                        </Modal>
                        {isSubmitted ? (
                          <div className="progressOverley">
                            <Spinner animation="grow" variant="primary" />
                          </div>
                        ) : (
                          ""
                        )}
                      </div>
                    </div>
                  ))}
                  {above ? (
                    <h3 className="reasonFor">
                      <span style={{ color: "var(--color-green)" }}>
                        Important!
                      </span>{" "}
                      As you have two or more bath tubs at your property we
                      advise you’ll need a boiler no lower than 40KW in power.
                      That’s why, for you, we are only recommending the above
                      Vaillant ecoTEC Green IQ 843.
                    </h3>
                  ) : (
                    ""
                  )}
                </>
              )}
            </section>
          ) : (
            <section className="recommended-boiler">
              <h1>No grants available...</h1>
            </section>
          )}
          
          <section
            className="faq-section text-center about-page"
            data-aos="fade-up"
          >
            <div className="section-title">Any questions?</div>
            <p className="">
              We are all unique just like our various questions and queries.{" "}
              <br />
              Chat live below or contact us for further information
            </p>
            <div onClick={liveChat}>
            <GetYourQuoteBtn text="Chat with us" icon="chat" bgColor="skyblue" color="white"/>
            </div>
          </section>
        </>
      ) : (
        <>
          <div className="text-center loader-section">
            {/* <img src={LoaderImage} /> */}
            <CustomSpinnerComponent />
          </div>
        </>
      )}
    </>
  );
};

export default Resultpage;

