import { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import trustPilotStars from "./../../../assets/images/trustpilot-stars.png"
import prevArrow1 from "./../../../assets/images/left-arrow.png"
import nextArrow1 from "./../../../assets/images/right-arrow.png"
import "./TrustpilotSlider.css"

export default class TrustpilotSlider extends Component {
  render() {
    const settings = {
      dots: false,
      infinite: true,
      speed: 1000,
      autoplay: true,
      autoplaySpeed: 2000,
      slidesToShow: 3,
      slidesToScroll: 1,
      responsive: [
        {
          breakpoint: 3000,
          settings: {
            slidesToShow: 4,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 1440,
          settings: {
            slidesToShow: 3,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 960,
          settings: {
            slidesToShow: 2,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 767,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        },
        {
          breakpoint: 480,
          settings: {
            slidesToShow: 1,
            slidesToScroll: 1
          }
        }
      ]
    };
    return (
      <div className="trustpilot-slider">
        <Slider {...settings}>
          <div className="trustpilot-slide">
            <div className="flexbox flexbox-space-between">
                <img src={trustPilotStars} alt=""/>
                <p>15 June</p>
            </div>
            <div className="trustpilot-content">
                <div className="review-title">Great job</div>
                <p className="review">If someone had told me that i would qualify for a complete central heating</p>
                <p className="review-by">Mr Davies</p>
            </div>
          </div>
          <div className="trustpilot-slide">
            <div className="flexbox flexbox-space-between">
                <img src={trustPilotStars} alt=""/>
                <p>15 June</p>
            </div>
            <div className="trustpilot-content">
                <div className="review-title">Impressive workforce.</div>
                <p className="review">I would like to praise Nick and Darren (carpenter and plasterer) the wall</p>
                <p className="review-by">Marioball</p>
            </div>
          </div>
          <div className="trustpilot-slide">
          <div className="flexbox flexbox-space-between">
                <img src={trustPilotStars} alt=""/>
                <p>15 June</p>
            </div>
            <div className="trustpilot-content">
                <div className="review-title">Good tidy job.</div>
                <p className="review">The workmen arrived on time and were very polite and considerate. They laid</p>
                <p className="review-by">Mrs Rosemarry Harries</p>
            </div>
          </div>
          <div className="trustpilot-slide">
            <div className="flexbox flexbox-space-between">
                <img src={trustPilotStars} alt=""/>
                <p>15 June</p>
            </div>
            <div className="trustpilot-content">
                <div className="review-title">Great job</div>
                <p className="review">If someone had told me that i would qualify for a complete central heating</p>
                <p className="review-by">Mr Davies</p>
            </div>
          </div>
          <div className="trustpilot-slide">
            <div className="flexbox flexbox-space-between">
                <img src={trustPilotStars} alt=""/>
                <p>15 June</p>
            </div>
            <div className="trustpilot-content">
                <div className="review-title">Impressive workforce.</div>
                <p className="review">I would like to praise Nick and Darren (carpenter and plasterer) the wall</p>
                <p className="review-by">Marioball</p>
            </div>
          </div>
          <div className="trustpilot-slide">
            <div className="flexbox flexbox-space-between">
                <img src={trustPilotStars} alt=""/>
                <p>15 June</p>
            </div>
            <div className="trustpilot-content">
                <div className="review-title">Good tidy job.</div>
                <p className="review">The workmen arrived on time and were very polite and considerate. They laid</p>
                <p className="review-by">Mrs Rosemarry Harries</p>
            </div>
          </div>
          
        </Slider>
        <h6> Showing our latest reviews</h6>
      </div>
    );
  }
}