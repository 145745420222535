import "./stepper.css"
import { Step, Stepper } from 'react-form-stepper';
import { useEffect} from 'react';
const StepperComponent = (params) => {
    let activeStep = 0
    if(params.activeStep) activeStep = params.activeStep;
    useEffect(() => {
        const q = document.querySelector(".active.steps")
        
        const w = document.querySelectorAll(".steps")
        w.forEach((ww) => {
          if(ww.classList.contains('active')){
            ww.parentElement.classList.add('blue-bg');
          }else{
            ww.parentElement.classList.remove('blue-bg');
          }
          if(!ww.classList.contains('completed') && !ww.classList.contains('active')){
            ww.parentElement.classList.add('grey-bg');
          }else{
            ww.parentElement.classList.remove('grey-bg');
          }
        })
      }, [activeStep])
      
    return (
        <>
            <Stepper activeStep={activeStep} className="stepper row" stepClassName="steps" hideConnectors={true}>
                <Step label="Your current boiler" />
                <Step label="Your new boiler" />
                <Step label="Inside your home" />
                <Step label="Outside your home" />
                <Step label="Choose a boiler" />
                <Step label="Checkout" />
            </Stepper>
        </>
    )
}
export default StepperComponent
