import "./custom-spinner.css"
import SpineImage from "../../../assets/images/CES-Roundal-loader.png"

const CustomSpinner = () => {
    return (
        <div class="image ">
            <img class="spin spinner" src={SpineImage}/>
        </div>
    )
}

export default CustomSpinner;
