import "./financialpage.css"
import laptopImg1 from "./../../../assets/images/financialpage/how-it-work-1.png"
import laptopImg2 from "./../../../assets/images/financialpage/how-it-work-2.png"
import laptopImg3 from "./../../../assets/images/financialpage/how-it-work-3.png"
import vendigoLogo from "./../../../assets/images/financialpage/logo-inverted-svg.svg"
import vendigoLaptop from "./../../../assets/images/financialpage/vendigo-app-homepage.png"
import eligibleRight from "./../../../assets/images/financialpage/eligible-right.png"


import GetYourQuoteBtn from "../../components/get-your-quote-btn/get-your-quote-btn"
import aos from 'aos';
import { useEffect } from "react"
import { useHistory } from 'react-router-dom'

const Financialpage = (params) => {

    const history = useHistory();

    useEffect(()=>{
        aos.init({duration:2000})
    },[]);

    const handleQuote = () => {
        history.push("./startsurvey");
    }
    const handleToBoiler = () => {
        history.push("./boilerpage")
    }

    return (
        <>
            <section className="financialpage boilers-section" data-aos="fade-up">
                <div className="row">
                    <div className="col-12 mb-5">
                        <h1>Spread the cost</h1>
                    </div>

                    <div className="col-md-6">
                        <div className="pl-4">
                            <p className="first_sub_heading">Buy now pay later</p>
                            <p>
                            Your new boiler is just a few clicks away but we understand that buying a new boiler can sometimes be an unplanned expense, which is why we offer our customers a way to spread the cost over monthly payments.</p>
                            <p>With the help of our money lenders Vendigo we are able to offer our customers a flexible and affordable way to pay. </p>
                            <p>The best news of all is that we are happy to offer 24 months interest free on all 12 and 24 month plans. No deposit is needed and you can opt to pay back early for your peace of mind. </p>
                            <p>Alternatively if you would prefer to spread the cost over a longer amount of time we can offer 3, 5 or 10 year agreement plans at 9.9% APR fixed rate of interest per annum. 
                            </p>
                        </div>
                    </div>
                    <div className="col-md-6 center-section">
                        <div className="representative-part">
                            <p className="heading">Representative example</p>
                            <p className="sub_heading">Based on Green IQ boiler</p>
                            <div className="payment_count">
                                <div className="row">
                                    <div className="col-8">
                                        <span className="label">Purchase Price</span>
                                    </div>
                                    <div className="col-4 text-right">
                                        £2,760
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12"><hr /></div>
                                </div>
                                <div className="row">
                                    <div className="col-8">
                                        <span className="label">Deposit</span>
                                    </div>
                                    <div className="col-4 text-right">
                                        £1,380
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12"><hr /></div>
                                </div>
                                <div className="row">
                                    <div className="col-8">
                                        <span className="label">Total cost of finance</span>
                                    </div>
                                    <div className="col-4 text-right">
                                        £762.82
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12"><hr /></div>
                                </div>
                                <div className="row">
                                    <div className="col-8">
                                        <span className="label">Total Repayable</span>
                                    </div>
                                    <div className="col-4 text-right">
                                        £2,142.82
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12"><hr /></div>
                                </div>
                                <div className="row">
                                    <div className="col-8">
                                        <span className="label">Agreement Duration</span>
                                    </div>
                                    <div className="col-4 text-right">
                                        120 months
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-12"><hr /></div>
                                </div>
                                <div className="row">
                                    <div className="col-8">
                                        <span className="label">Monthly payments</span>
                                    </div>
                                    <div className="col-4 text-right">
                                        £17.86
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-8">
                                        <span className="label">Payments</span>
                                    </div>
                                    <div className="col-4 text-right">
                                        9.9% per
                                    </div>
                                </div> */}
                                <div className="row">
                                    <div className="col-12"><hr /></div>
                                </div>
                                <div className="row">
                                    <div className="col-8">
                                        <span className="label">Average Percentage Rate</span>
                                    </div>
                                    <div className="col-4 text-right">
                                        9.9% p.a.
                                    </div>
                                </div>
                                {/* <div className="row">
                                    <div className="col-12"><hr /></div>
                                </div> */}
                                {/* <div className="row">
                                    <div className="col-8">
                                        <span className="label">(fixed)</span>
                                    </div>
                                    <div className="col-4 text-right">
                                        £834.15
                                    </div>
                                </div> */}
                            </div>
                            <p className="sub_heading">Excludes any other costs</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className="financial-how-it-works" data-aos="fade-up">
                <div className="savings-title text-center">How it works</div>
                <div className="saving-benefits flexbox flexbox-space-between">
                    <div className="laptop-div text-center">
                        <img src={laptopImg1} alt="" />
                        <p className="text-center show-desktop">Choose the best <br />finance option <br />available for you</p>
                        <p className="text-center show-mobile">Choose the best finance <br />option available for you</p>
                    </div>
                    <div className="laptop-div text-center">
                        <img src={laptopImg2} alt="" />
                        <p className="text-center show-desktop">Fill in the online finance <br />application with our <br />partner vendigo</p>
                        <p className="text-center show-mobile">Fill in the online finance <br /> application with our <br /> partner vendigo</p>
                    </div>
                    <div className="laptop-div text-center">
                        <img src={laptopImg3} alt="" />
                        <p className="text-center show-desktop">Hooray! When <br /> accepted you can <br />complete your order</p>
                        <p className="text-center show-mobile">Hooray! When accepted you <br /> can complete your order</p>
                    </div>
                </div>
            </section>
            <section data-aos="fade-up">
                <div className="vindigo-section">
                    <div className="row heading">
                        <div className="col-md-5 pr-0">
                            <h1>What is Vendigo?</h1>
                            <div className="left-text">
                                <p>
                                We have partnered with Vendigo Finance UK Ltd to offer you a quick, simple and 100% paperless process to access affordable finance options.</p>
                                <p>Vendigo can offer an instant credit decision and allow you to track your spending and pay flexibly over your agreed payment plan. 
                                </p>
                            </div>

                            <div className="pl-2 btn-div">
                                <GetYourQuoteBtn action={handleToBoiler} text="Find out more" bgColor="skyblue" color="white"></GetYourQuoteBtn>
                            </div>
                        </div>
                        <div className="col-md-7 pl-0">
                            <div className="vindigoLogo">
                                <img src={vendigoLogo} alt="Vendigo logo" />
                            </div>
                            <div className="right-text">
                                <img src={vendigoLaptop} alt="Vendigo laptop" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="eligible-section">
                    <div className="row heading">
                        <div className="col-md-7 pr-0">
                            <h1 data-aos="fade-up">Who is eligible</h1>
                            <div className="left-text" data-aos="fade-up">
                                <p>
                                Be over 18 years of age (Max 75 years old and you are able to repay the debt before the age of 85)</p>
                                <p>Be a UK Resident for at least 3 years</p>
                                <p>Have a UK bank account that can accept direct debits</p>
                                <p>Have a stable income whether through permanent employment (at least 16 hours per week) or other means (pension, Disability Allowance, etc.)</p>
                                <p>Be OK for there to be a credit check performed when you submit your application
                                </p>
                            </div>

                            <div className="pl-2 btn-div">
                                <GetYourQuoteBtn action={handleQuote} text="Get your quote" bgColor="skyblue" color="white"></GetYourQuoteBtn>
                            </div>
                        </div>
                        <div className="col-md-5 pl-0">
                            <div className="right-image ">
                                <img src={eligibleRight} alt="Eligible queastion" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section>
                <div className="CancellationsRefunds-section" data-aos="fade-up">
                    <div className="row">
                        <div className="col-12">
                            <h1>Cancellations & Refunds </h1>
                        </div>
                        <div className="col-md-10 ">
                            <p>
                            You have the right to withdraw from your finance agreement. This right applies for 14 days following signing of the credit agreement. You will still be in a contract of purchase for the goods. In the event that your finance agreement is cancelled after the installation is complete you will need to make alternative payment arrangements directly with CES Limited. CES Limited acts as a credit broker and is not a lender. Any credit is subject to status, affordability and a credit check. Our finance platform is Vendigo. See our cancellation policy in full here: <a href="/ces-legal-terms-and-conditions">https://www.cesboilershop.co.uk/ces-legal-terms-and-conditions</a>
                            </p>
                        </div>
                    </div>
                </div>
            </section>

        </>    
    )   
}

export default Financialpage